import React from "react";
import { FaInstagram } from "react-icons/fa";

import uoboLogo from "../../assets/images/logo.png";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer
      className={`mb-4 flex flex-wrap items-center justify-evenly xs:mx-auto xs:w-4/5 md:w-full`}
    >
      <Link to="/">
        <img src={uoboLogo} alt="Uobo Logo" className="h-14" />
      </Link>
      <div className="px-2 text-sm font-semibold text-slate-500">
        &copy; 2024 Uobo
      </div>
      <div className="px-2 text-sm font-semibold text-slate-500">
        info@uobo.ca
      </div>
      <div className="flex h-12 w-12 items-center justify-center rounded-full bg-white shadow-2xl shadow-black ">
        <a
          href="https://www.instagram.com/uobo.ca?igsh=MXZpZW4yOTg3ZHdveg%3D%3D&utm_source=qr"
          className="text-black no-underline"
        >
          <FaInstagram size={"20"} />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
