import React from "react";
import { useState } from "react";
import { Filter, Plus, Search } from "../../../../assets/icons/icons";
import UploadInventoryModal from "./uploadInventoryModal";
import Loader from "../../../common/loader";

const InventorySortSearch = (props) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleFileUpload = (file) => {

    setLoading(true);
    
    const dealerData = JSON.parse(localStorage.getItem("dealerSignin"));
    
    const formData = new FormData();
    formData.append("inventory_csv", file);
    formData.append("dealerId", dealerData?._id);

    fetch("https://api.uobo.ca/api/dealer/update-inventory", {
      method: "PUT",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("File uploaded successfully:", data);
        setIsModalOpen(false);
        props.onInventoryUpdate(data.updatedInventory);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error uploading file:", error);
      }) ;
  };

  return (
    <div className="flex items-center xs:flex-wrap justify-evenly xs:text-sm md:text-lg mt-3">
      <div
        className="flex items-center"
        onClick={() => props.setFilterModal(true)}
      >
        <Filter className="fill-blue-500 cursor-pointer" />
        <div className="text-blue-500 pr-2 border-r cursor-pointer">Filter</div>
      </div>
      {/* Sort */}
      <div className="flex my-2">
        <div className="px-2 mr-2">Sort :</div>
        <div
          className={`cursor-pointer ${
            props.sort === "0" && "text-blue-500  border-b-2 border-b-blue-500"
          }`}
          id="0"
          onClick={(e) => props.setSort(e.target.id)}
        >
          By Make
        </div>
        <div
          className={`cursor-pointer mx-3 ${
            props.sort === "1" && "text-blue-500  border-b-2 border-b-blue-500"
          }`}
          id="1"
          onClick={(e) => props.setSort(e.target.id)}
        >
          By Year
        </div>
        <div
          className={`cursor-pointer ${
            props.sort === "2" && "text-blue-500  border-b-2 border-b-blue-500"
          }`}
          id="2"
          onClick={(e) => props.setSort(e.target.id)}
        >
          By Type
        </div>
      </div>
      {/* Search */}
      <div className="flex items-center bg-slate-200 rounded-2xl pr-2 cursor-pointer xs:mb-2 md:mb-0 md:mx-2">
        <input
          id="search"
          type="text"
          name="search"
          ref={props.inputRef}
          placeholder="Search something.."
          className="bg-slate-200 rounded-2xl pl-2 xs:pr-4 sm:pr-0 xs:w-64 sm:w-auto"
        />
        <div onClick={props.handleIconClick}>
          <Search className="fill-blue-500" />
        </div>
      </div>
      <div
        className="flex items-center justify-center bg-blue-500 rounded-2xl text-white px-3 cursor-pointer xs:w-60 sm:w-auto"
        onClick={() => setIsModalOpen(true)}
      >
        <Plus className="mr-2 fill-white" /> Update Your Inventory
      </div>
      
      {isModalOpen && (
        <UploadInventoryModal
          onClose={() => setIsModalOpen(false)}
          onFileUpload={handleFileUpload}
        />
      )}

      {/* Loader */}
      {loading && <Loader />}

    </div>
  );
};

export default InventorySortSearch;
