import React, { useEffect, useState } from "react";

import uoboLogo from "../../../../assets/images/android-chrome-512x512.png";

import { getUserInventory } from "../../../../api/inventory/helper";
import { inventorySorting } from "../inventorySorting";
import Loading from "../../../common/Loading";

import "../../../../assets/scss/custom.scss";

const capitalizeWord = (word) => {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
};

const InventoryTable = (props) => {
  const [inventory, setInventory] = useState([]);

  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem("dealerSignin"))._id;
    const token = JSON.parse(localStorage.getItem("token"));

    getUserInventory(userId, token)
      .then((userInventory) => {
        setInventory(inventorySorting(props.sort, userInventory));
      })
      .catch((error) => {
        console.error(error, "User Inventory API Error!");
      });
  }, [props.sort]);

  const renderTableContent = () => {
    return inventory.map((data) => (
      <div
        key={data.Inventory_Id}
        onClick={() => {
          props.handleCarClick(data.Inventory_Id);
        }}
        className="my-2 flex cursor-pointer rounded border hover:bg-blue-100 xs:w-fit md:w-auto"
      >
        <div className="custom-container m-1 h-16 w-8 lg:w-10">
          <img
            src={data.Main_Photo}
            alt={"Car Photo is missing :("}
            className="h-full w-full rounded object-cover"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = uoboLogo;
            }}
          />
        </div>
        <div className="custom-container flex items-center justify-center">
          {data.Make}
        </div>
        <div className="custom-container flex items-center justify-center">
          {data.Model}
        </div>
        <div className="custom-container flex items-center justify-center">
          {data.Year}
        </div>
        <div className="custom-container flex items-center justify-center">
          {capitalizeWord(data.Body_Style)}
        </div>
        <div className="custom-container flex items-center justify-center">
          {data.Price}
        </div>
        <div className="custom-container flex items-center justify-center">
          {data.Vehicle_Location}
        </div>
      </div>
    ));
  };

  return (
    <div className="my-4 w-full overflow-x-auto">
      {/* Header*/}
      <div className="flex text-slate-400">
        <div className="custom-container flex justify-center">Image</div>
        <div className="custom-container flex justify-center">Make</div>
        <div className="custom-container flex justify-center">Model</div>
        <div className="custom-container flex justify-center">Year</div>
        <div className="custom-container flex justify-center">Type</div>
        <div className="custom-container flex justify-center">Price</div>
        <div className="custom-container flex justify-center">Location</div>
      </div>
      {/* Table Contents */}
      {inventory.length != 0 && <Loading /> ? <div className="inventory">{renderTableContent()}</div> : <h3 className="inventory"> Upload Your Inventory </h3>}
      
    </div>
  );
};

export default InventoryTable;
