import React, {useRef, useState} from "react";
import { sendAppointmentsToCustomer } from "../../../api/orders/helper";
import Modal from "react-bootstrap/Modal";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Accordion from 'react-bootstrap/Accordion';
import { sendFinanceAppointmentsToCustomer } from "../../../api/orders/helper";
import { useNavigate } from "react-router-dom";
// import SendQueryModal from "../../../common/modal/sendQueryModal";
import SuccessModal from "./successModal";
import "../../../assets/scss/custom.scss";
import { DownArrow } from "../../../assets/icons/icons";

const OpenFinanceOrder = (props) => {
console.log('-------FINANCE ORDER------', props);
const navigate = useNavigate();

let [emiArr, setemiArr] = useState([]);

  const [open, setOpen] = useState(false);
  const [bankName, setBankName] = useState("");
  const [step, setStep] = useState(0);
  let [emiOptions, setEMIOptions] = useState([]);
  let [emiRender, setEMIRender] = useState([]);

  let [appointmentArr, seAppointmentArr] = useState([]);
  let [appointmentsRender, setAppointmentsRender] = useState([]);

  let queryModal;

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const inputRef = useRef(null);

  function handleSubmit(event) {
    event.preventDefault();
    console.log("Input value:", inputRef.current.value);
    // console.log("btn1Ref value:", btn1Ref.current.value);
    // console.log("btn2Ref value:", btn2Ref.current.value);
    
    setStep(1);

    //API will send here
    const token = JSON.parse(localStorage.getItem("token"));
    let body = {
      
    }

    // props.queryModal.handleCloseModal();
    // props.queryModal.handleSuccessOpenModal();
  }

  const handleBankName = (bankName) => {
    setBankName(bankName);
  };

  const viewDocuments = () => {
    //opem new modal and pass props.financeOrderQuery.order.documents
  };
  
  const saveBanksRates = () => {
    localStorage.removeItem("emiOptions");
    //console.log('------EMI OPTIONS------', localStorage.getItem("emiOptions"));
    if(localStorage.getItem("emiOptions") === null) {
      //do nothing
    } else {
      emiOptions = JSON.parse(localStorage.getItem("emiOptions"));
    }

    console.log('-----ARRAY BEFORE OPERATIONS------', emiOptions);

    emiOptions.push({
      bankName: bankName,
      dealerId: props.financeOrderQuery.order.dealerId,
      options: []
    });

    let weekly_amount_24 = document.querySelector("#weekly_amount_24").value;
    let interest_rate_24 = document.querySelector("#interest_rate_24").value;
    let total_amount_24 = document.querySelector("#total_amount_24").value;
    console.log('-------24 months VALUE------', weekly_amount_24);

    if(weekly_amount_24 !== '') {
      emiOptions[emiOptions.length - 1].options.push({
          monthDuration: 24,
          amountFragment: Number(weekly_amount_24),
          amountDue: "0",
          totalAmount: Number(total_amount_24),
          interestRate: Number(interest_rate_24)
      });
    }

    let weekly_amount_36 = document.querySelector("#weekly_amount_36").value;
    let interest_rate_36 = document.querySelector("#interest_rate_36").value;
    let total_amount_36 = document.querySelector("#total_amount_36").value;

    console.log('-------36 months VALUE------', weekly_amount_36);

    if(weekly_amount_36 !== '') {
      emiOptions[emiOptions.length - 1].options.push({
          monthDuration: 36,
          amountFragment: Number(weekly_amount_36),
          amountDue: "0",
          totalAmount: Number(total_amount_36),
          interestRate: Number(interest_rate_36)
      });
    }

    let weekly_amount_48 = document.querySelector("#weekly_amount_48").value;
    let interest_rate_48 = document.querySelector("#interest_rate_48").value;
    let total_amount_48 = document.querySelector("#total_amount_48").value;

    console.log('-------48 months VALUE------', weekly_amount_48);

    if(weekly_amount_48 !== '') {
      emiOptions[emiOptions.length - 1].options.push({
          monthDuration: 48,
          amountFragment: Number(weekly_amount_48),
          amountDue: "0",
          totalAmount: Number(total_amount_48),
          interestRate: Number(interest_rate_48)
      });
    }

    let weekly_amount_60 = document.querySelector("#weekly_amount_60").value;
    let interest_rate_60 = document.querySelector("#interest_rate_60").value;
    let total_amount_60 = document.querySelector("#total_amount_60").value;

    console.log('-------60 months VALUE------', weekly_amount_60);

    if(weekly_amount_60 !== '') {
      emiOptions[emiOptions.length - 1].options.push({
          monthDuration: 60,
          amountFragment: Number(weekly_amount_60),
          amountDue: "0",
          totalAmount: Number(total_amount_60),
          interestRate: Number(interest_rate_60)
      });
    }

    let weekly_amount_72 = document.querySelector("#weekly_amount_72").value;
    let interest_rate_72 = document.querySelector("#interest_rate_72").value;
    let total_amount_72 = document.querySelector("#total_amount_72").value;

    console.log('-------72 months VALUE------', weekly_amount_72);

    if(weekly_amount_72 !== '') {
      emiOptions[emiOptions.length - 1].options.push({
          monthDuration: 72,
          amountFragment: Number(weekly_amount_72),
          amountDue: "0",
          totalAmount: Number(total_amount_72),
          interestRate: Number(interest_rate_72)
      });
    }

    let weekly_amount_84 = document.querySelector("#weekly_amount_84").value;
    let interest_rate_84 = document.querySelector("#interest_rate_84").value;
    let total_amount_84 = document.querySelector("#total_amount_84").value;

    console.log('-------84 months VALUE------', weekly_amount_84);

    if(weekly_amount_84 !== '') {
      emiOptions[emiOptions.length - 1].options.push({
          monthDuration: 84,
          amountFragment: Number(weekly_amount_84),
          amountDue: "0",
          totalAmount: Number(total_amount_84),
          interestRate: Number(interest_rate_84)
      });
    }

    localStorage.setItem("emiOptions", JSON.stringify(emiOptions));

    //emiOptionsReal.push(emiOptions);
  };

  const saveAndNewPlan = () => {
    saveBanksRates();
    emiArr = JSON.parse(localStorage.getItem("emiOptions"));
    console.log('-----LIST OF BANKS------', emiArr);

    setStep(0);

  };

  const saveAndProceed = () => {
    saveBanksRates();
    setStep(2);
  };

  if(step === 2 && emiRender.length == 0) {
    emiArr = JSON.parse(localStorage.getItem("emiOptions"));
    console.log('-----LIST OF BANKS------', emiArr);

    Object.keys(emiArr).forEach(function (key) {
      // for(let i = 0; i < emiArr[key].options.length; i++) {
      //   console.log('--------PLAN-------', emiArr[key].options[i].monthDuration);
      //   if(emiArr[key].options[i].amountFragment === 0) {
      //     console.log('--------FRAGMENT-------', emiArr[key].options[i].amountFragment);
      //     emiArr[key].options.splice(emiArr[key].options[i], 1);
      //   }
      // }
      emiRender.push(emiArr[key]);
    });

    console.log('-----LIST OF BANKS AFTER RENDER------', emiRender);

  }

  const sendToCustomer = () => {
    //emiArr = JSON.parse(localStorage.getItem("emiOptions"));
    console.log('-----SENDING TO API LIST OF BANKS------', emiRender);

    appointmentArr = JSON.parse(localStorage.getItem("appointmentsArray"));
    // console.log('-----Appointments------', appointmentArr);

    Object.keys(appointmentArr).forEach(function (key) {
      appointmentsRender.push(appointmentArr[key]);
    });

    let tradeInCarValue = "0";

    if(props.financeOrderQuery.order.isTradeinCarAvilable !== false) {
      tradeInCarValue = localStorage.getItem("dealerEvaluation");
    }

    //API will call here
    let body = {
      financeId: props.financeOrderQuery.order._id,
      status: 'DealerSentEMIOptions',
      confirmAvailabilty: true,
      financeApproval: true,
      dealerEstimatedTradeInValue: tradeInCarValue,
      tradeInCarValue: tradeInCarValue,
      additionalDocuments: [],
      appointments: appointmentsRender,
      EMIOptions: emiRender,
      billOfSale: ''
    }
    console.log('--------------->>>>>>>>>>>BODY<<<<<<<<<<<<<<--------------', body);

    const token = JSON.parse(localStorage.getItem("token"));
      sendFinanceAppointmentsToCustomer(body, token).then((response) => {
        console.log('----SEND EMI AND APPOINTMENTS API----', response);

        localStorage.removeItem("emiOptions");
        localStorage.removeItem("dealerEvaluation");
        localStorage.removeItem("appointmentsArray");
        emiRender.length = 0;
        setOpen(true);
        setStep(3);

        //handleOpenModal(); //will open props.financeOrderQuery.order

        navigate("/additional-car-services", {
          state: {
            order: props.financeOrderQuery.order
          }
        })

      }, (err) => {
        console.log('Error sending appointments', err);
        localStorage.removeItem("emiOptions");
        localStorage.removeItem("dealerEvaluation");
        emiRender.length = 0;
        setStep(3);
      });
  };

  if(step === 3) {

    setTimeout(() => {
      props.financeOrderQuery.closeFinanceOrder();
    }, 2000);
  }

  queryModal = {
    open: open,
    success: open,
    handleSuccessCloseModal: handleCloseModal,
    customerName: props.financeOrderQuery.order.firstName + ' ' + props.financeOrderQuery.order.lastName
  };
  
  return (
    
    <><Modal dialogClassName="my-modal" show={props.financeOrderQuery.openFinanceOrder} onHide={() => props.financeOrderQuery.closeFinanceOrder} centered>
      <form onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>

            <div className="flex justify-between my-3">
              <div className="mr-3">
                <div className="flex w-64 text-sm">
                  <img
                    className="h-16 rounded-lg mr-3"
                    src={props.financeOrderQuery.order.carId.Main_Photo}
                    alt="GMC_Yukon" />

                  <div className="flex flex-col">
                    <div>{props.financeOrderQuery.order.carId.Year} {props.financeOrderQuery.order.carId.Make} {props.financeOrderQuery.order.carId.Model}</div>
                    <div>${props.financeOrderQuery.order.carId.Price}</div>
                  </div>
                </div>
              </div>
              <div className="mr-3">
                <div className="text-center">
                  {props.financeOrderQuery.order.customerId.firstName} {props.financeOrderQuery.order.customerId.lastName}
                  {/* TODO CHANGES */}
                  {/* <div className="text-blue-400 cursor-pointer" onClick={viewDocuments()}>View Documents</div> */}
                </div>

              </div>
              <div className="mr-3">
                {/* <div
                  className="text-blue-500 border rounded-lg text-center w-full py-2 my-3 cursor-pointer hover:bg-blue-500  hover:text-white "
                  onClick={() => handleOpenModal()}
                >
                  Request for more documents
                </div> */}

              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {step === 0 && (
            <><div className="text-center">Enter bank name to finance for {props.financeOrderQuery.order.customerId.firstName} {props.financeOrderQuery.order.customerId.lastName}</div><div className="border-2 border-blue-500 rounded-lg">
              <input
                name="bank_name"
                id="query"
                ref={inputRef}
                placeholder="Bank name"
                value={bankName}
                className="p-1 mt-1 focus-visible:outline-none max-w-md cursor-pointer" />
              <div className="mx-2 my-2">
                {/* TODO CHANGES */}
                <div onClick={() => handleBankName('CIBC')} className="rounded-full my-2 mx-2 text-sm border p-2 cursor-pointer bg-red-500 text-white w-1/4 text-center float-left">CIBC</div>
      <div onClick={() => handleBankName('RBC')} className="rounded-full my-2 mx-2 text-sm border p-2 cursor-pointer bg-blue-500 text-white w-1/4 text-center float-left">RBC</div>
      <div onClick={() => handleBankName('Carfinco')} className="rounded-full my-2 mx-2 text-sm border p-2 cursor-pointer bg-yellow-500 text-white w-1/4 text-center float-left">Carfinco</div>
      <div onClick={() => handleBankName('TD Bank')} className="rounded-full my-2 mx-2 text-sm border p-2 cursor-pointer bg-green-500 text-white w-1/4 text-center float-left">TD Bank</div>
      <div onClick={() => handleBankName('HSBC')} className="rounded-full my-2 mx-2 text-sm border p-2 cursor-pointer bg-purple-500 text-white w-1/4 text-center float-left">HSBC</div>
      <div onClick={() => handleBankName('Scotibank')} className="rounded-full my-2 mx-2 text-sm border p-2 cursor-pointer bg-red-500 text-white w-1/4 text-center float-left">Scotibank</div>
      <div onClick={() => handleBankName('Bank of Montreal')} className="rounded-full my-2 mx-2 text-sm border p-2 cursor-pointer bg-blue-500 text-white w-1/4 text-center float-left">Bank of Montreal</div>
              </div>
            </div><div className="clear-both"></div></>
          )}

          {step === 1 && (
            <>
            <div
              className="flex items-center text-xl text-blue-500 mt-2 no-underline w-max cursor-pointer"
              onClick={() => {
                setStep(0)
              }}
            >
              <DownArrow className="transform rotate-90 fill-blue-500" />
              Back
            </div>
            <div className="text-center">Enter license plan details from {bankName}.</div><div className="">
              <Tabs className="tabs" forceRenderTabPanel>
                <TabList>
                  <Tab>24 months</Tab>
                  <Tab>36 months</Tab>
                  <Tab>48 months</Tab>
                  <Tab>60 months</Tab>
                  <Tab>72 months</Tab>
                  <Tab>84 months</Tab>
                </TabList>

                <TabPanel>
                  <Tabs>
                    {/* <TabList>
                      <Tab>24 months</Tab>
                    </TabList> */}

                    <TabPanel>
                      <div className="box-border h-30 w-60 p-4 border-2 rounded-md inline-block mx-4">
                        <label htmlFor="">Weekly amount ($)</label><br />
                        <input type="number" name="weekly_amount_24" id="weekly_amount_24" min={0} className="border-2" />
                      </div>
                      <div className="box-border h-30 w-60 p-4 border-2 rounded-md inline-block mx-4">
                        <label htmlFor="">Interest rate (%)</label><br />
                        <input type="text" name="interest_rate_24" id="interest_rate_24" className="border-2" />
                      </div>
                      <div className="box-border h-30 w-60 p-4 border-2 rounded-md inline-block mx-4">
                        <label htmlFor="">Total amount ($)</label><br />
                        <input type="number" name="total_amount_24" id="total_amount_24" min={0} className="border-2" />
                      </div>
                    </TabPanel>
                  </Tabs>
                </TabPanel>
                <TabPanel>
                  <Tabs>
                    {/* <TabList>
                      <Tab>36 months</Tab>
                    </TabList> */}

                    <TabPanel>
                      <div className="box-border h-30 w-60 p-4 border-2 rounded-md inline-block mx-4">
                        <label htmlFor="">Weekly amount ($)</label><br />
                        <input type="number" name="weekly_amount" id="weekly_amount_36" min={0} className="border-2" />
                      </div>
                      <div className="box-border h-30 w-60 p-4 border-2 rounded-md inline-block mx-4">
                        <label htmlFor="">Interest rate (%)</label><br />
                        <input type="text" name="interest_rate" id="interest_rate_36" className="border-2" />
                      </div>
                      <div className="box-border h-30 w-60 p-4 border-2 rounded-md inline-block mx-4">
                        <label htmlFor="">Total amount ($)</label><br />
                        <input type="number" name="total_amount" id="total_amount_36" min={0} className="border-2" />
                      </div>
                    </TabPanel>
                  </Tabs>
                </TabPanel>
                <TabPanel>
                  <Tabs>
                    {/* <TabList>
                      <Tab>48 months</Tab>
                    </TabList> */}

                    <TabPanel>
                      <div className="box-border h-30 w-60 p-4 border-2 rounded-md inline-block mx-4">
                        <label htmlFor="">Weekly amount ($)</label><br />
                        <input type="number" name="weekly_amount_48" id="weekly_amount_48" min={0} className="border-2" />
                      </div>
                      <div className="box-border h-30 w-60 p-4 border-2 rounded-md inline-block mx-4">
                        <label htmlFor="">Interest rate (%)</label><br />
                        <input type="text" name="interest_rate_48" id="interest_rate_48" className="border-2" />
                      </div>
                      <div className="box-border h-30 w-60 p-4 border-2 rounded-md inline-block mx-4">
                        <label htmlFor="">Total amount ($)</label><br />
                        <input type="number" name="total_amount_48" id="total_amount_48" min={0} className="border-2" />
                      </div>
                    </TabPanel>
                  </Tabs>
                </TabPanel>
                <TabPanel>
                  <Tabs>
                    {/* <TabList>
                      <Tab>60 months</Tab>
                    </TabList> */}

                    <TabPanel>
                      <div className="box-border h-30 w-60 p-4 border-2 rounded-md inline-block mx-4">
                        <label htmlFor="">Weekly amount ($)</label><br />
                        <input type="number" name="weekly_amount_60" id="weekly_amount_60" min={0} className="border-2" />
                      </div>
                      <div className="box-border h-30 w-60 p-4 border-2 rounded-md inline-block mx-4">
                        <label htmlFor="">Interest rate (%)</label><br />
                        <input type="text" name="interest_rate_60" id="interest_rate_60" className="border-2" />
                      </div>
                      <div className="box-border h-30 w-60 p-4 border-2 rounded-md inline-block mx-4">
                        <label htmlFor="">Total amount ($)</label><br />
                        <input type="number" name="total_amount_60" id="total_amount_60" min={0} className="border-2" />
                      </div>
                    </TabPanel>
                  </Tabs>
                </TabPanel>
                <TabPanel>
                  <Tabs>
                    {/* <TabList>
                      <Tab>72 months</Tab>
                    </TabList> */}

                    <TabPanel>
                      <div className="box-border h-30 w-60 p-4 border-2 rounded-md inline-block mx-4">
                        <label htmlFor="">Weekly amount ($)</label><br />
                        <input type="number" name="weekly_amount_72" id="weekly_amount_72" min={0} className="border-2" />
                      </div>
                      <div className="box-border h-30 w-60 p-4 border-2 rounded-md inline-block mx-4">
                        <label htmlFor="">Interest rate (%)</label><br />
                        <input type="text" name="interest_rate_72" id="interest_rate_72" className="border-2" />
                      </div>
                      <div className="box-border h-30 w-60 p-4 border-2 rounded-md inline-block mx-4">
                        <label htmlFor="">Total amount ($)</label><br />
                        <input type="number" name="total_amount_72" id="total_amount_72" min={0} className="border-2" />
                      </div>
                    </TabPanel>
                  </Tabs>
                </TabPanel>
                <TabPanel>
                  <Tabs>
                    {/* <TabList>
                      <Tab>84 months</Tab>
                    </TabList> */}

                    <TabPanel>
                      <div className="box-border h-30 w-60 p-4 border-2 rounded-md inline-block mx-4">
                        <label htmlFor="">Weekly amount ($)</label><br />
                        <input type="number" name="weekly_amount_84" id="weekly_amount_84" min={0} className="border-2" />
                      </div>
                      <div className="box-border h-30 w-60 p-4 border-2 rounded-md inline-block mx-4">
                        <label htmlFor="">Interest rate (%)</label><br />
                        <input type="text" name="interest_rate_84" id="interest_rate_84" className="border-2" />
                      </div>
                      <div className="box-border h-30 w-60 p-4 border-2 rounded-md inline-block mx-4">
                        <label htmlFor="">Total amount ($)</label><br />
                        <input type="number" name="total_amount_84" id="total_amount_84" min={0} className="border-2" />
                      </div>
                    </TabPanel>
                  </Tabs>
                </TabPanel>
              </Tabs>
            </div><div className="clear-both"></div></>
          )}

          {step === 2 && (
            // <><div className="text-center">Desking Details</div>
            // {emiRender.map((emi, index) => (
            //   <><div className="h-3 mx-1" key={index}>{emi.bankName}</div>
            //   {emi.options.map((option, index2) => (
            //     <div className="box-border h-30 w-60 p-4 border-2 rounded-md inline-block mx-4" key={index2}>
            //     <div className="h-4">{option.monthDuration}</div>
            //     <div className="h-3">${option.amountFragment} per week</div>
            //     <div className="">
            //       <div className="inline-block text-gray-400">Amount</div>
            //       <div className="inline-block">${option.totalAmount}</div>
            //     </div>
            //     <div className="">
            //       <div className="inline-block text-gray-400">Interest rate</div>
            //       <div className="inline-block">{option.interestRate}%</div>
            //     </div>
            //   </div>
            //   ))}
            // </>
            // ))}
            // </>
            // <Accordion defaultActiveKey="0">
            //   {emiRender.map((emi, index) => (
            //     <Accordion.Item eventKey={index}>
            //       <Accordion.Header>{emi.bankName}</Accordion.Header>
            //       <Accordion.Body>
            //         {emi.options.map((option, index2) => (
            //           <div className="box-border h-30 w-60 p-2 my-2 border-2 rounded-md inline-block mx-4" key={index2}>
            //             <div className="h-4">{option.monthDuration}</div>
            //             <div className="h-3 mx-1">${option.amountFragment} per week</div>
            //             <div className="my-2">
            //               <div className="inline-block text-gray-400">Amount</div>
            //               <div className="inline-block mx-2">${option.totalAmount}</div>
            //             </div>
            //             <div className="">
            //               <div className="inline-block text-gray-400">Interest rate</div>
            //               <div className="inline-block mx-2">{option.interestRate}%</div>
            //             </div>
            //           </div>
            //         ))}
            //       </Accordion.Body>
            //     </Accordion.Item>
            //   ))}
            // </Accordion>
            <Accordion defaultActiveKey="0">
              {emiRender.map((emi, index) => (
                <Accordion.Item eventKey={index} key={index}>
                  <Accordion.Header>{emi.bankName}</Accordion.Header>
                  <Accordion.Body className="bg-gray-50">
                    {emi.options.map((option, index2) => (
                      <div 
                        className="box-border w-64 p-4 my-4 border-2 rounded-lg inline-block mx-4 shadow-lg transition-transform transform hover:scale-105 bg-white" 
                        key={index2}
                      >
                        <div className="font-semibold text-lg mb-2 text-indigo-700">{option.monthDuration} Months</div>
                        <div className="text-md text-gray-700">
                          <span className="font-medium">${option.amountFragment}</span> per week
                        </div>
                        <div className="my-4">
                          <div className="text-sm text-gray-500 inline-block">Total Amount</div>
                          <div className="text-md inline-block mx-2 font-semibold">${option.totalAmount}</div>
                        </div>
                        <div className="mt-2">
                          <div className="text-sm text-gray-500 inline-block">Interest Rate</div>
                          <div className="text-md inline-block mx-2 font-semibold">{option.interestRate}%</div>
                        </div>
                      </div>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>

          )}
        </Modal.Body>
        <Modal.Footer>
          {step === 0 && (
            <><div
              className="bg-red-500 text-xl text-white text-center py-2 rounded-2xl w-48 mb-10 cursor-pointer"
              onClick={() => props.financeOrderQuery.closeFinanceOrder()}
            >
              Cancel
            </div>

              <div
                className="bg-blue-500 text-xl text-white text-center py-2 rounded-2xl w-48 float-right mb-10 cursor-pointer"
              >
                <input type="submit" value="Proceed" />
              </div></>
          )}

          {step === 1 && (
            <><div
              className="bg-red-500 text-xl text-white text-center py-2 rounded-2xl w-48 mb-10 cursor-pointer"
              onClick={() => props.financeOrderQuery.closeFinanceOrder()}
            >
              Cancel
            </div><div
              className="text-xl text-center py-2 rounded-2xl w-45 float-right mb-10 cursor-pointer text-blue-600 border-blue-400 border-2"
              onClick={() => saveAndNewPlan()}
            > Save and add a new Plan
              </div><div
                className="bg-blue-500 text-xl text-white text-center py-2 rounded-2xl w-48 float-right mb-10 cursor-pointer"
                onClick={() => saveAndProceed()}
              > Save and proceed
              </div></>
          )}

          {step === 2 && (
            <>
              <div
                className="bg-blue-500 text-xl text-white text-center py-2 rounded-2xl w-48 mb-10 cursor-pointer"
                onClick={() => {
                  setStep(1)
                }}
              >
                {/* <DownArrow className="transform rotate-90 fill-blue-500" /> */}
                Back
              </div>
              <div
                className="bg-red-500 text-xl text-white text-center py-2 rounded-2xl w-48 mb-10 cursor-pointer"
                onClick={() => props.financeOrderQuery.closeFinanceOrder()}
              >
                Cancel
              </div>
                {/* <div
                className="text-xl text-center py-2 rounded-2xl w-45 float-right mb-10 cursor-pointer text-blue-600 border-blue-400 border-2"
                onClick={() => saveAndNewPlan()}
              > Add new bank
                </div> */}
                <div
                  className="bg-blue-500 text-xl text-white text-center py-2 rounded-2xl w-48 float-right mb-10 cursor-pointer"
                  onClick={() => sendToCustomer()}
                > Send to customer
                </div>
              </>
          )}

        </Modal.Footer>
      </form>
    </Modal>
    <SuccessModal queryModal={queryModal} handleCloseModal={handleCloseModal} /></>
    
  );
};

export default OpenFinanceOrder;
