import React, {useRef} from "react";
import { Link, useNavigate } from "react-router-dom";
import { sendFinanceAppointmentsToCustomer } from "../../../../api/orders/helper";
//import LicenceFr from "../../../../assets/images/licenceFr.png";

const TradeCustomerInfo = (props) => {

  const navigate = useNavigate();

  const inputRef = useRef(null);

  function handleSubmit(event) {
    event.preventDefault();
    console.log("Input value:", inputRef.current.value);

    localStorage.setItem("dealerEvaluation", inputRef.current.value);

    if(props.ordersData.orders[0].category === 'Fix') {
      let body = {
        financeId: props.ordersData.orders[0]._id,
        status: 'DealerSentAvailability',
        confirmAvailabilty: true,
        tradeInCarValue: inputRef?.current?.value,
        dealerEstimatedTradeInValue: inputRef?.current?.value,
        additionalDocuments: [],
        appointments: [],
        EMIOptions: [],
        tradeInCarOfferedPrice: inputRef?.current?.value,
        billOfSale: ''
      }

    const token = JSON.parse(localStorage.getItem("token"));
    sendFinanceAppointmentsToCustomer(body, token).then((response) => {
      console.log(response);

      props.ordersData.setNewOrderDetails(1);

      navigate("/dealer-dashboard");

    }, (err) => {
      console.log('Error sending appointments', err);
    });
    }
    else if(props.ordersData.orders[0].category === 'Cash') {
      props.ordersData.setStep(1); 
    }
    
  }

  let isCustomerDetailAvailable = false;

  if(props.ordersData.orders.length > 0) {
    isCustomerDetailAvailable = true;

     
  }

  return (
    <div className="w-3/5 ml-3">
      {/* <div className="border-2 border-blue-500 rounded-lg p-3">
        <div className="text-xl">Customer details</div>
        {isCustomerDetailAvailable === true && ( 
          <><div className="flex justify-between my-2">
          </div><div className="text-xl mt-3">Car Images</div><div className="flex justify-between flex-wrap">
              {props.ordersData.orders[0].tradeDetails.photos.map((photo) => (
                <div className="my-2 mr-2">
                  <img
                    className="h-24 w-60 object-cover rounded-lg border-2 border-blue-500 p-1 cursor-pointer"
                    src={photo}
                    alt="Car Images" />
                </div>
              ))}
            </div></>
        )}
      </div> */}
      <form onSubmit={handleSubmit}>
      <div className="">
        <label className="my-2">Dealer Evaluation ($):</label><br />
          <input
            type="number"
            name="reason"
            autoComplete="off"
            id="query"
            ref={inputRef}
            placeholder=""
            className="p-1 mt-1 w-40 border-2 border-black"
          />
          
        </div>
        <div >
            <input type="submit" className="text-center rounded-lg py-2 my-2 cursor-pointer bg-blue-500 text-white w-40" value="Send" />
          </div>
      </form>
      {/* <div
        className="bg-blue-500 text-slate-50 border-2 border-transparent rounded-lg text-center w-full py-2 my-3 
      cursor-pointer hover:bg-white hover:border-blue-500 hover:text-blue-500 "
        onClick={() => props.ordersData.setStep(1)}
      >
        Confirm Availability
      </div> */}
      
    </div>
  );
};

export default TradeCustomerInfo;
