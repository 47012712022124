import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { getLiveOrders } from "../../../../api/orders/helper";
import CarDetails from "../component/carDetails";
import CustomerDetails from "../component/customerDetails";
import TradeCarInfo from "../component/tradeCarInfo";
import TradeCustomerInfo from "../component/tradeCustomerInfo";
import ConfirmDateTime from "../component/confirmDateTime";
import PreApprovalOrder from "../component/preApprovalOrder";
import FinanceApprovalOrder from "../component/financeApprovalOrder";
import PaveWebView from "./paveWebView";

const LiverOrder = (props) => {
  let [step, setStep] = useState(0);
  const [newOrderDetails, setNewOrderDetails] = useState(0);
  let [orderDetails, setOrderDetails] = useState([]);
  const { state } = useLocation() || {};
   console.log('-----------VALUE FROM HOME PAGE----------', state);
  // console.log('-----------ORDER FROM HOME PAGE----------', state.order);

  if(state !== null) {
    step = state.nextStep;
    orderDetails = state.order;
  }

  const loadLiveOrders = () => {
    const token = JSON.parse(localStorage.getItem("token"));
    getLiveOrders(token).then((orders) => {
      // console.log('---ORDERS---', orders);
      setOrderDetails(orders);
    });
  };

  useEffect(() => {
    loadLiveOrders();
    props.setLiveOrdersCount(orderDetails.length);
  },[props.liveOrdersCount]); //props.liveOrdersCount in dependancy before

  if(newOrderDetails === 1) {
    loadLiveOrders();
    // console.log('---ARRAY LENGTH AFTER SPLICE---', orderDetails.length);
    props.setLiveOrdersCount(orderDetails.length);
  }

  
  
  console.log('-----ORDER DETAILS-----', orderDetails);
  const ordersData = {
    setStep: setStep,
    orders: orderDetails,
    setNewOrderDetails: setNewOrderDetails
  };
  return (
    <div className="w-4/5 my-3">
      {/* Header */}
      <div className="flex items-center justify-start">
        <div className="text-slate-400 text-xl">Live orders</div>
        <div className="text-blue-500 border-2 border-blue-500 rounded-full p-2 ml-2">
          + {orderDetails.length} pending
        </div>
      </div>
      {step === 0 && (
        <div className="text-xl my-4">
          { orderDetails.length > 0 ? 'Please confirm the availablity of this vehicle.' : 'No Pending Orders' }
        </div>
      )}
      {step === 1 && (
        <div className="text-xl my-4">
          Select a suitable date and time for delivery.
        </div>
      )}
      {step === 2 && (
        <div className="text-xl my-4">
          Please evaluate the Trade In Car From Customer.
        </div>
      )}

      {step === 3 && (
        <div className="text-xl my-4">
          Mr. Doe John is waiting for finance approval.
        </div>
      )}

      {step === 0 && (
        <div className="flex">
          {orderDetails.length > 0 && (
            <><CarDetails ordersData={ordersData} /><CustomerDetails ordersData={ordersData} /></>
          )}
        </div>
      )}

      {step === 2 && (
          <div className>
            <div className="flex">
              <TradeCarInfo ordersData={ordersData} />
              <TradeCustomerInfo ordersData={ordersData} />
              </div>
            { orderDetails[0]?.paveReportURL !== undefined && 
              orderDetails[0]?.paveReportURL !== '' && 
              orderDetails[0]?.paveReportURL !== null ? <PaveWebView url={orderDetails[0]?.paveReportURL} /> : 'Please wait till customer submits trade in car'
            }
          </div>
        )}

      {step === 1 && <ConfirmDateTime ordersData={ordersData} />}

      {step === 3 && <PreApprovalOrder setStep={setStep} />}

      {step === 3 && <FinanceApprovalOrder setStep={setStep} />}
    </div>
  );
};

export default LiverOrder;
