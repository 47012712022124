import React, { useState } from "react";
import { Link } from "react-router-dom";

import CarImages from "../component/carImages";
import CarDetails from "../component/carDetails";
import CarEditDetails from "../component/carEditDetails";
import { DownArrow, Edit } from "../../../../assets/icons/icons";

const InventoryCarView = () => {
  const [step, setStep] = useState(0);
  const [edit, setEdit] = useState(false);
  const [images, setImages] = useState(false);
  const [imageList, setImageList] = useState([]);

  const handleShowImages = () => {
    setImages(!images);
  };

  const handleNext = () => {
    if (step === 3) {
      setStep(3);
    } else {
      setStep(step + 1);
    }
  };

  const handlePrev = () => {
    setStep(step - 1);
  };

  const handleImageList = (photos) => {
    setImageList(photos);
  };

  return (
    <div className="w-4/5 mt-3">
      {/* Title */}
      <div className="flex items-center">
        <div className="text-2xl text-slate-400 flex pr-4 border-r-2">
          Inventory
        </div>
        <div className={`${edit && "text-slate-400"} text-2xl  px-4`}>
          Audi A4
        </div>
        {/* {edit ? (
          <div className="text-2xl border-l-2 pl-4">
            Update Vehicle Information
          </div>
        ) : (
          <div
            className="flex stroke-blue-500 text-blue-500 cursor-pointer"
            onClick={() => setEdit(true)}
          >
            <Edit stroke="s" /> Edit Contents
          </div>
        )} */}
      </div>

      {/* Back Button */}
      {images || edit ? (
        <div
          className="flex items-center text-xl text-blue-500 mt-2 no-underline w-max cursor-pointer"
          onClick={() => {
            setImages(false);
            setEdit(false);
          }}
        >
          <DownArrow className="transform rotate-90 fill-blue-500" />
          Back
        </div>
      ) : (
        <Link
          to="/inventory"
          className="flex items-center text-xl text-blue-500 mt-2 no-underline w-max"
        >
          <DownArrow className="transform rotate-90 fill-blue-500" />
          Back
        </Link>
      )}

      {/* Main Content */}
      {!images && !edit && (
        <CarDetails setImageList={setImageList} inImages={handleShowImages} />
      )}
      {images && !edit && <CarImages imageList={imageList} />}
      {edit && (
        <CarEditDetails
          handleNext={handleNext}
          handlePrev={handlePrev}
          step={step}
        />
      )}
    </div>
  );
};

export default InventoryCarView;
