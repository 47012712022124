import React from "react";

import CarImages from "./carImages";
import EditFeatures from "./editFeatures";
import EditGeneralInfo from "./editGeneralInfo";
import EditDescription from "./editDescription";
import { CheckMark, DownArrow } from "../../../../assets/icons/icons";

const CarEditDetails = (props) => {
  const renderTrackActiveStep = () => (
    <div className="w-1/4 ml-5 border-l">
      <div
        className={`${
          props.step === 0
            ? "text-blue-500 border-l-2 border-blue-500"
            : "text-slate-400"
        } pl-4`}
      >
        Vehicle General Details
      </div>
      <div
        className={`${
          props.step === 1
            ? "text-blue-500 border-l-2 border-blue-500"
            : "text-slate-400"
        } mt-3 pl-4`}
      >
        Description
      </div>
      <div
        className={`${
          props.step === 2
            ? "text-blue-500 border-l-2 border-blue-500"
            : "text-slate-400"
        } mt-3 pl-4`}
      >
        Features
      </div>
      <div
        className={`${
          props.step === 3
            ? "text-blue-500 border-l-2 border-blue-500"
            : "text-slate-400"
        } mt-3 pl-4`}
      >
        Vehicle Images
      </div>
    </div>
  );

  return (
    <>
      <div className="flex">
        {/* Left Side */}
        <div className="w-3/4 mr-5">
          {props.step === 0 && <EditGeneralInfo />}
          {props.step === 1 && <EditDescription />}
          {props.step === 2 && <EditFeatures />}
          {props.step === 3 && <CarImages />}
        </div>

        {/* Right Side */}
        {renderTrackActiveStep()}
      </div>

      {/* Navigation Buttons */}
      <div className="flex items-center justify-between my-5">
        {props.step !== 0 ? (
          <div
            className="text-blue-500 hover:text-white hover:bg-blue-500 group cursor-pointer flex items-center justify-center rounded-full w-max py-2 pr-4 pl-2"
            onClick={props.handlePrev}
          >
            <DownArrow
              className="rotate-90 fill-blue-500 group-hover:fill-white"
              height="16"
            />
            Previous
          </div>
        ) : (
          <div />
        )}
        <div className="flex">
          {props.step !== 3 && (
            <div
              className="group border-2 border-blue-500 hover:border-white text-blue-500 hover:text-white hover:bg-blue-500 cursor-pointer flex items-center justify-center rounded-full w-max py-2 px-4"
              onClick={props.handleNext}
            >
              Save and proceed to next section{" "}
              <DownArrow
                className="-rotate-90 fill-blue-500 group-hover:fill-white"
                height="16"
              />
            </div>
          )}
          <div className="flex items-center justify-center ml-5 px-4 py-2 w-max border-2 border-blue-500 bg-blue-500 hover:bg-white text-slate-50 hover:text-blue-500 cursor-pointer rounded-full">
            Save and update <CheckMark className="ml-2" />
          </div>
        </div>
      </div>
    </>
  );
};

export default CarEditDetails;
