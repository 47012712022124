import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import TodaySchedule from "./todaySchedule";
import CurrentStatus from "./currentStatus";
import { getOrders } from "../../../../api/orders/helper";

const DealerHome = () => {

  // let [orderWithStatuses, setOrderWithStatuses] = useState([]);
  // const { orderWithStatuses } = useContext(DealerOrderContext);

  // const loadOrders = () => {
  //   const token = JSON.parse(localStorage.getItem("token"));
  //   getOrders(token).then((orders) => {

  //     let filterData = [];

  //     for (let i in orders) {
  //       if (orders[i].status === '') {
  //         if (orders[i].isTradeinCarAvilable === false) {
  //           filterData.push(orders[i]);
  //         } else if(orders[i].isTradeinCarAvilable === true && orders[i].paveReportURL !== '') {
  //           filterData.push(orders[i]);
  //         }
  //       } else {
  //         filterData.push(orders[i]);
  //       }
  //     }

  //     // console.log(filterData);
  //     setOrderWithStatuses(filterData);
  //   });
  // };

  // useEffect(() => {
  //   loadOrders();
  // }, []);

  // const customInput = (
  //   <button
  //     className="custom-date-input flex text-sm items-center"
  //     onClick={() => setIsDateSelected(true)}
  //   >
  //     {isDateSelected ? format(selectedDate, "eee, dd MMM, yyyy") : `Today`}
  //     <DownArrow width="15" height="15" className="ml-2 fill-blue-500" />
  //   </button>
  // );
  return (
    <>
      <div className="flex xs:flex-wrap md:flex-nowrap my-5">
        {/* Schedule */}
        {/* <div className="xs:w-full md:w-4/6 md:mr-5">
          <div className="flex flex-wrap md:flex-nowrap items-baseline">
            <div>Schedule</div>
            <div>
              <DatePicker
                selected={selectedDate}
                onChange={handleChange}
                dateFormat="dd MMM, yyyy"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                customInput={customInput}
                className="border-2 border-gray-400 rounded-xl ml-3 px-3"
              />
            </div>
            <div className="text-gray-400 text-sm ml-1 md:ml-3">
              Select an event for more details.
            </div>
          </div>
          <div className="border rounded-2xl h-80 mt-3"></div>
        </div> */}

        {/* Todays Schedule */}
        <div className="xs:w-full md:w-6/6 md:mt-0 xs:mt-3 ">
          <div className="text-start">Upcoming Schedule</div>
          <TodaySchedule />
        </div>
      </div>

      {/* Current Status */}
      <div className="mb-3">
        <CurrentStatus />
      </div>
    </>
  );
};

export default DealerHome;
