import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { setupNotifications } from "./firebase";
import { toastNotification, sendNativeNotification } from "./api/orders/helper";
import useVisibilityChange from "./useVisibilityChange";
import { register } from "./serviceWorkerRegistration";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";

import DealerSigninOTP from "./component/feature/dealerSignin/container/delaerSigninOTP.container";
import DealerCustomization from "./component/feature/dealerSignup/components/dealerCustomization";
import InventoryCarView from "./component/feature/inventory/container/inventoryCarView.container";
import DealerSignup from "./component/feature/dealerSignup/container/dealerSignup.container";
import StartSelling from "./component/feature/dealerSignup/container/startSelling.container";
import DealerSignin from "./component/feature/dealerSignin/container/dealerSignin.container";
import Inventory from "./component/feature/inventory/container/inventoryHome.container";
import Dashboard from "./component/feature/dashboard/container/dashboardHome.container";
import DealerAgreement from "./component/feature/dealerSignup/components/dealerAgreement";
import LiverOrder from "./component/feature/liveOrder/container/liverOrder.container";
import HomePage from "./component/feature/homepage/container/homePage.container";
import Setting from "./component/feature/setting/container/setting.container";
import Help from "./component/feature/help/container/help.container";
import Header from "./component/common/header";
import Footer from "./component/common/footer";
import { getLiveOrders } from "./api/orders/helper";
// import CheckoutForm from "./component/feature/payment/component/checkout";
import CheckoutForm from "./component/feature/payment/component/checkout";
import AddSignaturePlaceholder from "./component/common/modal/AddPdfSignaturePlaceholder";
import PersonaInquiryButton from "./component/feature/persona/component/id_verification";
import EditDealerInventoryFeed from "./component/feature/dealerSignup/components/editDealerInventory";
import AdditionalCarServices from "./component/feature/dashboard/component/additionalCarServices";
import RetryOnboarding from "./component/feature/dashboard/container/retryStripeOnBoarding";
import CustomerOrderInformation from "./component/feature/dashboard/component/customerOrderInformation";

import { ProtectedRoute } from "./ProtectedRoute";
import { DealerOrderProvider } from "./context/dealerOrderContext";
const stripePromise = loadStripe('pk_live_51OYse8KialSzstv1lm42Mz7vmCh9Fi7PBxSxDWzDBvUUDB4D1epfHXCJFqAyzHtFF2wSFLQ9As5tlYMECQMf6R1C00KrL8cYoW');

function App() {

  const [liveOrdersCount, setLiveOrdersCount] = useState(null);
  const [liveOrders, setLiveOrders] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [dealerLoggedIn, setDealerLoggedIn] = useState(false);

  const loadLiveOrders = () => {
    const token = JSON.parse(localStorage.getItem("token"));
    getLiveOrders(token).then((orders) => {
      let ordersCount = 0;
      if(orders) {
        ordersCount = orders.length;
      }
      setLiveOrdersCount(ordersCount);
    });
  };

  const checkLoginToken = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("token"));
      const response = await axios.get('https://api.uobo.ca/api/dealer/check-token', {
        headers: {
          Authorization: token,
        },
      });  

      setDealerLoggedIn(true);

      return response;
    } catch (error) {
      if(error.response.status === 401) {
        setDealerLoggedIn(false);
        localStorage.removeItem("token");
        localStorage.removeItem("dealerSignin");
        localStorage.removeItem("otpVerified");
        localStorage.removeItem("accountCreated");
      }
    }
  }

  const isForeground = useVisibilityChange();

  useEffect(() => {
    loadLiveOrders();
    
    checkLoginToken();

  }, [])

  return (
    <div className="App flex flex-col min-h-screen">
      <Router>
        <Header liveOrdersCount={liveOrdersCount} notifications={notifications} />
        <main className="flex-grow">
          <div className="flex justify-center">
            <DealerOrderProvider>

            <Routes>
              <Route
                exact
                path="/dealer-signin"
                element={
                  <ProtectedRoute>
                    <DealerSignin />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/dealer-signup"
                element={
                  <ProtectedRoute>
                    <DealerSignup />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/dealer-signin-otp"
                element={
                  <ProtectedRoute>
                    <DealerSigninOTP />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/dealer-customization"
                element={<DealerCustomization />}
              />
              <Route
                exact
                path="/dealer-agreement"
                element={<DealerAgreement />}
              />
              <Route
                exact
                path="/dealer-logo-and-location"
                element={<StartSelling />}
              />
              <Route
                exact
                path="/dealer-dashboard"
                element={
                  <ProtectedRoute>
                      <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/inventory"
                element={
                  <ProtectedRoute>
                    <Inventory />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/inventory/:id"
                element={
                  <ProtectedRoute>
                    <InventoryCarView />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/live-order"
                element={
                  <ProtectedRoute>
                    <LiverOrder setLiveOrdersCount={setLiveOrdersCount} />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/setting"
                element={
                  <ProtectedRoute>
                    <Setting />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/help"
                element={
                  <ProtectedRoute>
                    <Help />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/payment"
                element={
                  <Elements stripe={stripePromise}>
                    <CheckoutForm />
                  </Elements>
                }
              />
              <Route
                exact
                path="/id_verification"
                element={<PersonaInquiryButton />}
              />
              <Route
                exact
                path="/sign"
                element={
                  <ProtectedRoute>
                    <AddSignaturePlaceholder />
                  </ProtectedRoute>
                }
              />
               <Route
                exact
                path="/update-inventory"
                element={
                  <ProtectedRoute>
                    <EditDealerInventoryFeed />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/additional-car-services"
                element={
                  <ProtectedRoute>
                    <AdditionalCarServices />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/retry-stripe-onboarding"
                element={
                  <ProtectedRoute>
                    <RetryOnboarding />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/customer-information"
                element={
                  <ProtectedRoute>
                    <CustomerOrderInformation />
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<HomePage />} />
            </Routes>
          
            </DealerOrderProvider>
          </div>
        </main>
        <Footer />
      </Router>
      {/* FOR TOASTS */}
      <ToastContainer />
      
    </div>
  );
};

export default App;
