const initialState = {
    orderWithStatuses: null,
  };
  
  export const dealerOrderReducer = (state, action) => {
    switch (action.type) {
      case "SET_ORDERS":
        return { ...state, orderWithStatuses: action.payload };
      default:
        return state;
    }
  };
  