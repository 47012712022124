import image1 from "../images/1.svg";
import image2 from "../images/2.svg";
import image3 from "../images/3.svg";
import image4 from "../images/winwin.png";

import img1 from "../images/londonHonda.png";
import img2 from "../images/sevenSeas.png";
import img3 from "../images/topAuto.png";

export const StackData = [
  {
    id: 1,
    name: "Completely Online",
    content: "We offer creative solution to our customer",
    image: image1,
  },
  {
    id: 2,
    name: "Simple & Secure",
    content: "All our procedures are carefully tested",
    image: image2,
  },
  {
    id: 3,
    name: "Transparent",
    content: "Our unique approach makes all of our partnered dealership",
    image: image3,
  },
  {
    id: 4,
    name: "Win Win",
    content: "Our shared economy values a win win ",
    image: image4,
  },
];

export const ReviewData = [
  {
    id: 1,
    image: img1,
    name: "Jeff",
    from: "London Honda",
    review: "The pre owned dealership industry is about to change",
  },
  {
    id: 2,
    image: img2,
    name: "Hassan",
    from: "Seven Seas Motors",
    review: "We can't wait to get started, Uobo has masisve potential!",
  },
  {
    id: 3,
    image: img3,
    name: "Sam",
    from: "Top Auto Centre",
    review: "Uobo will be transforming the automative industry",
  },
];

export const AggrementPerks = [
  {
    id: 1,
    text: "Extended Customer Reach: Attract a wider customer base to your dealership.",
  },
  {
    id: 2,
    text: "Convenience: Streamlined for customers and operational ease for your dealership.",
  },
  {
    id: 3,
    text: "Future-Proof Technology: Stay competitive in the evolving automotive landscape.",
  },
  // {
  //   id: 4,
  //   text: "Management tools and analytics",
  // },
];

export const TodaysSchedule = [
  {
    id: 1,
    time: "10:00",
    type: "Test Drive",
    car: "2010 Audi A4",
    identity: "884 New Street, London, ON",
  },
  {
    id: 2,
    time: "11:30",
    type: "Pickup",
    car: "2018 Jeep Cherokee",
    identity: "Mrs Sara Johnes",
  },
  {
    id: 3,
    time: "12:45",
    type: "Pickup",
    car: "2019 GMC Yukon",
    identity: "Mr John Doe",
  },
  {
    id: 4,
    time: "13:00",
    type: "Test drive",
    car: "2010 Audi A5",
    identity: "200 Old Street, London, ON",
  },
  {
    id: 5,
    time: "13:20",
    type: "Pickup",
    car: "2020 Toyota Corollo",
    identity: "Mrs Stephene Gonzales",
  },
  {
    id: 6,
    time: "13:55",
    type: "Test Drive",
    car: "2016 GMC Yukon",
    identity: "844 New Street, London, ON",
  },
  {
    id: 7,
    time: "14:30",
    type: "Pickup",
    car: "2010 Audi A4",
    identity: "Mr John Doe",
  },
  {
    id: 8,
    time: "14:55",
    type: "Test Drive",
    car: "2010 Toyota Corollo",
    identity: "200 New Street, London, ON",
  },
  {
    id: 9,
    time: "15:20",
    type: "Pickup",
    car: "2007 Audi A7",
    identity: "Mr James Bond",
  },
  {
    id: 10,
    time: "15:55",
    type: "Pickup",
    car: "2019 Jeep Cherokee",
    identity: "844 New Street, London, ON",
  },
];

export const CarList = [
  {
    id: 0,
    bodyType: "Any",
    make: "Any",
    model: "Any",
    year: "Any",
  },
  {
    id: 1,
    bodyType: "SUV",
    make: "Chevrolet",
    model: "Trax",
  },
  {
    id: 2,
    bodyType: "Sedan",
    make: "Huyndai",
    model: "Elantra",
  },
  {
    id: 3,
    bodyType: "Coupe",
    make: "Chevrolet",
    model: "Camaro",
  },
  {
    id: 4,
    bodyType: "Truck",
    make: "Toyota",
    model: "Tundra",
  },
  {
    id: 5,
    bodyType: "Hatchback",
    make: "Volkswagen",
    model: "Golf",
  },
  {
    id: 6,
    bodyType: "Mini Van",
    make: "Dodge",
    model: "Carvan",
  },
];
