import React, { useState } from "react";

import AudiBack from "../../../../assets/images/AudiA4bk.avif";
import AudiFront from "../../../../assets/images/audiA4Fr.avif";
import AudiInterior from "../../../../assets/images/AudiA4In.avif";
import AudiBackSeat from "../../../../assets/images/AudiA4BkSt.avif";
import AudiInterior2 from "../../../../assets/images/AudiA4in2.avif";
import { Bin, CheckMark, Edit, Plus } from "../../../../assets/icons/icons";

import uoboLogo from "../../../../assets/images/android-chrome-512x512.png";

const CarImages = (props) => {
  const [edit, setEdit] = useState(false);
  const [selectedDiv, setSelectedDiv] = useState([]);

  const fixedBrokenImage = ({ currentTarget }) => {
    currentTarget.onerror = null;
    currentTarget.src = uoboLogo;
  };

  const renderEditBtn = () => {
    return (
      <>
        {edit ? (
          <div
            className="mb-3 flex justify-between"
            onClick={() => setEdit(!edit)}
          >
            <div>
              Select images that you wish to <b>remove permanently</b> from your
              inventory.
            </div>
            <div
              className="flex cursor-pointer text-red-400"
              onClick={() => handleDeleteClick()}
            >
              <Bin className="fill-red-400" /> Remove selected images
            </div>
          </div>
        ) : (
          <div
            className="mb-3 flex cursor-pointer justify-end stroke-blue-500 text-blue-500"
            onClick={() => setEdit(!edit)}
          >
            <Edit stroke="s" /> Edit Images
          </div>
        )}
      </>
    );
  };

  const handleDivClick = (index) => {
    if (edit) {
      // Check if the div is already selected
      const isSelected = selectedDiv.includes(index);

      if (isSelected) {
        // If already selected, remove it from the array
        setSelectedDiv((prevSelected) =>
          prevSelected.filter((selected) => selected !== index),
        );
      } else {
        // If not selected, add it to the array
        setSelectedDiv((prevSelected) => [...prevSelected, index]);
      }
    }
  };

  const handleDeleteClick = () => {
    if (selectedDiv !== null) {
      // Implement your delete logic here (e.g., remove the selected div from the list)
      console.log(`Deleting div ${selectedDiv}`);
      setSelectedDiv([]); // Reset selected div after deletion
    }
  };

  return (
    <div className="mt-3">
      {/* Main Image */}
      <div className="flex flex-wrap justify-evenly ">
        {props.imageList.map((image) => {
          return (
            <img
              src={image}
              className="mb-3 h-52 w-30 rounded object-cover"
              onError={fixedBrokenImage}
            />
          );
        })}
      </div>
    </div>
  );
};

// OLD CODE:

// <div className="mt-3">

// {/* {renderEditBtn()} */}
// <div className="flex">
//   {/* Main Image */}
//   <div
//     className={`${edit && "relative cursor-pointer"} w-3/5`}
//     onClick={() => handleDivClick(1)}
//   >
//     <img
//       className="h-80 w-full object-cover rounded bg-red-100"
//       src={AudiFront}
//       alt="AudiFront"
//     />
//     {selectedDiv.includes(1) && (
//       <div className="absolute inset-0 bg-blue-500 opacity-30" />
//     )}
//     {/* {edit && selectedDiv.includes(1) ? (
//       <CheckMark className="absolute w-8 h-8 right-3 top-3" />
//     ) : (
//       <div className="absolute w-8 h-8 rounded-full bg-white border right-3 top-3" />
//     )} */}
//   </div>

//   <div className="w-2/5">
//     {/* Front Image */}
//     <div
//       className={`${edit && "relative cursor-pointer"} ml-3`}
//       onClick={() => handleDivClick(2)}
//     >
//       <img
//         className="h-36 w-full object-cover rounded bg-red-100"
//         src={AudiBack}
//         alt="AudiFront"
//       />
//       {selectedDiv.includes(2) && (
//         <div className="absolute inset-0 bg-blue-500 opacity-30" />
//       )}

//       {/* {edit && selectedDiv.includes(2) ? (
//         <CheckMark className="absolute w-8 h-8 right-3 top-3" />
//       ) : (
//         <div className="absolute w-8 h-8 rounded-full bg-white border right-3 top-3" />
//       )} */}
//     </div>
//     {/* Back Image */}
//     <div
//       className={`${edit && "relative cursor-pointer"} ml-3 mt-3`}
//       onClick={() => handleDivClick(3)}
//     >
//       <img
//         className="h-40 w-full object-cover rounded bg-red-100"
//         src={AudiInterior2}
//         alt="AudiInterior"
//       />
//       {selectedDiv.includes(3) && (
//         <div className="absolute inset-0 bg-blue-500 opacity-30" />
//       )}
//       {/* {edit && selectedDiv.includes(3) ? (
//         <CheckMark className="absolute w-8 h-8 right-3 top-3" />
//       ) : (
//         <div className="absolute w-8 h-8 rounded-full bg-white border right-3 top-3" />
//       )} */}
//     </div>
//   </div>
// </div>
// <div className="flex mt-3">
//   <div
//     className={`${edit && "relative cursor-pointer"} w-1/2 mr-3`}
//     onClick={() => handleDivClick(4)}
//   >
//     <img
//       className="h-52 w-full object-cover rounded"
//       src={AudiInterior}
//       alt="AudiInterior"
//     />
//     {selectedDiv.includes(4) && (
//       <div className="absolute inset-0 bg-blue-500 opacity-30" />
//     )}
//     {/* {edit && selectedDiv.includes(4) ? (
//       <CheckMark className="absolute w-8 h-8 right-3 top-3" />
//     ) : (
//       <div className="absolute w-8 h-8 rounded-full bg-white border right-3 top-3" />
//     )} */}
//   </div>
//   <div
//     className={`${edit && "relative cursor-pointer"} w-1/2`}
//     onClick={() => handleDivClick(5)}
//   >
//     <img
//       className="h-52 w-full object-cover rounded"
//       src={AudiBackSeat}
//       alt="AudiBackSeat"
//     />
//     {selectedDiv.includes(5) && (
//       <div className="absolute inset-0 bg-blue-500 opacity-30" />
//     )}
//     {/* {edit && selectedDiv.includes(5) ? (
//       <CheckMark className="absolute w-8 h-8 right-3 top-3" />
//     ) : (
//       <div className="absolute w-8 h-8 rounded-full bg-white border right-3 top-3" />
//     )} */}
//   </div>
// </div>
// {/* Add New Image */}
// <div className="w-45 lg:w-60 lg:h-52 h-44 mt-3 rounded text-blue-500 bg-blue-50 border-2 border-blue-500 flex flex-col items-center justify-center cursor-pointer">
//   <Plus className="fill-blue-500" height="50" width="50" />
//   Add a new Image
// </div>
// </div>

export default CarImages;
