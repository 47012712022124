import React, { useEffect, useState } from "react";

import uoboLogo from "../../../../assets/images/android-chrome-512x512.png";

import { FourSquare, Location } from "../../../../assets/icons/icons";
import { getUserInventory } from "../../../../api/inventory/helper";
import { inventorySorting } from "../inventorySorting";
import Loading from "../../../common/Loading";

import "../../../../assets/scss/custom.scss";

const capitalizeWord = (word) => {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
};

const InventoryList = (props) => {
  const [inventory, setInventory] = useState([]);

  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem("dealerSignin"))._id;
    const token = JSON.parse(localStorage.getItem("token"));

    getUserInventory(userId, token)
      .then((userInventory) => {
        setInventory(inventorySorting(props.sort, userInventory));
      })
      .catch((error) => {
        console.error(error, "User Inventory API Error!");
      });
  }, [props.sort]);

  const renderListContent = () => {
    return inventory.map((data) => (
      <div
        key={data.Inventory_Id}
        onClick={() => props.handleCarClick(data.Inventory_Id)}
        className="mx-2 mb-4 w-350 cursor-pointer rounded border p-2 hover:bg-blue-100"
      >
        <div className="custom-container h-56">
          <img
            src={data.Main_Photo}
            alt={"Car Photo is missing! :("}
            className="h-full w-full rounded object-cover"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = uoboLogo;
            }}
          />
        </div>
        <div className="custom-container ml-2 flex items-center text-lg">
          {data.Make} {data.Model} | {data.Year}
        </div>
        <div className="custom-container ml-1 flex items-center text-sm text-slate-600">
          <FourSquare className="mr-1 fill-slate-600 p-1" />{" "}
          {capitalizeWord(data.Body_Style)}
        </div>
        <div className="flex items-center justify-between">
          <div className="custom-container flex items-center justify-center text-sm text-slate-600">
            <Location className="mr-1 fill-slate-600 p-1" />{" "}
            {data.Vehicle_Location}
          </div>
          <div className="custom-container flex items-center justify-center text-sm text-slate-600">
            <div className="mr-1 flex h-5 w-5 justify-center rounded-full bg-slate-600 text-white">
              $
            </div>
            {data.Price}
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="flex w-full flex-wrap justify-evenly">
      {inventory.length === 0 && <Loading />}
      {renderListContent()}
    </div>
  );
};

export default InventoryList;
