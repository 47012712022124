import React from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const EditDescription = () => {
  return (
    <>
      <div className="text-xl mt-4 mb-5">Description and Pricing</div>
      <div className="flex items-center">
        <div className="text-slate-400 w-44">Pricing</div>
        <InputGroup className="">
          <Form.Control placeholder="Price" aria-label="Price" />
          <InputGroup.Text>$</InputGroup.Text>
        </InputGroup>
      </div>

      <div className="flex mt-4">
        <div className="text-slate-400 w-44">Description</div>
        <textarea
          rows="6"
          id="description"
          name="description"
          placeholder="Enter car description"
          className="border w-full p-3 rounded"
        />
      </div>
    </>
  );
};

export default EditDescription;
