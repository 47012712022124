import React from "react";

export const CheckMark = (props) => (
  <svg
    viewBox="0 0 16 16"
    className={props.className}
    height={props.height || "16"}
    width={props.width || "16"}
  >
    <path
      fill={props.pathFill || "#2583ef"}
      d="M8,0C3.582,0,0,3.582,0,8s3.582,8,8,8s8-3.582,8-8S12.418,0,8,0z"
    />
    <polygon
      fill={props.polygonFill || "#fff"}
      points="7,12 3.48,8.48 4.894,7.066 7,9.172 11.71,4.462 13.124,5.876"
    />
  </svg>
);

export const Plus = (props) => (
  <svg
    className={props.className}
    viewBox="0 0 24 24"
    height={props.height || "24"}
    width={props.width || "24"}
    clipRule="evenodd"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
  >
    <path
      d="m11 11h-7.25c-.414 0-.75.336-.75.75s.336.75.75.75h7.25v7.25c0 .414.336.75.75.75s.75-.336.75-.75v-7.25h7.25c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-7.25v-7.25c0-.414-.336-.75-.75-.75s-.75.336-.75.75z"
      fillRule="nonzero"
    />
  </svg>
);

export const CirclePlus = (props) => (
  <svg
    className={props.className}
    height={props.height || "50"}
    width={props.width || "50"}
    viewBox="0,0,255.98959,255.98959"
    style={{ fill: "#000000" }}
  >
    <g
      fill={props.pathFill || "#3b82f6"}
      fillRule="nonzero"
      stroke="white"
      strokeWidth="1"
      strokeLinecap="butt"
      strokeLinejoin="miter"
      strokeMiterlimit="10"
      strokeDasharray=""
      strokeDashoffset="0"
      fontFamily="none"
      fontWeight="none"
      fontSize="none"
      textAnchor="none"
      style={{ mixBlendMode: "normal" }}
    >
      <g transform="scale(5.12,5.12)">
        <path d="M25,2c-12.683,0 -23,10.317 -23,23c0,12.683 10.317,23 23,23c12.683,0 23,-10.317 23,-23c0,-12.683 -10.317,-23 -23,-23zM37,26h-11v11h-2v-11h-11v-2h11v-11h2v11h11z"></path>
      </g>
    </g>
  </svg>
);

export const Location = (props) => (
  <svg
    className={props.className}
    height={props.height || "24"}
    width={props.width || "24"}
    viewBox="0 0 24 24"
  >
    <path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z" />
  </svg>
);

export const Bin = (props) => (
  <svg
    className={props.className}
    height={props.height || "24"}
    width={props.width || "24"}
    viewBox="0 0 24 24"
  >
    <path d="M 10 2 L 9 3 L 5 3 C 4.448 3 4 3.448 4 4 C 4 4.552 4.448 5 5 5 L 7 5 L 17 5 L 19 5 C 19.552 5 20 4.552 20 4 C 20 3.448 19.552 3 19 3 L 15 3 L 14 2 L 10 2 z M 5 7 L 5 20 C 5 21.105 5.895 22 7 22 L 17 22 C 18.105 22 19 21.105 19 20 L 19 7 L 5 7 z"></path>
  </svg>
);

export const DealerSignupLogo = (props) => (
  <svg
    className={props.className}
    height={props.height || "388"}
    width={props.width || "388"}
    viewBox="0 0 388 387"
  >
    <defs>
      <clipPath id="clipPath">
        <ellipse
          id="Ellipse_333"
          data-name="Ellipse 333"
          cx="19.462"
          cy="19.462"
          rx="19.462"
          ry="19.462"
          fill="#fff"
          stroke="#707070"
          strokeWidth="1"
        />
      </clipPath>
      <clipPath id="clipPath-3">
        <ellipse
          id="Ellipse_335"
          data-name="Ellipse 335"
          cx="20"
          cy="19"
          rx="20"
          ry="19"
          transform="translate(0.163 0)"
          fill="#fff"
          stroke="#707070"
          strokeWidth="1"
        />
      </clipPath>
      <clipPath id="clipPath-6">
        <ellipse
          id="Ellipse_335-2"
          data-name="Ellipse 335"
          cx="20"
          cy="19"
          rx="20"
          ry="19"
          transform="translate(-0.163 0.001)"
          fill="#fff"
          stroke="#707070"
          strokeWidth="1"
        />
      </clipPath>
    </defs>
    <g
      id="Group_10550"
      data-name="Group 10550"
      transform="translate(-62.098 -174.584)"
    >
      <line
        id="Line_9"
        data-name="Line 9"
        x1="60.851"
        y2="2.432"
        transform="translate(283.691 380.835)"
        fill="none"
        stroke="#95989a"
        strokeWidth="0.5"
      />
      <line
        id="Line_10"
        data-name="Line 10"
        x2="60.851"
        y2="2.432"
        transform="translate(167.655 380.835)"
        fill="none"
        stroke="#95989a"
        strokeWidth="0.5"
      />
      <path
        id="Path_3052"
        data-name="Path 3052"
        d="M146.316,89.489,0,60.463"
        transform="translate(271.679 361.076)"
        fill="none"
        stroke="#95989a"
        strokeWidth="1"
      />
      <path
        id="Path_3053"
        data-name="Path 3053"
        d="M0,89.489,146.316,60.463"
        transform="translate(94.205 361.076)"
        fill="none"
        stroke="#95989a"
        strokeWidth="1"
      />
      <line
        id="Line_7"
        data-name="Line 7"
        x1="40.243"
        y1="29.687"
        transform="translate(296.872 446.357)"
        fill="none"
        stroke="#95989a"
        strokeWidth="0.5"
      />
      <line
        id="Line_11"
        data-name="Line 11"
        y1="29.687"
        x2="40.243"
        transform="translate(182.991 446.357)"
        fill="none"
        stroke="#95989a"
        strokeWidth="0.5"
      />
      <line
        id="Line_8"
        data-name="Line 8"
        y2="60.491"
        transform="translate(256.213 279.63)"
        fill="none"
        stroke="#1a76ff"
        strokeWidth="2"
        opacity="0.8"
      />
      <g
        id="Group_10530"
        data-name="Group 10530"
        transform="translate(326.901 464.501)"
      >
        <ellipse
          id="Ellipse_334"
          data-name="Ellipse 334"
          cx="19.462"
          cy="19.462"
          rx="19.462"
          ry="19.462"
          transform="translate(-0.001 0)"
          fill="#e6e6e6"
        />
        <g
          id="Mask_Group_1"
          data-name="Mask Group 1"
          transform="translate(-0.001 0)"
          clipPath="url(#clipPath)"
        >
          <g id="noun_User_829920" transform="translate(2.748 4.58)">
            <g
              id="Group_10524"
              data-name="Group 10524"
              transform="translate(0 0)"
            >
              <path
                id="Path_3025"
                data-name="Path 3025"
                d="M.907,313.442c.085-.634.15-1.275.262-1.9A16.727,16.727,0,0,1,8.25,300.613a13.19,13.19,0,0,0,9.39,3.819,13.229,13.229,0,0,0,9.4-3.832c.53.418,1.085.817,1.6,1.269a16.539,16.539,0,0,1,5.63,10.7c.033.288.078.582.118.87v1.046s-8.37,3.741-16.739,3.741S.9,314.488.9,314.488.907,313.789.907,313.442Z"
                transform="translate(-0.9 -283.815)"
                fill="#787c81"
              />
              <path
                id="Path_3026"
                data-name="Path 3026"
                d="M112.588,1c.552.109,1.114.18,1.649.333a8.369,8.369,0,1,1-4.058-.147c.36-.082.732-.126,1.1-.186Z"
                transform="translate(-95.186 -1)"
                fill="#787c81"
              />
            </g>
          </g>
        </g>
      </g>
      <g
        id="Group_10539"
        data-name="Group 10539"
        transform="translate(146.377 464.501)"
      >
        <ellipse
          id="Ellipse_334-2"
          data-name="Ellipse 334"
          cx="19.462"
          cy="19.462"
          rx="19.462"
          ry="19.462"
          transform="translate(-0.001 0)"
          fill="#e6e6e6"
        />
        <g
          id="Mask_Group_1-2"
          data-name="Mask Group 1"
          transform="translate(-0.001 0)"
          clipPath="url(#clipPath)"
        >
          <g
            id="noun_User_829920-2"
            data-name="noun_User_829920"
            transform="translate(2.696 4.58)"
          >
            <g
              id="Group_10524-2"
              data-name="Group 10524"
              transform="translate(0 0)"
            >
              <path
                id="Path_3025-2"
                data-name="Path 3025"
                d="M34.372,313.442c-.085-.634-.15-1.275-.262-1.9a16.727,16.727,0,0,0-7.082-10.926,13.19,13.19,0,0,1-9.39,3.819,13.229,13.229,0,0,1-9.4-3.832c-.53.418-1.085.817-1.6,1.269a16.539,16.539,0,0,0-5.63,10.7c-.033.288-.078.582-.118.87v1.046s8.37,3.741,16.739,3.741,16.739-3.741,16.739-3.741S34.372,313.789,34.372,313.442Z"
                transform="translate(-0.9 -283.815)"
                fill="#787c81"
              />
              <path
                id="Path_3026-2"
                data-name="Path 3026"
                d="M111.284,1c-.552.109-1.114.18-1.649.333a8.369,8.369,0,1,0,4.058-.147c-.36-.082-.732-.126-1.1-.186Z"
                transform="translate(-95.208 -1)"
                fill="#787c81"
              />
            </g>
          </g>
        </g>
      </g>
      <g
        id="Group_10531"
        data-name="Group 10531"
        transform="translate(409.936 431.999)"
      >
        <ellipse
          id="Ellipse_336"
          data-name="Ellipse 336"
          cx="20"
          cy="19"
          rx="20"
          ry="19"
          transform="translate(0.163 0)"
          fill="#e6e6e6"
        />
        <g
          id="Mask_Group_2"
          data-name="Mask Group 2"
          transform="translate(0 0)"
          clipPath="url(#clipPath-3)"
        >
          <g id="noun_people_3457284" transform="translate(-1.34 13.323)">
            <g
              id="Group_10526"
              data-name="Group 10526"
              transform="translate(0)"
            >
              <path
                id="Path_3029"
                data-name="Path 3029"
                d="M188.677,4.44A5.857,5.857,0,1,1,182.82,10.3a5.857,5.857,0,0,1,5.857-5.857Z"
                transform="translate(-167.384 -4.065)"
                fill="#787c81"
                fillRule="evenodd"
              />
              <path
                id="Path_3030"
                data-name="Path 3030"
                d="M141.586,155.43c4.492,0,9.105,4.847,10.705,9.407-2.573,2.486-8.327,4.417-12.19,4.417-3.575,0-8.61-4.315-8.715-4.417,1.6-4.559,5.708-9.407,10.2-9.407Z"
                transform="translate(-120.293 -142.307)"
                fill="#787c81"
                fillRule="evenodd"
              />
              <path
                id="Path_3031"
                data-name="Path 3031"
                d="M355.782,0a4.8,4.8,0,1,1-4.8,4.8A4.8,4.8,0,0,1,355.782,0Z"
                transform="translate(-321.346)"
                fill="#787c81"
                fillRule="evenodd"
              />
              <path
                id="Path_3032"
                data-name="Path 3032"
                d="M338.945,123.81c3.684,0,6.837,2.634,8.149,6.373-2.109,2.038-8.289,4.306-8.821,4.231a19.445,19.445,0,0,0-5.684-7.445A8.238,8.238,0,0,1,338.945,123.81Z"
                transform="translate(-304.509 -113.356)"
                fill="#787c81"
                fillRule="evenodd"
              />
              <path
                id="Path_3033"
                data-name="Path 3033"
                d="M44.442,0a4.8,4.8,0,1,1-4.8,4.8A4.8,4.8,0,0,1,44.442,0Z"
                transform="translate(-36.293)"
                fill="#787c81"
                fillRule="evenodd"
              />
              <path
                id="Path_3034"
                data-name="Path 3034"
                d="M8.149,123.81a8.24,8.24,0,0,1,6.355,3.16,18.821,18.821,0,0,0-5.414,7.444c-.532.074-6.981-2.193-9.091-4.231,1.312-3.739,4.466-6.373,8.149-6.373Z"
                transform="translate(0 -113.356)"
                fill="#787c81"
                fillRule="evenodd"
              />
            </g>
          </g>
        </g>
      </g>
      <g
        id="Group_10523"
        data-name="Group 10523"
        transform="translate(204.231 335.685)"
      >
        <rect
          id="Rectangle_2764"
          data-name="Rectangle 2764"
          width="103.738"
          height="171.319"
          rx="29"
          transform="translate(0 0.386)"
          fill="#fff"
        />
        <g
          id="Group_10512"
          data-name="Group 10512"
          transform="translate(0 0.001)"
        >
          <g
            id="Group_10511"
            data-name="Group 10511"
            transform="translate(0 0)"
          >
            <path
              id="Path_3009"
              data-name="Path 3009"
              d="M98.868,1.33H30.528C20.8,1.345,12.915,9.472,12.9,19.5V154.644c.015,10.03,7.9,18.156,17.628,18.172H98.868c9.729-.015,17.613-8.142,17.628-18.172V19.5c-.015-10.03-7.9-18.156-17.628-18.172Zm9.492,153.314a9.642,9.642,0,0,1-9.492,9.785H30.528a9.642,9.642,0,0,1-9.492-9.785V19.5a9.642,9.642,0,0,1,9.492-9.785H45.443a5.5,5.5,0,0,0,5.424,5.256H78.556A5.5,5.5,0,0,0,83.98,9.717H98.9a9.642,9.642,0,0,1,9.492,9.785Z"
              transform="translate(-12.9 -1.33)"
              fill="#696e78"
            />
          </g>
        </g>
      </g>
      <g
        id="Group_10549"
        data-name="Group 10549"
        transform="translate(198.375 174.584)"
      >
        <path
          id="Path_3976"
          data-name="Path 3976"
          d="M58.274,0A58.274,58.274,0,1,1,0,58.274,58.274,58.274,0,0,1,58.274,0Z"
          fill="#e6e6e6"
        />
        <g
          id="noun_Car_1857698"
          transform="translate(15.028 17.707)"
          opacity="0.8"
        >
          <path
            id="Path_3042"
            data-name="Path 3042"
            d="M85.268,27.66a34.681,34.681,0,0,0-6.31,0,1.979,1.979,0,0,0-1.865,2.118,8.47,8.47,0,0,1-.113,1.02c-3.146-3.913-6.206-7.844-9.387-11.61a4.471,4.471,0,0,0-2.44-1.386c-2.423-.479-4.89-.819-7.356-1.055a153.566,153.566,0,0,0-33,.41,9.282,9.282,0,0,0-6.973,3.94c-2.127,2.99-4.55,5.761-6.851,8.629-.227.288-.479.567-1,1.168.305-2.144-.506-3.216-2.162-3.338a28.475,28.475,0,0,0-5.718,0C-.1,27.878-.671,29.8.872,31.434a5.5,5.5,0,0,0,2.284,1.525,48,48,0,0,0,4.863.976c-.784,1.351-1.612,2.772-2.423,4.21a17.8,17.8,0,0,0-2.423,9.588c.166,4.55,0,9.108.044,13.667,0,2.571,1.011,3.6,3.547,3.6H80.379c2.615,0,3.574-.994,3.582-3.556,0-4.4-.148-8.8.044-13.2a17.031,17.031,0,0,0-2.388-9.465l-2.955-5.23c.68,0,1.185.1,1.691.087a7.562,7.562,0,0,0,6.031-2.3C87.761,29.821,87.264,27.921,85.268,27.66ZM14.408,28.819a61.125,61.125,0,0,0,3.957-4.968c2.17-3.486,5.4-4.5,9.239-4.855a162.709,162.709,0,0,1,33.783.3,8.726,8.726,0,0,1,6.72,3.687c2.336,3.329,4.924,6.476,7.635,9.98H11.331C12.464,31.434,13.405,30.109,14.408,28.819ZM25.643,51.847H14.12c-1.97,0-3.643-1.194-3.73-2.615s1.307-5.16,3.277-4.968l6.363.619c1.961.192,6.293,3.565,7.321,4.785S27.621,51.847,25.643,51.847Zm50.666-2.615c-.087,1.456-1.743,2.615-3.739,2.615H61.012c-1.97,0-2.737-.994-1.743-2.214s5.36-4.593,7.321-4.785l6.363-.619c2-.192,3.434,3.5,3.356,4.968Z"
            transform="translate(-0.003 -16.051)"
            fill="#4c4c4c"
          />
          <path
            id="Path_3043"
            data-name="Path 3043"
            d="M5.864,75.18c0,1.743-.087,3.347,0,4.933a2.728,2.728,0,0,0,2.868,2.676h6.1a2.536,2.536,0,0,0,2.676-2.327,46.255,46.255,0,0,0,.044-5.282Z"
            transform="translate(-0.751 -23.644)"
            fill="#4c4c4c"
          />
          <path
            id="Path_3044"
            data-name="Path 3044"
            d="M80.685,75.22c0,1.743-.07,3.277,0,4.82a2.772,2.772,0,0,0,2.946,2.754h5.892a2.693,2.693,0,0,0,2.841-2.536c.122-1.639,0-3.286,0-5.038Z"
            transform="translate(-10.359 -23.649)"
            fill="#4c4c4c"
          />
        </g>
        <g
          id="noun_Handshake_3851135"
          transform="translate(36.35 76.857)"
          opacity="0.8"
        >
          <path
            id="Path_3037"
            data-name="Path 3037"
            d="M33.787,33.876a8.433,8.433,0,0,0-4.119,2.208.458.458,0,0,1-.092.068c-2.974,1.789-4.65.428-4.719.347-1.9-1.588-.157-4.25-.083-4.361a16.5,16.5,0,0,1,2.808-3.278H19.5L16,39.576a.423.423,0,0,1,.1.073A100.437,100.437,0,0,0,31.612,52.317a1.536,1.536,0,0,0,1.973-.211,1.278,1.278,0,0,0-.094-1.839l-3.92-3.633a.406.406,0,0,1,0-.6.488.488,0,0,1,.656,0l2.087,1.934h0l1.847,1.712h0l.924.877a1.868,1.868,0,0,0,2.5.02,1.556,1.556,0,0,0,.021-2.318l-3.6-3.479h0l-.683-.655a.406.406,0,0,1,0-.608.488.488,0,0,1,.656,0l5.25,5.05a1.955,1.955,0,0,0,1.974.377,1.726,1.726,0,0,0,1.171-1.52,1.605,1.605,0,0,0-.554-1.254l-3.149-2.825-2.193-1.973a.41.41,0,0,1-.155-.423.445.445,0,0,1,.336-.32.485.485,0,0,1,.461.131l6.234,5.593a1.831,1.831,0,0,0,1.72.425,1.667,1.667,0,0,0,1.22-1.2,1.542,1.542,0,0,0-.561-1.565Z"
            transform="translate(-10.459 -24.963)"
            fill="#4c4c4c"
          />
          <path
            id="Path_3038"
            data-name="Path 3038"
            d="M11.638,21.143A18.221,18.221,0,0,0,4,19.765V36.549H6.821a1.375,1.375,0,0,0,1.335-.929l4.239-12.937a1.256,1.256,0,0,0-.757-1.541Z"
            transform="translate(-4 -19.753)"
            fill="#4c4c4c"
          />
          <path
            id="Path_3039"
            data-name="Path 3039"
            d="M45.645,24.536a4.656,4.656,0,0,0-3.122-.064,13.059,13.059,0,0,0-6.617,5.469c0,.026-1.418,2.14-.083,3.278.046.039,1.238.963,3.546-.4a9.011,9.011,0,0,1,4.876-2.465.485.485,0,0,1,.351.111L56.7,40.734c.055.047.106.1.157.15a4,4,0,0,0,2.577-1.8l-4.387-11.08Z"
            transform="translate(-20.638 -22.316)"
            fill="#4c4c4c"
          />
          <path
            id="Path_3040"
            data-name="Path 3040"
            d="M88.054,24.549a21.841,21.841,0,0,0-7.693,1.344,1.253,1.253,0,0,0-.748,1.639L84.752,40.5a1.417,1.417,0,0,0,1.321.856h2.9V24.571C88.747,24.558,88.428,24.549,88.054,24.549Z"
            transform="translate(-44.465 -22.497)"
            fill="#4c4c4c"
          />
        </g>
      </g>
      <text
        id="UOBO"
        transform="translate(224.099 422.964)"
        fill="rgba(0,0,0,0.74)"
        fontSize="16"
        fontFamily="Merel-Regular, Merel"
        letterSpacing="0.35em"
      >
        <tspan x="0" y="0">
          UOBO
        </tspan>
      </text>
      <g
        id="Group_10537"
        data-name="Group 10537"
        transform="translate(344.26 361.371)"
      >
        <ellipse
          id="Ellipse_334-3"
          data-name="Ellipse 334"
          cx="19.462"
          cy="19.462"
          rx="19.462"
          ry="19.462"
          transform="translate(0 0.001)"
          fill="#e6e6e6"
        />
        <g
          id="Mask_Group_1-3"
          data-name="Mask Group 1"
          transform="translate(0 0.001)"
          clipPath="url(#clipPath)"
        >
          <g
            id="noun_User_829920-3"
            data-name="noun_User_829920"
            transform="translate(2.696 4.58)"
          >
            <g
              id="Group_10524-3"
              data-name="Group 10524"
              transform="translate(0 0)"
            >
              <path
                id="Path_3025-3"
                data-name="Path 3025"
                d="M34.372,313.442c-.085-.634-.15-1.275-.262-1.9a16.727,16.727,0,0,0-7.082-10.926,13.19,13.19,0,0,1-9.39,3.819,13.229,13.229,0,0,1-9.4-3.832c-.53.418-1.085.817-1.6,1.269a16.54,16.54,0,0,0-5.63,10.7c-.033.288-.078.582-.118.87v1.046s8.37,3.741,16.739,3.741,16.739-3.741,16.739-3.741S34.372,313.789,34.372,313.442Z"
                transform="translate(-0.9 -283.815)"
                fill="#787c81"
              />
              <path
                id="Path_3026-3"
                data-name="Path 3026"
                d="M111.284,1c-.552.109-1.114.18-1.649.333a8.369,8.369,0,1,0,4.058-.147c-.36-.082-.732-.126-1.1-.186Z"
                transform="translate(-95.208 -1)"
                fill="#787c81"
              />
            </g>
          </g>
        </g>
      </g>
      <g
        id="Group_10540"
        data-name="Group 10540"
        transform="translate(129.017 361.373)"
      >
        <ellipse
          id="Ellipse_334-4"
          data-name="Ellipse 334"
          cx="19.462"
          cy="19.462"
          rx="19.462"
          ry="19.462"
          transform="translate(0 -0.001)"
          fill="#e6e6e6"
        />
        <g
          id="Mask_Group_1-4"
          data-name="Mask Group 1"
          transform="translate(0 -0.001)"
          clipPath="url(#clipPath)"
        >
          <g
            id="noun_User_829920-4"
            data-name="noun_User_829920"
            transform="translate(2.748 4.58)"
          >
            <g
              id="Group_10524-4"
              data-name="Group 10524"
              transform="translate(0 0)"
            >
              <path
                id="Path_3025-4"
                data-name="Path 3025"
                d="M.907,313.442c.085-.634.15-1.275.262-1.9A16.727,16.727,0,0,1,8.25,300.613a13.19,13.19,0,0,0,9.39,3.819,13.229,13.229,0,0,0,9.4-3.832c.53.418,1.085.817,1.6,1.269a16.539,16.539,0,0,1,5.63,10.7c.033.288.078.582.118.87v1.046s-8.37,3.741-16.739,3.741S.9,314.488.9,314.488.907,313.789.907,313.442Z"
                transform="translate(-0.9 -283.815)"
                fill="#787c81"
              />
              <path
                id="Path_3026-4"
                data-name="Path 3026"
                d="M112.588,1c.552.109,1.114.18,1.649.333a8.369,8.369,0,1,1-4.058-.147c.36-.082.732-.126,1.1-.186Z"
                transform="translate(-95.186 -1)"
                fill="#787c81"
              />
            </g>
          </g>
        </g>
      </g>
      <path
        id="Path_3051"
        data-name="Path 3051"
        d="M13353.349,5666"
        transform="translate(-13097.274 -5390.761)"
        fill="none"
        stroke="#707070"
        strokeWidth="1"
      />
      <g
        id="Group_10538"
        data-name="Group 10538"
        transform="translate(62.262 431.998)"
      >
        <ellipse
          id="Ellipse_336-2"
          data-name="Ellipse 336"
          cx="20"
          cy="19"
          rx="20"
          ry="19"
          transform="translate(-0.164 0.003)"
          fill="#e6e6e6"
        />
        <g
          id="Mask_Group_2-2"
          data-name="Mask Group 2"
          transform="translate(0 0)"
          clipPath="url(#clipPath-6)"
        >
          <g
            id="noun_people_3457284-2"
            data-name="noun_people_3457284"
            transform="translate(-1.246 13.324)"
          >
            <g id="Group_10526-2" data-name="Group 10526">
              <path
                id="Path_3029-2"
                data-name="Path 3029"
                d="M188.677,4.44a5.857,5.857,0,1,0,5.857,5.857,5.857,5.857,0,0,0-5.857-5.857Z"
                transform="translate(-167.384 -4.065)"
                fill="#787c81"
                fillRule="evenodd"
              />
              <path
                id="Path_3030-2"
                data-name="Path 3030"
                d="M142.091,155.43c-4.492,0-9.105,4.847-10.705,9.407,2.573,2.486,8.327,4.417,12.19,4.417,3.575,0,8.61-4.315,8.715-4.417-1.6-4.559-5.708-9.407-10.2-9.407Z"
                transform="translate(-120.798 -142.307)"
                fill="#787c81"
                fillRule="evenodd"
              />
              <path
                id="Path_3031-2"
                data-name="Path 3031"
                d="M355.782,0a4.8,4.8,0,1,0,4.8,4.8A4.8,4.8,0,0,0,355.782,0Z"
                transform="translate(-347.633)"
                fill="#787c81"
                fillRule="evenodd"
              />
              <path
                id="Path_3032-2"
                data-name="Path 3032"
                d="M340.739,123.81c-3.684,0-6.837,2.634-8.149,6.373,2.109,2.038,8.289,4.306,8.821,4.231a19.445,19.445,0,0,1,5.684-7.445A8.238,8.238,0,0,0,340.739,123.81Z"
                transform="translate(-332.59 -113.356)"
                fill="#787c81"
                fillRule="evenodd"
              />
              <path
                id="Path_3033-2"
                data-name="Path 3033"
                d="M44.442,0a4.8,4.8,0,1,0,4.8,4.8A4.8,4.8,0,0,0,44.442,0Z"
                transform="translate(-10.006)"
                fill="#787c81"
                fillRule="evenodd"
              />
              <path
                id="Path_3034-2"
                data-name="Path 3034"
                d="M6.355,123.81A8.24,8.24,0,0,0,0,126.97a18.821,18.821,0,0,1,5.414,7.444c.532.074,6.981-2.193,9.091-4.231-1.312-3.739-4.466-6.373-8.149-6.373Z"
                transform="translate(28.081 -113.356)"
                fill="#787c81"
                fillRule="evenodd"
              />
            </g>
          </g>
        </g>
      </g>
      <line
        id="Line_13"
        data-name="Line 13"
        x2="64.158"
        transform="translate(224.134 438.115)"
        fill="none"
        stroke="#e4e4e4"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <line
        id="Line_12"
        data-name="Line 12"
        x2="55.909"
        transform="translate(224.134 438.115)"
        fill="none"
        stroke="#1a76ff"
        strokeLinecap="round"
        strokeWidth="2"
        opacity="0.8"
      />
      <ellipse
        id="Ellipse_332"
        data-name="Ellipse 332"
        cx="60.95"
        cy="7.332"
        rx="60.95"
        ry="7.332"
        transform="translate(195.263 547.385)"
        fill="#b1b9c5"
      />
    </g>
  </svg>
);

export const DealerSigninLogo = (props) => (
  <svg width={"233.55"} height={"326.144"} viewBox="0 0 233.55 326.144">
    <defs>
      <filter
        id="_"
        x="89"
        y="233.144"
        width="30"
        height="43"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur" />
        <feFlood floodOpacity="0.102" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="Union_5"
        x="59.105"
        y="106.635"
        width="100.829"
        height="100.411"
        filterUnits="userSpaceOnUse"
      >
        <feOffset input="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur-2" />
        <feFlood floodOpacity="0.102" />
        <feComposite operator="in" in2="blur-2" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="Path_3030"
        x="39.969"
        y="120.627"
        width="111.232"
        height="76.768"
        filterUnits="userSpaceOnUse"
      >
        <feOffset input="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur-3" />
        <feFlood floodOpacity="0.102" />
        <feComposite operator="in" in2="blur-3" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="Ellipse_335"
        x="77"
        y="182.144"
        width="31"
        height="31"
        filterUnits="userSpaceOnUse"
      >
        <feOffset input="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur-4" />
        <feFlood floodOpacity="0.102" />
        <feComposite operator="in" in2="blur-4" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="Ellipse_336"
        x="110"
        y="182.144"
        width="31"
        height="31"
        filterUnits="userSpaceOnUse"
      >
        <feOffset input="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur-5" />
        <feFlood floodOpacity="0.102" />
        <feComposite operator="in" in2="blur-5" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g
      id="Image_-_login_page"
      data-name="Image - login page"
      transform="translate(-163.703 -250.453)"
    >
      <rect
        id="Rectangle_2764"
        data-name="Rectangle 2764"
        width="146.756"
        height="242.359"
        rx="29"
        transform="translate(193.702 297.834)"
        fill="#fff"
      />
      <g
        id="Group_10512"
        data-name="Group 10512"
        transform="translate(195.467 235)"
        opacity="0.9"
      >
        <g
          id="Group_10511"
          data-name="Group 10511"
          transform="translate(-5.495 62.29)"
        >
          <path
            id="Path_3009"
            data-name="Path 3009"
            d="M134.517,1.33H37.837C24.074,1.352,12.921,12.848,12.9,27.037V218.218c.021,14.189,11.174,25.685,24.937,25.707h96.68c13.764-.022,24.916-11.518,24.937-25.707V27.037c-.021-14.189-11.174-25.685-24.937-25.707Zm13.428,216.888a13.64,13.64,0,0,1-13.428,13.842H37.837a13.64,13.64,0,0,1-13.428-13.842V27.037A13.64,13.64,0,0,1,37.837,13.195h21.1a7.776,7.776,0,0,0,7.673,7.435h39.171a7.776,7.776,0,0,0,7.673-7.435h21.1a13.64,13.64,0,0,1,13.428,13.842Z"
            transform="translate(-9.169 -1.33)"
            fill="#595f6a"
          />
        </g>
        <g
          id="noun_Time_4302048"
          transform="translate(132.397 15.953)"
          // style="mix-blend-mode: normal;isolation: isolate"
        >
          <ellipse
            id="Ellipse_207"
            data-name="Ellipse 207"
            cx="29.973"
            cy="29.973"
            rx="29.973"
            ry="29.973"
            transform="translate(7.685 1.665)"
            fill="#fff"
            opacity="0.3"
          />
          <path
            id="Path_3011"
            data-name="Path 3011"
            d="M56.753,14.749a31.572,31.572,0,0,0-44.653,0,1.913,1.913,0,0,0,2.706,2.706,27.748,27.748,0,1,1,0,39.241A1.913,1.913,0,1,0,12.1,59.4,31.574,31.574,0,0,0,56.753,14.749Z"
            transform="translate(2.889 -5.5)"
            fill="#696e78"
            stroke="#707070"
            strokeWidth="1"
          />
          <path
            id="Path_3012"
            data-name="Path 3012"
            d="M24.414,10.059A1.914,1.914,0,0,0,22.5,11.972V32.91a1.914,1.914,0,0,0,.561,1.353L33.53,44.732a1.913,1.913,0,1,0,2.706-2.706l-9.908-9.908V11.972a1.914,1.914,0,0,0-1.914-1.914Z"
            transform="translate(12.902 -1.335)"
            fill="#696e78"
          />
          <path
            id="Path_3013"
            data-name="Path 3013"
            d="M49.336,22.914c0-1.057-1.561-1.914-3.487-1.914H7.487C5.561,21,4,21.857,4,22.914s1.561,1.914,3.487,1.914H45.849C47.775,24.827,49.336,23.971,49.336,22.914Z"
            transform="translate(-24.459 8.661)"
            fill="#696e78"
          />
          <path
            id="Path_3014"
            data-name="Path 3014"
            d="M11.065,18.827H32.523c1.693,0,3.065-.857,3.065-1.914S34.215,15,32.523,15H11.065C9.372,15,8,15.857,8,16.914S9.372,18.827,11.065,18.827Z"
            transform="translate(-10.711 3.179)"
            fill="#696e78"
          />
          <path
            id="Path_3015"
            data-name="Path 3015"
            d="M25.191,28.914A1.912,1.912,0,0,0,23.281,27H9.91a1.914,1.914,0,0,0,0,3.827H23.281A1.912,1.912,0,0,0,25.191,28.914Z"
            transform="translate(-0.314 14.143)"
            fill="#696e78"
          />
        </g>
      </g>
      <ellipse
        id="Ellipse_332"
        data-name="Ellipse 332"
        cx="103.5"
        cy="12"
        rx="103.5"
        ry="12"
        transform="translate(163.703 552.598)"
        fill="#b1b9c5"
      />
      <g
        id="Group_10521"
        data-name="Group 10521"
        transform="translate(28.703 38.598)"
      >
        <rect
          id="Rectangle_2776"
          data-name="Rectangle 2776"
          width="104"
          height="23"
          rx="11.5"
          transform="translate(187 453)"
          fill="rgba(26,118,255,0.83)"
        />
        <g transform="matrix(1, 0, 0, 1, 135, 211.86)" filter="url(#_)">
          <text
            id="_2"
            data-name="$"
            transform="translate(104 258.14)"
            fill="#fff"
            fontSize="18"
            fontFamily="Poppins-Regular, Poppins"
            letterSpacing="0.016em"
          >
            <tspan x="-5" y="0">
              $
            </tspan>
          </text>
        </g>
      </g>
      <g
        id="Group_10524"
        data-name="Group 10524"
        transform="translate(19351.613 23284.598)"
      >
        <g
          id="Group_10524-2"
          data-name="Group 10524"
          transform="translate(-19119.805 -22918.51)"
        >
          <g
            transform="matrix(1, 0, 0, 1, -68.11, -115.63)"
            filter="url(#Union_5)"
          >
            <path
              id="Union_5-2"
              data-name="Union 5"
              d="M8.792,55,.53,45.849a2.046,2.046,0,0,1-.523-1.543L.918,26.974A2.039,2.039,0,0,1,1.6,25.557L23.774,5.644a2.058,2.058,0,0,1,1.773-.49L39.859,7.962,51.234.347A2.063,2.063,0,0,1,53.91.68L60.8,8.3a2.049,2.049,0,0,1-.154,2.9l-1.527,1.373A9.251,9.251,0,0,0,45.348,24.938L29.289,39.357A9.251,9.251,0,0,0,15.524,51.719L11.7,55.15A2.062,2.062,0,0,1,8.792,55ZM4.99,28.034l-.613,11.6L35.861,11.362l-10.1-1.981ZM17.053,50.345a7.218,7.218,0,1,1,5.353,2.375A7.174,7.174,0,0,1,17.053,50.345Zm3.287-7.095a3.092,3.092,0,1,0,2.065-.791A3.072,3.072,0,0,0,20.339,43.251ZM46.877,23.564a7.218,7.218,0,1,1,5.355,2.376A7.17,7.17,0,0,1,46.877,23.564Zm3.289-7.094a3.093,3.093,0,1,0,2.065-.791A3.072,3.072,0,0,0,50.166,16.47Z"
              transform="translate(105.36 115.63) rotate(42)"
              fill="#696e78"
            />
          </g>
        </g>
        <g
          id="Group_10525"
          data-name="Group 10525"
          transform="translate(-19138.941 -22904.518)"
        >
          <g
            transform="matrix(1, 0, 0, 1, -48.97, -129.63)"
            filter="url(#Path_3030)"
          >
            <path
              id="Path_3030-2"
              data-name="Path 3030"
              d="M-19050.674-22846.193h-58.939a2.324,2.324,0,0,1-2.322-2.324,2.324,2.324,0,0,1,2.322-2.324h58.465c.3-.2.795-.734.795-2.105a2.75,2.75,0,0,0-.607-1.943,1.292,1.292,0,0,0-.189-.152h-57.365c-.059,0-.133,0-.215,0-.867,0-4.014-.209-5.865-2.059-1.883-1.883-2.775-5.271-2.93-5.9l-7.713-37.32h-11.377a2.325,2.325,0,0,1-2.324-2.324,2.324,2.324,0,0,1,2.324-2.322h13.123a2.327,2.327,0,0,1,2.234,1.676l8.213,39.049c.01.039.02.076.027.117a9.7,9.7,0,0,0,1.707,3.732,4.537,4.537,0,0,0,2.5.707h0c.074-.006.148-.01.223-.01h57.906a2.259,2.259,0,0,1,.561.068,5.493,5.493,0,0,1,2.369,1.377,7.043,7.043,0,0,1,2.037,5.3,7.055,7.055,0,0,1-2.033,5.3,5.5,5.5,0,0,1-2.365,1.383A2.325,2.325,0,0,1-19050.674-22846.193Z"
              transform="translate(19187.91 23034.59)"
              fill="#696e78"
            />
          </g>
          <g
            transform="matrix(1, 0, 0, 1, -48.97, -129.63)"
            filter="url(#Ellipse_335)"
          >
            <circle
              id="Ellipse_335-2"
              data-name="Ellipse 335"
              cx="6.5"
              cy="6.5"
              r="6.5"
              transform="translate(86 191.14)"
              fill="#696e78"
            />
          </g>
          <g
            transform="matrix(1, 0, 0, 1, -48.97, -129.63)"
            filter="url(#Ellipse_336)"
          >
            <circle
              id="Ellipse_336-2"
              data-name="Ellipse 336"
              cx="6.5"
              cy="6.5"
              r="6.5"
              transform="translate(119 191.14)"
              fill="#696e78"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const Bell = (props) => (
  <svg
    className={props.className}
    height={props.height || "24"}
    width={props.width || "24"}
    viewBox="0 0 24 24"
  >
    <path d="M15.137 3.945c-.644-.374-1.042-1.07-1.041-1.82v-.003c.001-1.172-.938-2.122-2.096-2.122s-2.097.95-2.097 2.122v.003c.001.751-.396 1.446-1.041 1.82-4.667 2.712-1.985 11.715-6.862 13.306v1.749h20v-1.749c-4.877-1.591-2.195-10.594-6.863-13.306zm-3.137-2.945c.552 0 1 .449 1 1 0 .552-.448 1-1 1s-1-.448-1-1c0-.551.448-1 1-1zm3 20c0 1.598-1.392 3-2.971 3s-3.029-1.402-3.029-3h6z" />
  </svg>
);

export const Play = (props) => (
  <svg
    className={props.className}
    viewBox="0 0 24 24"
    height={props.height || "24"}
    width={props.width || "24"}
  >
    <path d="M3 22v-20l18 10-18 10z" />
  </svg>
);

export const Home = (props) => (
  <svg
    className={props.className}
    viewBox="0 0 24 24"
    height={props.height || "24"}
    width={props.width || "24"}
  >
    <path d="M 12 2 A 1 1 0 0 0 11.289062 2.296875 L 1.203125 11.097656 A 0.5 0.5 0 0 0 1 11.5 A 0.5 0.5 0 0 0 1.5 12 L 4 12 L 4 20 C 4 20.552 4.448 21 5 21 L 9 21 C 9.552 21 10 20.552 10 20 L 10 14 L 14 14 L 14 20 C 14 20.552 14.448 21 15 21 L 19 21 C 19.552 21 20 20.552 20 20 L 20 12 L 22.5 12 A 0.5 0.5 0 0 0 23 11.5 A 0.5 0.5 0 0 0 22.796875 11.097656 L 12.716797 2.3027344 A 1 1 0 0 0 12.710938 2.296875 A 1 1 0 0 0 12 2 z"></path>
  </svg>
);

export const Inventory = (props) => (
  <svg
    className={props.className}
    viewBox="0 0 24 24"
    height={props.height || "24"}
    width={props.width || "24"}
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <path d="M6 23h-3v-10l8.991-8.005 9.009 8.005v10h-3v-9h-12v9zm1-2h10v2h-10v-2zm0-3h10v2h-10v-2zm10-3v2h-10v-2h10zm-5-14.029l12 10.661-1.328 1.493-10.672-9.481-10.672 9.481-1.328-1.493 12-10.661z" />
  </svg>
);

export const Help = (props) => (
  <svg
    className={props.className}
    viewBox="0 0 24 24"
    height={props.height || "24"}
    width={props.width || "24"}
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm.053 17c.466 0 .844-.378.844-.845 0-.466-.378-.844-.844-.844-.466 0-.845.378-.845.844 0 .467.379.845.845.845zm.468-2.822h-.998c-.035-1.162.182-2.054.939-2.943.491-.57 1.607-1.479 1.945-2.058.722-1.229.077-3.177-2.271-3.177-1.439 0-2.615.877-2.928 2.507l-1.018-.102c.28-2.236 1.958-3.405 3.922-3.405 1.964 0 3.615 1.25 3.615 3.22 0 1.806-1.826 2.782-2.638 3.868-.422.563-.555 1.377-.568 2.09z" />
  </svg>
);

export const Settings = (props) => (
  <svg
    className={props.className}
    viewBox="0 0 24 24"
    height={props.height || "24"}
    width={props.width || "24"}
  >
    <path d="M24 14v-4c-1.619 0-2.906.267-3.705-1.476-.697-1.663.604-2.596 1.604-3.596l-2.829-2.828c-1.033 1.033-1.908 2.307-3.666 1.575-1.674-.686-1.404-2.334-1.404-3.675h-4c0 1.312.278 2.985-1.404 3.675-1.761.733-2.646-.553-3.667-1.574l-2.829 2.828c1.033 1.033 2.308 1.909 1.575 3.667-.348.849-1.176 1.404-2.094 1.404h-1.581v4c1.471 0 2.973-.281 3.704 1.475.698 1.661-.604 2.596-1.604 3.596l2.829 2.829c1-1 1.943-2.282 3.667-1.575 1.673.687 1.404 2.332 1.404 3.675h4c0-1.244-.276-2.967 1.475-3.704 1.645-.692 2.586.595 3.596 1.604l2.828-2.829c-1-1-2.301-1.933-1.604-3.595l.03-.072c.687-1.673 2.332-1.404 3.675-1.404zm-12 2c-2.209 0-4-1.791-4-4s1.791-4 4-4 4 1.791 4 4-1.791 4-4 4z" />
  </svg>
);

export const DownArrow = (props) => (
  <svg
    className={props.className}
    viewBox="0 0 24 24"
    height={props.height || "24"}
    width={props.width || "24"}
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <path d="M12 17.414 3.293 8.707l1.414-1.414L12 14.586l7.293-7.293 1.414 1.414L12 17.414z" />
  </svg>
);

export const Search = (props) => (
  <svg
    className={props.className}
    viewBox="0 0 24 24"
    height={props.height || "24"}
    width={props.width || "24"}
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
  >
    <path
      d="m15.97 17.031c-1.479 1.238-3.384 1.985-5.461 1.985-4.697 0-8.509-3.812-8.509-8.508s3.812-8.508 8.509-8.508c4.695 0 8.508 3.812 8.508 8.508 0 2.078-.747 3.984-1.985 5.461l4.749 4.75c.146.146.219.338.219.531 0 .587-.537.75-.75.75-.192 0-.384-.073-.531-.22zm-5.461-13.53c-3.868 0-7.007 3.14-7.007 7.007s3.139 7.007 7.007 7.007c3.866 0 7.007-3.14 7.007-7.007s-3.141-7.007-7.007-7.007z"
      fillRule="nonzero"
    />
  </svg>
);

export const ThreeDashes = (props) => (
  <svg
    className={props.className}
    viewBox="0 0 24 24"
    height={props.height || "24"}
    width={props.width || "24"}
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
  >
    <path
      d="m22 16.75c0-.414-.336-.75-.75-.75h-18.5c-.414 0-.75.336-.75.75s.336.75.75.75h18.5c.414 0 .75-.336.75-.75zm0-5c0-.414-.336-.75-.75-.75h-18.5c-.414 0-.75.336-.75.75s.336.75.75.75h18.5c.414 0 .75-.336.75-.75zm0-5c0-.414-.336-.75-.75-.75h-18.5c-.414 0-.75.336-.75.75s.336.75.75.75h18.5c.414 0 .75-.336.75-.75z"
      fillRule="nonzero"
    />
  </svg>
);

export const ThreeByThreeDots = (props) => (
  <svg
    className={props.className}
    viewBox="0 0 24 24"
    height={props.height || "24"}
    width={props.width || "24"}
  >
    <path d="M12 16C13.1 16 14 16.9 14 18S13.1 20 12 20 10 19.1 10 18 10.9 16 12 16M12 10C13.1 10 14 10.9 14 12S13.1 14 12 14 10 13.1 10 12 10.9 10 12 10M12 4C13.1 4 14 4.9 14 6S13.1 8 12 8 10 7.1 10 6 10.9 4 12 4M6 16C7.1 16 8 16.9 8 18S7.1 20 6 20 4 19.1 4 18 4.9 16 6 16M6 10C7.1 10 8 10.9 8 12S7.1 14 6 14 4 13.1 4 12 4.9 10 6 10M6 4C7.1 4 8 4.9 8 6S7.1 8 6 8 4 7.1 4 6 4.9 4 6 4M18 16C19.1 16 20 16.9 20 18S19.1 20 18 20 16 19.1 16 18 16.9 16 18 16M18 10C19.1 10 20 10.9 20 12S19.1 14 18 14 16 13.1 16 12 16.9 10 18 10M18 4C19.1 4 20 4.9 20 6S19.1 8 18 8 16 7.1 16 6 16.9 4 18 4Z" />
  </svg>
);

export const Filter = (props) => (
  <svg
    className={props.className}
    viewBox="0 0 24 24"
    height={props.height || "24"}
    width={props.width || "24"}
  >
    <path d="M21 8H3V6H21V8M13.81 16H10V18H13.09C13.21 17.28 13.46 16.61 13.81 16M18 11H6V13H18V11M23 18H15V20H23V18Z" />
  </svg>
);

export const FourSquare = (props) => (
  <svg
    className={props.className}
    viewBox="0 0 24 24"
    height={props.height || "24"}
    width={props.width || "24"}
    clipRule="evenodd"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
  >
    <path
      d="m3 12.7v7.3c0 .62.519 1 1 1h7.3v-8.3zm18 0h-8.3v8.3h7.3c.621 0 1-.52 1-1zm-9.7-9.7h-7.3c-.478 0-1 .379-1 1v7.3h8.3zm1.4 0v8.3h8.3v-7.3c0-.478-.379-1-1-1z"
      fillRule="nonzero"
    />
  </svg>
);

export const Edit = (props) => (
  <svg
    className={props.className}
    viewBox="0 0 24 24"
    height={props.height || "24"}
    width={props.width || "24"}
    fill="none"
  >
    <path
      d="M16.4745 5.40801L18.5917 7.52524M17.8358 3.54289L12.1086 9.27005C11.8131 9.56562 11.6116 9.94206 11.5296 10.3519L11 13L13.6481 12.4704C14.0579 12.3884 14.4344 12.1869 14.7299 11.8914L20.4571 6.16423C21.181 5.44037 21.181 4.26676 20.4571 3.5429C19.7332 2.81904 18.5596 2.81903 17.8358 3.54289Z"
      stroke={props.stroke || "black"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 15V18C19 19.1046 18.1046 20 17 20H6C4.89543 20 4 19.1046 4 18V7C4 5.89543 4.89543 5 6 5H9"
      stroke={props.stroke || "black"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Email = (props) => (
  <svg
    className={props.className}
    height={props.height || "20"}
    width={props.width || "25.456"}
    viewBox="0 0 25.456 20"
  >
    <path
      id="Path_2970"
      data-name="Path 2970"
      d="M4.727-35a2.7,2.7,0,0,0-1.65.575L13.7-24.776a1.437,1.437,0,0,0,2.047,0l10.586-9.68A2.693,2.693,0,0,0,24.727-35Zm22.637,2.069q-5.2,4.759-10.408,9.519a3.37,3.37,0,0,1-4.457,0Q7.289-28.146,2.075-32.88A2.676,2.676,0,0,0,2-32.273v14.545A2.742,2.742,0,0,0,4.727-15h20a2.742,2.742,0,0,0,2.727-2.727V-32.273A2.232,2.232,0,0,0,27.364-32.931Z"
      transform="translate(-2 35)"
    />
  </svg>
);

export const PasswordKey = (props) => (
  <svg
    className={props.className}
    height={props.height || "12.729"}
    width={props.width || "25.457"}
    viewBox="0 0 25.457 12.729"
  >
    <path
      id="Path_2982"
      data-name="Path 2982"
      d="M32.275,13.182H21.87a6.365,6.365,0,1,0,0,6.364h4.041L27.5,17.955l1.591,1.591,1.591-1.591,1.591,1.591,3.182-3.182ZM13.182,17.955a1.591,1.591,0,1,1,1.591-1.591A1.591,1.591,0,0,1,13.182,17.955Z"
      transform="translate(-10 -10)"
    />
  </svg>
);

export const PasswordEye = (props) => (
  <svg
    className={props.className}
    height={props.height || "14.352"}
    width={props.width || "28.016"}
    viewBox="0 0 28.016 14.352"
  >
    <g
      id="Group_10519"
      data-name="Group 10519"
      transform="translate(-3.502 -13.5)"
    >
      <path
        id="Path_3017"
        data-name="Path 3017"
        d="M31.432,20.45c-.253-.287-6.308-6.95-13.921-6.95S3.842,20.163,3.59,20.45a.344.344,0,0,0,0,.458c.253.28,6.308,6.943,13.921,6.943s13.668-6.663,13.921-6.95A.335.335,0,0,0,31.432,20.45ZM17.511,27.168c-6.527,0-12.055-5.317-13.2-6.492,1.141-1.175,6.663-6.492,13.2-6.492s12.055,5.317,13.2,6.492C29.566,21.851,24.044,27.168,17.511,27.168Z"
        fill="#2d2d2d"
      />
      <path
        id="Path_3018"
        data-name="Path 3018"
        d="M21.942,17.5a4.442,4.442,0,1,0,4.442,4.442A4.451,4.451,0,0,0,21.942,17.5Zm0,8.2A3.759,3.759,0,1,1,25.7,21.942,3.765,3.765,0,0,1,21.942,25.7Z"
        transform="translate(-4.432 -1.266)"
        fill="#2d2d2d"
      />
    </g>
  </svg>
);

export const PasswordEyeLine = (props) => (
  <svg
    className={props.className}
    width={props.width || "20.143"}
    height={props.height || "20.143"}
    viewBox="0 0 20.143 20.143"
  >
    <path
      id="Path_3019"
      data-name="Path 3019"
      d="M391.488,43.564l-.707-.707,19.436-19.436.707.707Z"
      transform="translate(-390.781 -23.421)"
      fill="#2d2d2d"
    />
  </svg>
);

export const OTPClock = (props) => (
  <svg
    className={props.className}
    width={props.width || "11.747"}
    height={props.height || "11.747"}
    viewBox="0 0 11.747 11.747"
  >
    <path
      id="Path_2977"
      data-name="Path 2977"
      d="M69.873,45a5.873,5.873,0,1,0,5.873,5.873A5.873,5.873,0,0,0,69.873,45ZM73.4,52.106H68.641V47.349h1.624v3.132H73.4Z"
      transform="translate(-64 -45)"
    />
  </svg>
);
