import React from "react";
//import { Location } from "../../../../assets/icons/icons";

const CarDetails = (props) => {

  console.log('-----props', props)

  let isCarDetailAvailable = false;

  if(props.ordersData.orders.length > 0) {
    isCarDetailAvailable = true;
  }

  return (
    <div className="w-2/5 mr-3">
      <div className="text-xl">Order details</div>
      <div className="text-slate-400">{props.ordersData.orders[0].category === 'Fix' ? 'Finance' : 'Cash Purchase'} Order</div>
      {isCarDetailAvailable === true && (
        <><img
          className="h-52 w-full object-cover rounded-lg my-3"
          src={props.ordersData.orders[0].carId.Main_Photo}
          alt={props.ordersData.orders[0].carId.Make} /><div className="flex justify-between">
            <div>{props.ordersData.orders[0].carId.Year} {props.ordersData.orders[0].carId.Make} {props.ordersData.orders[0].carId.Model}</div>
            <div>${props.ordersData.orders[0].carId.Price}</div>
          </div><div className="flex justify-between">
            <div className="text-slate-400">Year</div>
            <div>{props.ordersData.orders[0].carId.Year}</div>
          </div><div className="flex justify-between my-2">
            <div className="text-slate-400">Milage</div>
            <div>{props.ordersData.orders[0].carId.Current_Miles}</div>
          </div><div className="flex justify-between">
            <div className="text-slate-400">Transmission</div>
            <div>{props.ordersData.orders[0].carId.Transmission_Description}</div>
          </div></>
      )}
      
    </div>
   
  );
};

export default CarDetails;
