import * as Yup from "yup";
import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import { ErrorMessage, Formik } from "formik";

import api from "../../../common/api/api";
import FieldErrorMessage from "../../../common/error";

import heroImage from "../../../../assets/images/center.png";
import Mixpanel from "../../setting/container/mixpanel";

const UoboIntro = () => {
  const [email, setEmail] = useState(false);

  useEffect(() => {
    Mixpanel.track('Dashboard view');
    const dealer = JSON.parse(localStorage.getItem("dealerSignin"));
    if (dealer !== undefined && dealer !== null && dealer !== '') {
      Mixpanel.people.set({
        $first_name: dealer?.firstName,
        $last_name: dealer?.lastName,
      });
    } else {
      Mixpanel.people.set({
        $first_name: 'Guest',
      });
    }
    
  },[])

  const homePageEmailUpdates = Yup.object({
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email address")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "Invalid email address format"),
  });

  const handlePostRequest = async (email) => {
    try {
      await api.post("/api/user/email", email, {
        headers: {
          Authorization: `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiNjU1ZmNjMWEwNWZhYmM2NWI0MTViNGVhIiwiaWF0IjoxNzA0MzA5OTQxLCJleHAiOjE3MDY5MDE5NDF9.nJMST_PrZN0I38xIfqQIarw-Frx6gWykjHJcmXbTfb8`,
        },
      });
      toast.success("Success", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setEmail(!email);
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong !", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setEmail(!email);
    }
  };

  return (
    <div>
      <div className="uoboIntro m-auto lg:w-4/5">
        {/* MAIN INFO */}
        <p className="font-semibold-700 mb-4 mt-24 text-2xl text-slate-700 xs:text-center  md:text-5xl  lg:w-6/12 lg:text-left lg:leading-10">
          Redefining Pre Owned Car Buying
          <span className="font-semibold-700 ml-2 text-blue-500 lg:text-5xl">
            Online
          </span>
        </p>

        {/* INTRO */}

        <p className="mb-0 text-sm font-semibold text-gray-500 xs:mx-auto xs:w-4/5 xs:text-center md:mt-10 md:w-6/12 md:text-base lg:m-0 lg:w-4/12 lg:text-left">
          Simplifying The Experience For Dealerships And Buyers Alike With
          Streamlined Online Processes And Enhanced Satisfaction.
        </p>

        {/* SUBMIT BUTTON */}
        <div className="lg:jus mt-4 flex justify-center lg:justify-start">
          {!email && (
            <div
              className="cursor-pointer rounded-full bg-blue-500  text-center text-sm text-white xs:px-4 xs:py-3 sm:text-base lg:px-7 lg:py-3"
              onClick={() => setEmail(true)}
            >
              Stay Updated
            </div>
          )}
          {email && (
            <Formik
              initialValues={{ email: "" }}
              onSubmit={(values) => {
                const params = {
                  email: values.email,
                };
                console.log("Form values>>", params);
                handlePostRequest(params);
              }}
              validateOnChange={true}
              validateOnBlur={true}
              validationSchema={homePageEmailUpdates}
            >
              {({ handleSubmit, setFieldValue, errors }) => (
                <form onSubmit={handleSubmit} className="xs:block sm:flex">
                  <div>
                    <input
                      type="text"
                      name="email"
                      id="email"
                      onChange={(e) =>
                        setFieldValue(e.target.name, e.target.value)
                      }
                      className="w-64 rounded-md border-2 border-blue-500 p-2"
                      placeholder="Your Email"
                    />
                    <ErrorMessage name="email" component={FieldErrorMessage} />
                  </div>
                  <button
                    type="submit"
                    className="ml-4 h-11 w-28 rounded-md bg-blue-500 py-2 text-white xs:mt-2 sm:mt-0"
                  >
                    Submit
                  </button>
                </form>
              )}
            </Formik>
          )}
          {/* <Link
       to="/dealer-customization"
       className="bg-blue-500 px-4 py-2 sm:px-5 sm:py-3 rounded-full text-white text-center text-sm sm:text-base no-underline"
     >
       Stay Updated
     </Link> */}
        </div>
      </div>
      <div className="absolute top-96 z-0 lg:-top-10 lg:left-96">
        <img src={heroImage} alt="Home Page Intro Image" />
      </div>
    </div>
  );
};

export default UoboIntro;
