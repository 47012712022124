import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const EditServiceModal = ({ show, handleClose, service, handleChange, handleSave }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{service && service.id ? 'Edit Service' : 'Add New Service'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="serviceName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={service ? service.name : ''}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="serviceDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              name="description"
              value={service ? service.description : ''}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="servicePrice">
            <Form.Label>Price</Form.Label>
            <Form.Control
              type="number"
              name="price"
              value={service ? service.price : ''}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="servicePricingType">
            <Form.Label>Pricing Type</Form.Label>
            <Form.Select
              name="pricingType"
              value={service ? service.pricingType : ''}
              onChange={handleChange}
              required
            >
              <option value="">Select Pricing Type</option>
              <option value="one-time">One-time</option>
              <option value="monthly">Monthly</option>
              <option value="weekly">Weekly</option>
              <option value="yearly">Yearly</option>
            </Form.Select>
          </Form.Group>
          {/* <Form.Group controlId="serviceIcon">
            <Form.Label>Icon URL</Form.Label>
            <Form.Control
              type="text"
              name="icon"
              value={service ? service.icon : ''}
              onChange={handleChange}
              required
            />
          </Form.Group> */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          {service && service.id ? 'Update and Add to Bucket' : 'Add to Bucket'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditServiceModal;
