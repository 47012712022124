import React from "react";

const CommonQuestions = () => {
  return (
    <div className="lg:uoboIntro mt-4 flex flex-col md:mb-72 md:mt-10 md:items-center lg:mb-64">
      {/* SET 1 */}
      <div className="font-semibold-700 mb-8 ml-9 w-full text-xl sm:text-3xl md:mb-12 md:ml-0 md:w-9/12 md:text-2xl lg:w-7/12">
        Frequently Asked Questions
      </div>
      <div className="ml-9 flex flex-wrap text-left md:w-4/5 lg:mx-auto lg:w-7/12 lg:flex-nowrap">
        <div className="lg:w-1/2">
          <div className="font-semibold text-black">
            How does Uobo's platform work?
          </div>
          <div className="mb-0 w-80 text-base text-gray-700 md:mt-3 md:w-full  lg:w-96">
            Uobo is an online pre-owned vehicle marketplace that allows
            customers to purchase their next vehicle from local dealerships
            completely online. We do that by{" "}
            <span className="font-semibold text-blue-600">partnering</span> with
            dealers to help them reach an online market without significant time
            or money invested into it. Reach out to us for more info!
          </div>
        </div>
        <div className="mt-7 lg:mt-0 lg:w-1/2">
          <div className="font-semibold text-black">
            How can dealerships join the platform?
          </div>
          <div className="mt-3 w-80 text-base text-gray-700 md:w-full lg:w-96">
            Dealerships can join Uobo via the 'Join Uobo' button located on our
            homepage. Simply fill in your dealership's information and you will
            be set!
          </div>
        </div>
      </div>
      {/* SET 2 */}
      <div className="ml-9 flex flex-wrap text-left md:mt-7 md:w-4/5 lg:mx-auto lg:w-7/12 lg:flex-nowrap">
        <div className="lg:w-1/2">
          <div className="font-semibold text-black">
            When will the platform launch?
          </div>
          <div className="mb-0 w-80 text-base text-gray-700 md:mt-3 md:w-full lg:mt-3 lg:w-96">
            Uobo is working diligently to fine-tune the platform for the perfect
            user experience. Sign up for notifications to stay updated on our
            launch date.
          </div>
        </div>
        <div className="mt-7 lg:mt-0 lg:w-1/2">
          <div className="font-semibold text-black">
            Is Uobo exclusive to Ontario, Canada?
          </div>
          <div className="mt-3 w-80 text-base text-gray-700 md:w-full lg:w-96">
            Currently, Uobo focuses on partnering with dealerships in Ontario to
            transform the local car buying experience. Expansion plans will be
            announced post-launch.
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonQuestions;
