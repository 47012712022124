import React from 'react';

const PaveWebView = ({ url }) => {
  return (
    <div style={{ width: '100%', height: '80vh', margin: '20px 0' }}>
      <iframe 
        src={url}
        style={{ width: '100%', height: '100%', border: 'none' }}
      >
      </iframe>
    </div>
  );
}

export default PaveWebView;