import React, { useEffect, useState } from "react";
import DealerHome from "../component/dealerHome";
import { getDealerStripeDetails, createDealerStripeAccount } from "../../../../api/orders/helper";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [dealerName, setDealerName] = useState("");
  const [stripeDetails, setStripeDetails] = useState({});
  const [stripeOnBoardingSetup, setStripeOnBoardingSetup] = useState(false);
  const [stripeOnBoardingLink, setStripeOnBoardingLink] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const dealerSignIn = localStorage.getItem("dealerSignin");
    if (dealerSignIn) {
      const name = JSON.parse(dealerSignIn).firstName;
      setDealerName(name);
    }

    const token = JSON.parse(localStorage.getItem("token"));
    getDealerStripeDetails(token)
      .then((dealerStripeDetails) => {
        if (dealerStripeDetails.isOnboardingComplete) {
          setStripeOnBoardingSetup(true);
          setStripeDetails(dealerStripeDetails);
          setStripeOnBoardingLink(dealerStripeDetails?.account?.onBoardingLink);
        } else {
          setStripeDetails(dealerStripeDetails);
          setStripeOnBoardingLink(dealerStripeDetails?.account?.onBoardingLink);
        }
      })
      .catch((error) => {
        console.error("Error fetching Stripe details:", error);
      });

    // let token = requestNotificationPermission();

    // console.log('-----------------------token', token);
  }, [dealerName]);

  const createDealerNewStripeAccont = () => {
    try {
      const dealerId = JSON.parse(localStorage.getItem("dealerSignin"));
      // console.log(token._id)
      createDealerStripeAccount(dealerId._id)
      .then((dealerStripeDetails) => {

        console.log('creat ======',dealerStripeDetails)
        setStripeDetails(dealerStripeDetails[1]);
        setStripeOnBoardingLink(dealerStripeDetails[1]?.onBoardingLink);
        window.location.href = dealerStripeDetails[1]?.onBoardingLink;
      })
      .catch((error) => {
        console.error("Error fetching Stripe details:", error);
      });
    } catch (error) {
      console.error("Error creating Stripe account:", error);
    }
  }

  const handleOnBoarding = () => {
    console.log(stripeOnBoardingLink)
    if (stripeOnBoardingLink !== undefined && stripeOnBoardingLink !== null && stripeOnBoardingLink !== '') {
      console.log('here');
      window.location.href = stripeOnBoardingLink;
    } else if (stripeDetails?.newUser) {
      console.log('in')
      createDealerNewStripeAccont();
    } else {
      console.error("Onboarding link is missing");
    }
  }

  return (
    <div className={`w-4/5`}>
      {/* User Greeting */}
      {dealerName && (
        <div className="mt-4 text-start">
          <div className="text-2xl font-medium">Hey {dealerName},</div>
          <div className="text-sm text-slate-500">
            Here's what happening in your dashboard.
          </div>
        </div>
      )}

      {!stripeOnBoardingSetup && (
            <button type="button" 
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              onClick={handleOnBoarding}
            >
              Please Setup Stripe Onboarding
            </button>
          )}
      {/* Main Content */}
      <DealerHome />
    </div>
  );
};

export default Dashboard;
