import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import InventoryList from "../component/inventoryList";
import InventoryTable from "../component/inventoryTable";
import GMC_Yukon from "../../../../assets/images/GMC_Yukon.jpeg";
import InventorySortSearch from "../component/inventorySortSearch";
import InventoryFilterModal from "../component/inventoryFilterModal";
import { ThreeDashes, ThreeByThreeDots } from "../../../../assets/icons/icons";

const Inventory = () => {
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [sort, setSort] = useState("0");
  const [year, setYear] = useState([1998, 2024]);
  const [range, setRange] = useState([0, 1000000]);
  const [isChecked, setIsChecked] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [inventoryData, setInventoryData] = useState([]); // Initialize with an empty array

  const handleIconClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const toggleSwitch = () => {
    setIsChecked(!isChecked);
  };

  const handleModalClose = () => {
    setFilterModal(!filterModal);
  };

  const handleChange = (event, newValue) => {
    setRange(newValue);
  };

  const handleYearChange = (event, newValue) => {
    setYear(newValue);
  };

  const handleCarClick = (id) => {
    setTimeout(() => {
      navigate(`/inventory/${id}`);
    }, 100);
  };

  // Function to update inventory data after successful upload
  const handleInventoryUpdate = (updatedData) => {
    setInventoryData(updatedData || []); // Ensure it's an array
  };

  return (
    <div className="w-4/5 mt-3">
      <div className="text-2xl text-slate-400 flex">
        Inventory
        {/* Toggle Switch */}
        <label className="flex items-center cursor-pointer ml-4">
          <div className="relative flex items-center">
            <input
              type="checkbox"
              id="toggleSwitch"
              className="hidden"
              checked={isChecked}
              onChange={toggleSwitch}
            />
            <div className="toggle__line w-28 h-6 bg-gray-200 rounded-full shadow-inner"></div>
            <div
              className={` h-full flex items-center justify-center rounded-full w-14 absolute left-0 ${
                isChecked ? "bg-transparent" : "bg-blue-500"
              }`}
            >
              <ThreeDashes
                className={`${!isChecked ? "fill-white" : "fill-slate-400"}`}
              />
            </div>
            <div
              className={`bg-blue-500 h-full flex items-center justify-center rounded-full w-14 absolute right-0 ${
                isChecked ? "bg-blue-500" : "bg-transparent"
              }`}
            >
              <ThreeByThreeDots
                className={`${isChecked ? "fill-white" : "fill-slate-400"}`}
              />
            </div>
          </div>
        </label>
      </div>

      {/* Control Bar */}
      <InventorySortSearch
        sort={sort}
        setSort={setSort}
        inputRef={inputRef}
        setFilterModal={setFilterModal}
        handleIconClick={handleIconClick}
        onInventoryUpdate={handleInventoryUpdate} // Pass the callback function
      />

      {/* Cars Data */}
      {isChecked ? (
        <InventoryList
          data={inventoryData.length ? inventoryData : []}
          handleCarClick={handleCarClick}
          sort={sort}
        />
      ) : (
        <InventoryTable
          data={inventoryData.length ? inventoryData : []}
          handleCarClick={handleCarClick}
          sort={sort}
        />
      )}

      {/* Filter Modal */}
      <InventoryFilterModal
        range={range}
        year={year}
        filterModal={filterModal}
        handleChange={handleChange}
        handleYearChange={handleYearChange}
        handleModalClose={handleModalClose}
      />
    </div>
  );
};

export default Inventory;
