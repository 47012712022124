import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';  

const firebaseConfig = {
  apiKey: "AIzaSyBt8vCt3IVcsgrQof-tN5N213CY7tpi4zI",
  authDomain: "uobo-2e6e2.firebaseapp.com",
  projectId: "uobo-2e6e2",
  storageBucket: "uobo-2e6e2.appspot.com",
  messagingSenderId: "273505842663",
  appId: "1:273505842663:web:d9c1245b4f7dfa8af7f942",
  measurementId: "G-H1D5LHZ12X"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging
const messaging = getMessaging(app);

const setupNotifications = async () => {
  try {
    // Request permission for notifications
    const permission = await Notification.requestPermission();
    
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      // Get the FCM token
      const token = await getToken(messaging);
      console.log('FCM Token:', token);
    } else {
      console.log('Notification permission denied.');
    }
    // Handle foreground notifications
    onMessage(messaging, (payload) => {
      console.log('Foreground Message:', payload);
      // Handle the notification or update your UI
    });
  } catch (error) {
    console.error('Error setting up notifications:', error);
  }
};

export { app, messaging, getToken, onMessage, setupNotifications };