/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Dropdown from "react-bootstrap/Dropdown";
import BillOfSale from "../../../common/modal/billOfSale";
import ProgressBar from "react-bootstrap/ProgressBar";
import OpenFinanceOrder from "../../../common/modal/openFinanceOrder";
import EMIAppointmentDetails from "./emiAppointmentDetails";
// import ScheduleList from "../../liveOrder/component/scheduleList";
import { getOrders } from "../../../../api/orders/helper";
import { firestore, storage } from "../../../../deliveryFirebaseConfig";
import { collection, addDoc } from "firebase/firestore";
import ConfirmationModal from "../../liveOrder/component/confirmAvailablePopUp";
import CustomerTradeInModal from "./customerTradeInModal";

import { DownArrow, Search } from "../../../../assets/icons/icons";
import "../../../../assets/scss/custom.scss";
import PDFIconImage from "../../../../assets/images/pdf_icon.png"
import { DealerOrderContext } from "../../../../context/dealerOrderContext";

const CurrentStatus = (props) => {

  const navigate = useNavigate();

  const [isSearch, setIsSearch] = useState(false);

  const [billOfSale, setBillOfSale] = useState(false);

  const [financeOrder, setFinanceOrder] = useState([]);

  let [cashOrder, setCashOrder] = useState([]);

  let { orderWithStatuses } = useContext(DealerOrderContext);

  const [financeOrderStep, setfinanceOrderStep] = useState(false);

  const [selectedImage, setSelectedImage] = useState("");

  const [showEMIDetailsModal, setShowEMIDetailsModal] = useState(false);
  const [selectedEMIDetails, setSelectedEMIDetails] = useState(null);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const [showTradeInModal, setShowTradeInModal] = useState(false);
  const [tradeInDecision, setTradeInDecision] = useState(false);

  // const [status, setStatus] = useState("Awaiting For Approval");

  // const [success, setSuccess] = useState(false);

  const loadOrders = () => {
    const token = JSON.parse(localStorage.getItem("token"));
    getOrders(token).then((orders) => {
      // console.log('---ALL ORDERS---', orders);
      // setOrderWithStatuses(orders);
      orderWithStatuses = orders
    });
  };

  // useEffect(() => {
  //   // loadOrders();
  //   // setInterval(loadOrders, 1000 * 360);
  //   //clearInterval(intervalId);

  //   const data = JSON.parse(localStorage.getItem("dealerSignin"));
  //   const dealerId = data?._id;
  //   // Pass dealerId as a query parameter
  //   const eventSource = new EventSource(`https://api.uobo.ca/api/user/order-event-stream?dealerId=${dealerId}`);

  //   eventSource.onmessage = (event) => {
  //     const message = JSON.parse(event.data);

  //     let ordersList = message?.data;
      
  //     setOrderWithStatuses(ordersList);
  //   };

  //   return () => {
  //     eventSource.close();
  //   };
    
  // }, []);

  let customerName = '';

  const handleOpenBillOfSale = (order) => {

    let orderData = order
    navigate("/sign", {
      state: {
        order: orderData
      }
    })
    // navigate("/additional-car-services", {
    //   state: {
    //     order: orderData
    //   }
    // })

    // additional-car-services
    console.log('---Customer name---', order);
    customerName = order.firstName + ' ' + order.lastName;
    setCashOrder(order);
    setBillOfSale(true);
  }

  const handleCloseBillOfSale = () => {
    loadOrders();
    setBillOfSale(false);
    //setStatus("Awaiting For Bill Of Sale");
  }

  const handleCancelBillOfSale = () => {
    setBillOfSale(false);
  }

  const closeFinanceOrder = () => {
    setfinanceOrderStep(false);
  }

  const confirmAppointments = (orderDetail) => {
    let order = [orderDetail];
    let nextStep = 1;
    navigate("/live-order", {
      state: {
        nextStep,
        order
      }
    });
  };

  const handleImageClick = (image, category) => {

    if (category === 'BillOfSale') {
      window.open(image, "_blank");
    } else {
      setSelectedImage(image);
    }
  };

  const handleCloseImagePopup = () => {
    setSelectedImage("");
  };

  const handleViewEMIDetails = (order) => {
    setSelectedEMIDetails(order);
    setShowEMIDetailsModal(true);
  };

  const handleCloseEMIDetailsModal = () => {
    setShowEMIDetailsModal(false);
    setSelectedEMIDetails(null);
  };

  const handleViewTradeIn = (order) => {
    setTradeInDecision(order);
    setShowTradeInModal(true);
  };

  const handleCloseTradeInModal = () => {
    setShowTradeInModal(false);
    setTradeInDecision(null);
  };

  let query = {
    handleOpenBillOfSale: billOfSale,
    order: cashOrder,
    handleCloseBillOfSale: handleCloseBillOfSale,
    handleCancelBillOfSale: handleCancelBillOfSale
  };

  let financeOrderQuery = {
    order: financeOrder,
    closeFinanceOrder: closeFinanceOrder,
    openFinanceOrder: financeOrderStep
  };

  const handleAssignDelivery = (order) => {
    setSelectedOrder(order);
    setConfirmOpen(true);
  };

  const handleConfirm = async () => {
    if (selectedOrder) {
      try {
        const docRef = await addDoc(collection(firestore, "orders"), {
          customerDetails: {
            email: selectedOrder?.customerId?.email,
            name: `${selectedOrder?.customerId?.firstName} ${selectedOrder?.customerId?.lastName}`,
            phone: `${selectedOrder?.customerId?.contact?.countryCode}${selectedOrder?.customerId?.contact?.number}`,
          },
          carDetails: {
            make: selectedOrder?.carId?.Make,
            model: selectedOrder?.carId?.Model,
            vin: selectedOrder?.carId?.VIN,
            year: selectedOrder?.carId?.Year,
          },
          checklistDropOff: {
            carChecked: false,
            loadedInTruck: false,
            papersPicked: false,
          },
          dealershipDetails: {
            contact: selectedOrder?.dealerId?.email || "",
            name: selectedOrder?.dealerId?.name || "",
          },
          orderId: selectedOrder?._id,
          status: "Assigned",
          timestamp: new Date(),
        });

        console.log("Document written with ID: ", docRef.id);
      } catch (e) {
        console.error("Error adding document: ", e.message);
      }
    }
    setConfirmOpen(false);
    setSelectedOrder(null);
  };
  

  // console.log('--ORDERS--', orderWithStatuses);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className="flex items-center border rounded-xl px-2"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <DownArrow width="15" height="15" className="ml-2 fill-blue-500" />
    </div>
  ));

  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <Form.Control
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  return (
    <>
      <div className="flex xs:flex-wrap md:flex-nowrap justify-between items-center w-full">
        {/* Left Side */}
        <div className="flex">
          <div>Current Status</div>
          <div className="ml-2">
            <Dropdown>
              <Dropdown.Toggle
                as={CustomToggle}
                id="dropdown-custom-components"
              >
                Sort by
              </Dropdown.Toggle>

              <Dropdown.Menu as={CustomMenu}>
                <Dropdown.Item eventKey="1">A-Z</Dropdown.Item>
                <Dropdown.Item eventKey="2">Z-A</Dropdown.Item>
                <Dropdown.Item eventKey="3" /* active */>Price</Dropdown.Item>
                <Dropdown.Item eventKey="4">Approved</Dropdown.Item>
                <Dropdown.Item eventKey="4">Waiting for approval</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        {/* Right Side */}
        <div
          className="bg-slate-200 p-2 rounded-xl flex items-center xs:mt-3 sm:mt-0"
          onClick={() => setIsSearch(true)}
        >
          <Search className="fill-blue-500" />
          {isSearch && (
            <Form.Control
              type="text"
              placeholder=""
              className="ml-3"
              autoFocus
              size="sm"
            />
          )}
        </div>
      </div>

      {/* Table */}
      <Table responsive="sm" className="table">
        <thead>
          <tr className="text-start ">
            <th>Vehicle Details</th>
            <th> Customer Name</th>
            <th>Documents</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
        {orderWithStatuses?.length > 0 ? (
          orderWithStatuses?.map((order, index) => (
            order.status !== '' ?
            <tr key={index}>
            <td className="text-start align-middle">
              <div className="flex w-64">
                <img
                  className="h-16 rounded-lg mr-3"
                  src={order?.carId?.Main_Photo}
                  alt="GMC_Yukon"
                />

                <div className="flex flex-col justify-between">
                  <div>{order?.carId?.Year} {order?.carId?.Make} {order?.carId?.Model}</div>
                  <div>${order?.carId?.Price}</div>
                  <ProgressBar
                    now={60}
                    label={`${60}%`}
                    visuallyHidden
                    className="progressBar"
                  />
                </div>
              </div>
            </td>
            <td className="text-start align-middle w-64">
              <div>{order?.customerId?.firstName} {order?.customerId?.lastName}</div>
              {order.status === 'AdditionalDocumentAskedFromDealer' && (
                <div className="text-blue-500 text-sm">Waiting for Customer to Send Documents</div>
              )}
              {order.status === 'DealerSentAvailability' && (
                <div className="text-blue-500 text-sm">Awaiting for Payment Deposit</div>
              )}
              {order.status === 'DepositPaidByCustomer' && (
                <div className="text-blue-500 text-sm">Customer Paid Deposit ${ order?.customerDepositAmount }</div>
              )}
              {order.status === 'DealerSentEMIOptions' && (
                <div className="text-blue-500 text-sm">Dealer Sent EMI Options</div>
              )}
              {order.status === 'CustomerSelectEMIOptionAndChooseTime' && (
                <div className="text-blue-500 text-sm">Customer Selected EMI Options and Appointment</div>
              )}
              {order.status === 'CarNotAvailable' && (
                <div className="text-blue-500 text-sm">Car Not Available</div>
              )}
              {order.status === 'CustomerSentAdditionalDocuments' && (
                <div className="text-blue-500 text-sm">Customer Sent Additional Documents</div>
              )}
              {(order.status === 'CustomerPaidFullInCashAndChooseTime' || order.status === 'CustomerBookedAppointment') && (
                <div className="text-blue-500 text-sm">Customer Booked Appointment</div>
              )}
              {order.status === 'DealerSentBillOfSale' && (
                <div className="text-blue-500 text-sm">Waiting for Customer to Sign Bill of Sale</div>
              )}
              {order.status === 'CustomerSignedBillofSale' && (
                <div className="text-blue-500 text-sm">Customer Signed Bill Of Sale</div>
              )}
              {/* {order.status === 'AdditionalDocumentAskedFromDealer' && (
                <div className="text-blue-500 text-sm">Awating For Additional Documents</div>
              )} */}
              {/* {order.status === 'DealerSentAvailability' && (
                <div className="text-blue-500 text-sm">Awating For Cash Deposit</div>
              )}
              {order.status === 'DealerSentBillOfSale' && (
                <div className="text-blue-500 text-sm">Awating For Bill of Sale</div>
              )} */}
            </td>
            <td>
              <div className="bg-blue-50 flex items-center rounded-lg w-72 mt-2 overflow-auto">
                {order?.documents.map((document, docIndex) => (
                  <img
                  className="h-10 rounded-lg m-2"
                  key={docIndex}
                  src={ document?.category === 'BillOfSale' ? PDFIconImage : document?.file}
                  alt={document?.category}
                  onClick={() => handleImageClick(document?.file, document?.category)}
                />
                ))}

                {/* <div className="pr-2 text-blue-500">+2more</div> */}
              </div>
            </td>
            <td>
            {(order.status === 'CustomerPaidFullInCashAndChooseTime' || order.status === 'CustomerBookedAppointment') && (
              <div className="flex flex-row items-center mt-3 xs:w-40 xl:w-auto">
                <div className="bg-blue-500 text-white rounded p-1 w-70 text-center text-sm mr-2" onClick={() => handleViewTradeIn(order)}>
                  View
                </div>

                <div className="bg-blue-500 text-white rounded p-1 w-70 text-center text-sm" onClick={() => handleOpenBillOfSale(order)}>
                  Continue
                </div>
                {/* <div className="text-blue-500 mt-1 text-sm">
                  Approve with condition
                </div> */}
              </div>
            )}
            {order.status === 'CustomerSelectEMIOptionAndChooseTime' && (
              <div className="flex flex-row items-center mt-3 xs:w-40 xl:w-auto">
                <div className="bg-blue-500 text-white rounded p-1 w-70 text-center text-sm mr-2" onClick={() => handleViewEMIDetails(order)}>
                View
                </div>
                <div className="bg-blue-500 text-white rounded p-1 w-70 text-center text-sm" onClick={() => handleOpenBillOfSale(order)}>
                Continue
                </div>
                {/* <div className="text-blue-500 mt-1 text-sm">
                  Approve with condition
                </div> */}
              </div>
            )}
            {order.status === 'DepositPaidByCustomer' && (
              <div className="flex flex-col items-center mt-3 xs:w-40 xl:w-auto">
                <div className="bg-blue-500 text-white rounded p-1 w-70 text-center text-sm" onClick={() => confirmAppointments(order)}>
                Continue
                </div>
                {/* <div className="text-blue-500 mt-1 text-sm">
                  Approve with condition
                </div> */}
              </div>
            )}
            {order.status === 'DealerSentAvailability' && (
              <div className="flex flex-col items-center mt-3 xs:w-40 xl:w-auto">
                <div className="bg-gray-400 text-white rounded p-1 w-70 text-center text-sm"> 
                Continue
                </div>
                {/* <div className="text-blue-500 mt-1 text-sm">
                  Approve with condition
                </div> */}
              </div>
            )}
            {order.status === 'DealerSentEMIOptions' && (
              <div className="flex flex-col items-center mt-3 xs:w-40 xl:w-auto">
                <div className="bg-gray-400 text-white rounded p-1 w-70 text-center text-sm">
                  Continue
                </div>
                {/* <div className="text-blue-500 mt-1 text-sm">
                  Approve with condition
                </div> */}
              </div>
            )}
            {order.status === 'AdditionalDocumentAskedFromDealer' && (
              <div className="flex flex-col items-center mt-3 xs:w-40 xl:w-auto">
                <div className="bg-gray-400 text-white rounded p-1 w-70 text-center text-sm">
                  Continue
                </div>
                {/* <div className="text-blue-500 mt-1 text-sm">
                  Approve with condition
                </div> */}
              </div>
            )}

          {order.status === 'cancelled' && (
              <div className="flex flex-col items-center mt-3 xs:w-40 xl:w-auto">
                <div className="bg-gray-400 text-white rounded p-1 w-70 text-center text-sm">
                  Cancelled
                </div>
                {/* <div className="text-blue-500 mt-1 text-sm">
                  Approve with condition
                </div> */}
              </div>
          )}

          {order.status === 'DealerSentBillOfSale' && (
              <div className="flex flex-col items-center mt-3 xs:w-40 xl:w-auto">
                <div className="bg-gray-400 text-white rounded p-1 w-70 text-center text-sm">
                Continue
                </div>
                {/* <div className="text-blue-500 mt-1 text-sm">
                  Approve with condition
                </div> */}
              </div>
        )}

            {order.status === 'CustomerSentAdditionalDocuments' && (
              <div className="flex flex-col items-center mt-3 xs:w-40 xl:w-auto">
                <div className="bg-blue-500 text-white rounded p-1 w-70 text-center text-sm" onClick={() => confirmAppointments(order)}>
                  Continue
                </div>
                {/* <div className="text-blue-500 mt-1 text-sm">
                  Approve with condition
                </div> */}
              </div>
            )}

            {order.status === 'CustomerSignedBillofSale' && (
              <div className="flex flex-col items-center mt-3 xs:w-40 xl:w-auto">
                <div className="bg-blue-500 text-white rounded p-1 w-70 text-center text-sm" onClick={() => handleAssignDelivery(order)}>
                  Assign Delivery
                </div>
                {/* <div className="text-blue-500 mt-1 text-sm">
                  Approve with condition
                </div> */}
              </div>
            )}

            
            </td>
            {/* <td>
              <div className="flex flex-col items-center mt-3 xs:w-40 xl:w-auto">
                <div className="bg-blue-500 text-white rounded p-1 w-1/2 text-center text-sm" 
                onClick={() => getFinanceOrder(order)}>
                  Approve
                </div>
                <div className="text-blue-500 mt-1 text-sm">
                  Approve with condition
                </div>
              </div>
            </td> */}
          </tr>
          : null
          ))
        ) : (
          <tr>
            <td colSpan="4" className="text-center text-blue-500">
              Awaiting for the order
            </td>
          </tr>
        )}
        </tbody>
      </Table>

      <BillOfSale query={query} customerName={customerName} />
      {financeOrderStep === true && (
        <OpenFinanceOrder financeOrderQuery={financeOrderQuery} />
      )}

      {selectedImage && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
          <img
            src={selectedImage}
            alt="Full Screen"
            className="max-w-full max-h-full"
            onClick={handleCloseImagePopup}
          />
          <button
            className="absolute top-4 right-4 text-white text-xl bg-gray-800 p-2 rounded-full"
            onClick={handleCloseImagePopup}
          >
            &times;
          </button>
        </div>
      )}

      {selectedEMIDetails && (
        <EMIAppointmentDetails
          show={showEMIDetailsModal}
          handleClose={handleCloseEMIDetailsModal}
          emiDetails={selectedEMIDetails}
        />
      )}

      {tradeInDecision && (
        <CustomerTradeInModal
          show={showTradeInModal}
          handleClose={handleCloseTradeInModal}
          tradeInDecision={tradeInDecision}
        />
      )}

      <ConfirmationModal
        isOpen={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default CurrentStatus;
