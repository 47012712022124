import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Location } from "../../../../assets/icons/icons";
import { TodaysSchedule } from "../../../../assets/constants/const";
import { DealerOrderContext } from "../../../../context/dealerOrderContext";

const TodaySchedule = ({ data }) => {

  const { orderWithStatuses } = useContext(DealerOrderContext);

  // console.log(orderWithStatuses);

  const navigate = useNavigate();

  const handleCustomerClick = () => {

    navigate("/customer-information", {
      state: {
        order: orderWithStatuses
      }
    })
  };

  const renderReview = () => {
    return orderWithStatuses?.map((data) => (
      <div className="flex my-3" key={data?._id}>
        {/* left */}

        <div className="text-xs text-center w-1/5 text-blue-500 border-r-3 border-blue-500">
          <div>{data?.deliveryDate?.time ? data?.deliveryDate?.time : 'Time not selected yet'}</div>
          <div>{data?.deliveryDate?.date ? data?.deliveryDate?.date : 'Date not selected yet'}</div>
        </div>

        {/* right */}
        <div className="w-3/5 text-start ml-1">
          <div className="flex items-center space-x-2">
            <div>{data?.carId?.Make}</div>
            <div>{data?.carId?.Model}</div>
            <div>{data?.carId?.Year}</div>
          </div>
          
          <div
            className="cursor-pointer text-blue-600 hover:underline"
            onClick={() => handleCustomerClick(data)}
          >
            {data?.customerId?.firstName} {data?.customerId?.lastName}
          </div>
          
          <div className="flex text-xs items-center">
            <Location height="12" width="12" /> {data?.address[0]?.address ? data?.address[0]?.address : 'Not available'}
          </div>
        </div>
      </div>
    ));
  };
  return (
    <div className="border rounded-2xl p-1 h-80 overflow-y-auto mt-3">
      {renderReview()}
    </div>
  );
};

export default TodaySchedule;
