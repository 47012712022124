import React from "react";

import UnderConstructor from "../../../../assets/images/under-construction.png";

const Setting = () => {
  return (
    <div className="flex flex-col justify-center" style={{ height: "33rem" }}>
      <div>
        <img src={UnderConstructor} alt="" className="h-52 w-52" />
      </div>
      <h2 className="mt-4 text-left uppercase tracking-wider">Coming Soon</h2>
    </div>
  );
};

export default Setting;
