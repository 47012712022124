import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import "../../../../assets/scss/custom.scss";
import { DownArrow } from "../../../../assets/icons/icons";
import LicenceFr from "../../../../assets/images/licenceFr.png";
//import SendQueryModal from "../../../common/modal/sendQueryModal";

const PreApprovalOrder = (props) => {
  // const [open, setOpen] = useState(false);

  // const handleCloseModal = () => {
  //   setOpen(false);
  // };

  // const handleOpenModal = () => {
  //   setOpen(true);
  // };
  return (
    <>
      <div className="flex">
        {/* Customer Details */}
        <div className="w-2/5 mr-3 preApproval">
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<DownArrow className="fill-blue-500" />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="text-lg"
            >
              Personal Details
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">Name</div>
                <div className="text-right w-1/2">Mr. Doe John</div>
              </div>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">Date of Birth</div>
                <div className="text-right w-1/2">01/07/1993</div>
              </div>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">Gender</div>
                <div className="text-right w-1/2">Male</div>
              </div>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">Contact Number</div>
                <div className="text-right w-1/2">+172XXXXXX68</div>
              </div>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">Marital Status</div>
                <div className="text-right w-1/2">Common Law</div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<DownArrow className="fill-blue-500" />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="text-lg"
            >
              Current Address
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">Suite Number</div>
                <div className="text-right w-1/2">32</div>
              </div>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">Address</div>
                <div className="text-right w-1/2">
                  1410 Church Street West Norfolk County Toronto, Ontario
                </div>
              </div>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">Address Type</div>
                <div className="text-right w-1/2">Street</div>
              </div>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">Street Type</div>
                <div className="text-right w-1/2">Square</div>
              </div>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">Direction</div>
                <div className="text-right w-1/2">North-West</div>
              </div>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">Postal Code</div>
                <div className="text-right w-1/2">A0B 1C2</div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<DownArrow className="fill-blue-500" />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="text-lg"
            >
              Vehicle Preferences
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">Type</div>
                <div className="text-right w-1/2">(New) Sedan</div>
              </div>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">Make and model</div>
                <div className="text-right w-1/2">Audi Q3</div>
              </div>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">Year</div>
                <div className="text-right w-1/2">2017 to 2021</div>
              </div>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">
                  Kilometers Driven
                </div>
                <div className="text-right w-1/2">Below 50,000 km</div>
              </div>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">Price Range</div>
                <div className="text-right w-1/2">Below $20,000</div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<DownArrow className="fill-blue-500" />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="text-lg"
            >
              Home/Mortage
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">Moratge Amount</div>
                <div className="text-right w-1/2">$70,000</div>
              </div>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">Mortage With</div>
                <div className="text-right w-1/2">RMG Mortages</div>
              </div>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">Moratge Value</div>
                <div className="text-right w-1/2">$50,000</div>
              </div>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">Monthly Payment</div>
                <div className="text-right w-1/2">$300</div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<DownArrow className="fill-blue-500" />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="text-lg"
            >
              Current Employment
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">Employemnt Type</div>
                <div className="text-right w-1/2">Trades (Full Time)</div>
              </div>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">Occupation</div>
                <div className="text-right w-1/2">Marketing Executive</div>
              </div>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">Employer</div>
                <div className="text-right w-1/2">ABC ltd</div>
              </div>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">Work Address</div>
                <div className="text-right w-1/2"></div>
              </div>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">Suite Number</div>
                <div className="text-right w-1/2">53</div>
              </div>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">Address Number</div>
                <div className="text-right w-1/2">211</div>
              </div>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">Address Type</div>
                <div className="text-right w-1/2">Street</div>
              </div>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">Street Name</div>
                <div className="text-right w-1/2">Albert Street</div>
              </div>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">Street Type</div>
                <div className="text-right w-1/2">Road</div>
              </div>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">Direction</div>
                <div className="text-right w-1/2">South-East</div>
              </div>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">Postal Code</div>
                <div className="text-right w-1/2">A0B 1C2</div>
              </div>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">Telephone</div>
                <div className="text-right w-1/2">100-xxx-0021, Ext 212</div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<DownArrow className="fill-blue-500" />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="text-lg"
            >
              Income Details
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">
                  Gross Income amount
                </div>
                <div className="text-right w-1/2">$42,00 (Monthly)</div>
              </div>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">
                  Other Income Source
                </div>
                <div className="text-right w-1/2">Child Support/ Alimony</div>
              </div>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">
                  Other Income amount
                </div>
                <div className="text-right w-1/2">$1,000 (Monthly)</div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<DownArrow className="fill-blue-500" />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="text-lg"
            >
              Social Incurance Number (SIN)
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex justify-between">
                <div className="w-1/2 text-slate-400 mr-3">
                  Socail Insurance Number
                </div>
                <div className="text-right w-1/2">*** *** *53</div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        {/* Documents & Approve */}
        <div className="w-3/5 ml-3">
          <div className="border-2 border-blue-500 rounded-lg p-3">
            <div className="flex items-center">
              <div className="text-slate-400 mr-4">Credit Score</div>
              <div className="text-lg">700</div>
            </div>
            <div className="text-xl mt-3">Documents Uploaded</div>
            <div className="flex justify-between flex-wrap">
              <div className="my-2 mr-2">
                <div className="text-blue-500">Bank Statement</div>
                <img
                  className="h-24 w-60 object-cover rounded-lg border-2 border-blue-500 p-1 cursor-pointer"
                  src={LicenceFr}
                  alt="LicenceFr"
                />
              </div>
              <div className="my-2 mr-2">
                <div className="text-blue-500">Self Assessment</div>
                <img
                  className="h-24 w-60 object-cover rounded-lg border-2 border-blue-500 p-1 cursor-pointer"
                  src={LicenceFr}
                  alt="LicenceFr"
                />
              </div>
              <div className="my-2 mr-2">
                <div className="text-blue-500">Void Checque</div>
                <img
                  className="h-24 w-60 object-cover rounded-lg border-2 border-blue-500 p-1 cursor-pointer"
                  src={LicenceFr}
                  alt="LicenceFr"
                />
              </div>
            </div>
          </div>
          <div
            className="bg-blue-500 text-slate-50 border-2 border-transparent rounded-lg text-center w-full py-2 my-3 
                      cursor-pointer hover:bg-white hover:border-blue-500 hover:text-blue-500 "
            onClick={() => props.setStep(3)}
          >
            Send to dealertrack
          </div>
          {/* <div
            className="text-blue-500 border rounded-lg text-center w-full py-2 my-3 cursor-pointer hover:bg-blue-500  hover:text-white "
            onClick={() => handleOpenModal()}
          >
            Need more Information?
          </div> */}
          <div className="text-center text-red-400 cursor-pointer hover:bg-red-400 hover:text-white rounded-lg py-2">
            Reject
          </div>
        </div>
      </div>
      {/* <SendQueryModal open={open} handleCloseModal={handleCloseModal} /> */}
    </>
  );
};

export default PreApprovalOrder;
