import React, { useEffect, useState } from "react";
import roadImage from "../../../../assets/images/road.png";

const Finance = () => {
  const [currentImage, setCurrentImage] = useState(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // setCurrentImage(images[Math.floor(Math.random() * images.length)]);
    }, 1200);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="md:financeDiv my-10 flex w-full flex-wrap justify-center rounded-3xl bg-blue-100 py-10 md:my-20 md:py-12 md:pb-20">
      {/* TEXT AREA */}
      <div className="xs:w-10/12 md:w-fit">
        <div className="font-semibold-700 w-8/12 text-xl md:text-2xl lg:text-3xl">
          Find & Purchase
          <p>
            Your Next Car <span className="text-blue-500">Easily</span>
          </p>
        </div>
        {/* DESCRIPTION */}
        <div className="mb-2 mt-10 flex text-left md:mt-16">
          <div className="mt-1.5 h-6 w-6 rounded-full bg-blue-500  md:h-8 md:w-8"></div>
          <div className="ml-4 w-4/5">
            <p className="mb-1 text-sm font-bold text-gray-700">
              Search and find your ideal option
            </p>
            <p className="w-full text-sm text-gray-600 md:w-70">
              Whether you're looking for a specific vehicle or use smart finance
              to see what you
            </p>
          </div>
        </div>
        <div className="mb-2 flex text-left">
          <div className="mt-1.5 h-6 w-6 rounded-full bg-blue-400 md:h-8 md:w-8"></div>
          <div className="ml-4 w-4/5">
            <p className="mb-1 text-sm font-bold text-gray-700">
              Cater your deal
            </p>
            <p className="w-full text-sm text-gray-600 md:w-70">
              Customize your deal and choose the ideal offer that fits your
              need.
            </p>
          </div>
        </div>
        <div className="flex text-left">
          <div className="mt-1.5 h-6 w-6 rounded-full bg-blue-300 md:h-8 md:w-8"></div>
          <div className="ml-4 w-4/5">
            <p className="mb-1 text-sm font-bold text-gray-700">
              Get you vehicle delivered
            </p>
            <p className="w-full text-sm text-gray-600 md:w-70">
              Uobo provides delivery services for all vehicles on our platform.
            </p>
          </div>
        </div>
      </div>
      {/* IMAGE / SVG */}
      <div className="hidden self-center lg:block lg:w-96">
        <img src={roadImage} alt="" />
      </div>
    </div>
  );
};

export default Finance;
