import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { sendAppointmentsToCustomer } from "../../../api/orders/helper";
import { sendFinanceAppointmentsToCustomer } from "../../../api/orders/helper";
import Varified from "../../../assets/images/Varified.png";

const BillOfSale = (props) => {
    // console.log('---PROPS---', props);
    const [success, setSuccess] = useState(0);

    
      const [selectedFile, setSelectedFile] = useState(null);
   
      const handleFileUpload = (event) => {
        console.log('----FILE-----', event.target.files[0]);
        setSelectedFile(event.target.files[0]);
      };
   
      const handleUpload = () => {

        if(!selectedFile) {
          alert('Please select a file');
          return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(selectedFile);
          reader.onload = () => {
          console.log(reader.result);

          let encodedBillOfSale = reader.result;

          const token = JSON.parse(localStorage.getItem("token"));

          if(props.query.order.category === 'Fix') {
            let body = {
              financeId: props.query.order._id,
              status: 'DealerSentBillOfSale',
              confirmAvailabilty: true,
              dealerEstimatedTradeInValue: "0",
              tradeInCarValue: "0",
              financeApproval: true,
              additionalDocuments: [],
              appointments: [],
              EMIOptions: [],
              billOfSale: encodedBillOfSale
            }
        
            
            sendFinanceAppointmentsToCustomer(body, token).then((response) => {
              console.log(response);
        
                setSuccess(1);
  
                //setTimeout(() => {
                    props.query.handleCloseBillOfSale();
                //}, 2000);
        
            }, (err) => {
              console.log('Error sending bill of sale', err);
            });
          }
          else if(props.query.order.category === 'Cash') {
            let body = {
              financeId: props.query.order._id,
              status: 'DealerSentBillOfSale',
              confirmAvailabilty: true,
              tradeInCarValue: "0",
              additionalDocuments: [],
              appointments: [],
              billOfSale: encodedBillOfSale
            }
        
            
            sendAppointmentsToCustomer(body, token).then((response) => {
              console.log(response);
        
                setSuccess(1);
  
                //setTimeout(() => {
                    props.query.handleCloseBillOfSale();
                //}, 2000);
        
            }, (err) => {
              console.log('Error sending bill of sale', err);
            }); 
          }

        };
        // const formData = new FormData();
        // formData.append('file', selectedFile);
        // axios.post('/api/upload', formData)
        //   .then((response) => {
        //     console.log(response.data);
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });
      };

    // function handleSubmit(event) {
    //     event.preventDefault();

    //     setSuccess(1);

    //     setTimeout(() => {
    //         props.query.handleCloseBillOfSale();
    //     }, 2000);
    // }
    return(
        
            <Modal show={props.query.handleOpenBillOfSale} onHide={() => props.query.handleCloseBillOfSale} centered>
        {success === 0 && (
            <><Modal.Header>
            <Modal.Title className="w-full">
              <div className="text-center">
                Awaiting Bill Of Sale
              </div>
              <div className="text-center">
                Upload file below
              </div>
            </Modal.Title>
          </Modal.Header><Modal.Body>
              <div className="border-2 border-blue-500 rounded-lg">
                <input type="file" onChange={handleFileUpload} />
                <div className="text-center text-blue-500 border-t  rounded-lg py-2 mx-2 my-2 cursor-pointer hover:bg-blue-500 hover:text-white"
                onClick={handleUpload}>
                  Upload Bill Of Sale
                </div>
              </div>
            </Modal.Body><Modal.Footer>
              <div
                className="bg-red-500 text-xl text-white text-center py-2 rounded-2xl w-48 float-right mb-10 cursor-pointer"
                onClick={() => props.query.handleCancelBillOfSale()}
              >
                Cancel
              </div>
            </Modal.Footer></>
        )}
        {success === 1 && (
            <><Modal.Body>
            <div className="flex justify-center items-center">
                <img
                    className="h-16 w-16"
                    src={Varified}
                    alt="Verified"
                  />
                </div>
                <div className="text-center">
                  <h4>Successfully Approved</h4>
                  <span className="text-sm">You will be notified when customer upload Bill Of Sale.</span>
                </div>
                <div className="flex justify-between my-3">
      
                </div>
            </Modal.Body></>
            
        )}
    </Modal>
       
        
    );

};

export default BillOfSale;