import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { toast } from "react-toastify";
import Loader from '../loader';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const AddSignaturePlaceholders = () => {
  const [file, setFile] = useState(null);
  const [pdfDoc, setPdfDoc] = useState(null);
  const [placeholders, setPlaceholders] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedPlaceholderType, setSelectedPlaceholderType] = useState('Signature');
  const [signCount, setSignCount] = useState(0);
  const [initialCount, setInitialCount] = useState(0);
  const pdfRef = useRef([]);

  const navigate = useNavigate();
  const { state } = useLocation() || {};

  useEffect(() => {
    const loadPdf = async () => {
      if (file) {
        try {
          const arrayBuffer = await file.arrayBuffer();
          const loadedPdfDoc = await PDFDocument.load(arrayBuffer);
          setPdfDoc(loadedPdfDoc);
          setNumPages(loadedPdfDoc.getPageCount());
        } catch (error) {
          console.error('Error loading PDF:', error);
        }
      }
    };
    loadPdf();
  }, [file]);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setPlaceholders([]);
    setSignCount(0);
    setInitialCount(0);
  };

  const handlePdfClick = (pageNumber, event) => {
    if (!pdfRef.current[pageNumber - 1] || !pdfDoc) return;

    const rect = pdfRef.current[pageNumber - 1].getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const width = rect.width;
    const height = rect.height;
    const relX = (x / width) * 100;
    const relY = (y / height) * 100;

    const newPlaceholders = [];

    if (selectedPlaceholderType === 'Signature') {
      setSignCount(signCount + 1);
      newPlaceholders.push({
        pageNumber,
        x: relX,
        y: relY,
        width: 80,
        height: 20,
        label: `Sign ${signCount + 1}`,
      });
    }

    if (selectedPlaceholderType === 'Initial') {
      setInitialCount(initialCount + 1);
      newPlaceholders.push({
        pageNumber,
        x: relX,
        y: relY,
        width: 40,
        height: 20,
        label: `Init ${initialCount + 1}`,
      });
    }

    setPlaceholders([...placeholders, ...newPlaceholders]);
  };

  const clearPlaceholders = () => {
    setPlaceholders([]);
    setSignCount(0);
    setInitialCount(0);
  };

  const savePdfWithPlaceholders = async () => {
    setLoading(true);
    if (!pdfDoc) {
      setLoading(false);
      console.error('PDF document is not loaded');
      return;
    }

    const modifiedPdf = await addPlaceholdersToPdf(pdfDoc, placeholders);

    try {
      const pdfBytes = await modifiedPdf.save();
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });

      const formData = new FormData();
      formData.append('file', blob, 'document_with_placeholders.pdf');
      formData.append('signerEmail', state?.order?.customerId?.email);
      formData.append('signerName', state?.order?.customerId?.firstName);
      formData.append('ccEmail', 'harsh@uobo.ca');
      formData.append('ccName', 'Harsh');
      formData.append('orderId', state?.order?._id);

      placeholders.forEach((placeholder, index) => {
        const { pageNumber, x, y, width, height, label } = placeholder;
        const page = pdfDoc.getPage(pageNumber - 1);
        const { width: pageWidth, height: pageHeight } = page.getSize();
        const xPosInches = (x / 100) * pageWidth / 72;
        const yPosInches = ((100 - y) / 100) * pageHeight / 72;
        const xPosition = Math.round(72 * xPosInches);
        const yPosition = Math.round(72 * yPosInches);
        formData.append(`placeholders[${index}][x]`, xPosition);
        formData.append(`placeholders[${index}][y]`, yPosition);
        formData.append(`placeholders[${index}][width]`, width);
        formData.append(`placeholders[${index}][height]`, height);
        formData.append(`placeholders[${index}][pageNumber]`, pageNumber);
        formData.append(`placeholders[${index}][label]`, label);
      });

      await fetch('https://api.uobo.ca/api/finance/sendEnvelope', {
        method: 'POST',
        body: formData,
      });

      toast.success("Document submitted successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });

      navigate('/dealer-dashboard')
    } catch (error) {
      console.error('Error saving PDF:', error);
      toast.error("Something went wrong !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setLoading(false);
    }
  };

  const addPlaceholdersToPdf = async (pdfDoc, placeholders) => {
    const copiedPdf = await PDFDocument.load(await pdfDoc.save());

    for (let i = 0; i < placeholders.length; i++) {
      const placeholder = placeholders[i];
      const { pageNumber, x, y, width, height, label } = placeholder;

      const page = copiedPdf.getPage(pageNumber - 1);
      const { width: pageWidth, height: pageHeight } = page.getSize();

      const absX = (x / 100) * pageWidth;
      const absY = pageHeight - (y / 100) * pageHeight - height;

      page.drawRectangle({
        x: absX,
        y: absY,
        width: width,
        height: height,
        borderColor: rgb(0, 0, 0),
        borderWidth: 1,
      });

      const helveticaFont = await copiedPdf.embedFont(StandardFonts.Helvetica);
      page.drawText(label, {
        x: absX + 5,
        y: absY + 5,
        size: 10,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });
    }

    return copiedPdf;
  };

  return (
    <div>
      <h3 className="text-center">
        Send bill of sale 
      </h3>
      <input type="file" onChange={handleFileChange} />

      {file && (
        <>
          <div className="placeholder-options">
            <label>
              Select placeholder type:
              <select 
                value={selectedPlaceholderType} 
                onChange={(e) => setSelectedPlaceholderType(e.target.value)}
              >
                <option value="Signature">Signature</option>
                <option value="Initial">Initial</option>
              </select>
            </label>
          </div>

          <div style={{ cursor: 'pointer', position: 'relative' }}>
            <Document
              file={file}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
              error="Failed to load PDF"
            >
              {Array.from(new Array(numPages), (el, index) => (
                <div
                  key={`page_${index + 1}`}
                  ref={(el) => (pdfRef.current[index] = el)}
                  style={{ position: 'relative' }}
                  onClick={(e) => handlePdfClick(index + 1, e)}
                >
                  <Page pageNumber={index + 1} />
                  {placeholders
                    .filter((placeholder) => placeholder.pageNumber === index + 1)
                    .map((placeholder, index) => (
                      <div
                        key={index}
                        style={{
                          position: 'absolute',
                          left: `${placeholder.x}%`,
                          top: `${placeholder.y}%`,
                          width: `${placeholder.width}px`,
                          height: `${placeholder.height}px`,
                          border: '1px solid black',
                          pointerEvents: 'none',
                          zIndex: 1,
                        }}
                      >
                        {placeholder.label}
                      </div>
                    ))}
                </div>
              ))}
            </Document>
            {loading && <Loader />}
            <div className="flex flex-row items-center mt-3 xs:w-40 xl:w-auto">
              <button 
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2" 
                onClick={clearPlaceholders}
              >
                Clear All Placeholders
              </button>
              {!loading && (
                <button 
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" 
                  onClick={savePdfWithPlaceholders}
                >
                  Send Bill Of Sale
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AddSignaturePlaceholders;
