import api from "../../component/common/api/api";

export const getUserInventory = async (userId, token) => {
  try {
    const response = await api.get(`/api/dealer/?id=${userId}`, {
      headers: {
        Authorization: token,
      },
    });
    return response?.data?.Data[0]?.inventory;
  } catch (error) {
    console.error(error, "Inventory not fetched!");
  }
};
