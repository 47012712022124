// EMIDetailsModal.js
import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const CustomerTradeInModal = ({ show, handleClose, tradeInDecision }) => {

  const modalBodyStyle = {
    maxHeight: '400px',  // Adjust the max height as needed
    overflowY: 'auto',
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Customer Trade In Decision</Modal.Title>
      </Modal.Header>
      <Modal.Body style={modalBodyStyle}>
      <h5>Trade In Car Details</h5>
        {
            tradeInDecision.customerTradeInDecision === true ? 
            <p>Customer accepted trade in offer</p> : 
            <p>Customer rejected trade in offer</p> 
        }

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomerTradeInModal;
