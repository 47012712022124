import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Image1 from "../../../../assets/images/help/help1.png";
import Image2 from "../../../../assets/images/help/help2.png";
import Image3 from "../../../../assets/images/help/help3.png";
import Image4 from "../../../../assets/images/help/help4.png";
import Image5 from "../../../../assets/images/help/help5.png";
import Image6 from "../../../../assets/images/help/help6.png";

const Help = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [dealerName, setDealerName] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("dealerSignin")) {
      const name = JSON.parse(localStorage.getItem("dealerSignin")).firstName;
      setDealerName(name);
    }
  }, [dealerName]);

  const images = [Image1, Image2, Image3, Image4, Image5, Image6];

  const handleNext = () => {
    if (currentIndex === images.length - 1) {
      navigate("/dealer-dashboard");
    }
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <div className="mb-3 mt-20 flex w-full flex-col flex-wrap items-center lg:mt-3">
      {currentIndex === 0 ? (
        <>
          <div className="mb-2.5 text-lg font-medium text-blue-500 md:text-2xl">
            Hey {dealerName}, Lets get you on track!
          </div>
          <div className="text-xs text-slate-500 md:text-base">
            There will be <strong className="text-slate-600">3 cases</strong>{" "}
            that pops up on the{" "}
            <span className="text-slate-700">'Live Orders'</span>
            section.
          </div>
          <div className="text-xs text-slate-500 md:text-base">
            These will be of the highest priority and cannot be skipped.
          </div>
          <div className="mt-10">
            <img
              src={images[currentIndex]}
              alt="Help Slide"
              className="md:w-12/12 m-auto w-10/12 lg:w-6/12"
            />
          </div>
          <div className="mb-6 mt-3 flex w-full justify-between text-center  lg:w-9/12 lg:justify-evenly">
            <div className="w-32 text-xs text-slate-500 md:w-52 md:text-base">
              The customer chooses to buy a vehicle{" "}
              <strong className="text-slate-600">in cash.</strong>
            </div>
            <div className="w-32 text-xs text-slate-500 md:w-52 md:text-base">
              The customer chooses to buy a vehicle by{" "}
              <strong className="text-slate-600">financing.</strong>
            </div>
            <div className="w-32 text-xs text-slate-500 md:w-52 md:text-base">
              The customer chooses to buy a vehicle by{" "}
              <strong className="text-slate-600">
                pre-approved financing.
              </strong>
            </div>
          </div>
        </>
      ) : (
        <div className="mb-3 mt-10">
          <img
            src={images[currentIndex]}
            alt="Help Slide 1"
            className="m-auto w-11/12 lg:w-8/12"
          />
        </div>
      )}
      <button
        className="rounded-lg bg-blue-500 px-24 py-2 text-base  text-white md:px-44 md:text-lg"
        onClick={handleNext}
      >
        {currentIndex === 5 ? "Finish" : "Next"}
      </button>
    </div>
  );
};

export default Help;
