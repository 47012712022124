import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import "../../../../assets/scss/custom.scss";
import { DownArrow, Location } from "../../../../assets/icons/icons";
import AudiA4 from "../../../../assets/images/audiA4Fr.avif";
import LicenceFr from "../../../../assets/images/licenceFr.png";
import SendQueryModal from "../../../common/modal/sendQueryModal";

const FinanceApprovalOrder = (props) => {
  const [open, setOpen] = useState(false);

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };
  return (
    <>
      <div className="flex">
        {/* Customer Details */}
        <div className="w-2/5 mr-3 preApproval">
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<DownArrow className="fill-blue-500" />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="text-lg"
            >
              Vehicle Details
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex justify-between">
                <div className="w-1/2 mr-3">
                  <img
                    className="h-32 w-full object-cover rounded-lg"
                    src={AudiA4}
                    alt="AudiA4"
                  />
                </div>
                <div className="w-1/2">
                  <div className="my-1">Audi A4</div>
                  <div className="my-1">2019</div>
                  <div className="flex items-center">
                    <Location height="14" width="14" /> Mississauga, Ontario
                  </div>
                  <div className="my-1">$16,000</div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<DownArrow className="fill-blue-500" />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="text-lg"
            >
              Customer Details
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex justify-between my-2">
                <div className="w-1/2 text-slate-400 mr-3">Name</div>
                <div className="text-right w-1/2">Mr. Doe John</div>
              </div>
              <div className="flex justify-between my-2">
                <div className="w-1/2 text-slate-400 mr-3">Date of Birth</div>
                <div className="text-right w-1/2">01/07/1993</div>
              </div>
              <div className="flex justify-between my-2">
                <div className="w-1/2 text-slate-400 mr-3">Gender</div>
                <div className="text-right w-1/2">Male</div>
              </div>
              <div className="flex justify-between my-2">
                <div className="w-1/2 text-slate-400 mr-3">Contact Number</div>
                <div className="text-right w-1/2">+172XXXXXX68</div>
              </div>
              <div className="flex justify-between my-2">
                <div className="w-1/2 text-slate-400 mr-3">Marital Status</div>
                <div className="text-right w-1/2">Common Law</div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<DownArrow className="fill-blue-500" />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="text-lg"
            >
              Trade-In Details
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <img
                  className="h-32 w-full object-cover rounded-lg"
                  src={AudiA4}
                  alt="AudiA4"
                />
              </div>
              <div className="flex justify-between my-2">
                <div className="w-1/2 text-slate-400 mr-3">Make and model</div>
                <div className="text-right w-1/2">
                  2010 Audi A4 (1.5 LT Luxury edition)
                </div>
              </div>
              <div className="flex justify-between my-2">
                <div className="w-1/2 text-slate-400 mr-3">Mileage</div>
                <div className="text-right w-1/2">123,600 km</div>
              </div>
              <div className="flex justify-between my-2">
                <div className="w-1/2 text-slate-400 mr-3">Color</div>
                <div className="text-right w-1/2">Gray</div>
              </div>
              <div className="flex justify-between my-2">
                <div className="w-1/2 text-slate-400 mr-3">Transmission</div>
                <div className="text-right w-1/2">Automatic</div>
              </div>
              <div className="flex justify-between my-2">
                <div className="w-1/2 text-slate-400 mr-3">Vehicle Value</div>
                <div className="text-right w-1/2">$6,600</div>
              </div>
              <div className="flex justify-between my-2">
                <div className="w-1/2 text-slate-400 mr-3">Tax Savings</div>
                <div className="text-right w-1/2">$1,817</div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<DownArrow className="fill-blue-500" />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="text-lg"
            >
              Payment Details
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex justify-between my-2">
                <div className="w-1/2 text-slate-400 mr-3">Prefered Method</div>
                <div className="text-right w-1/2">Delivery</div>
              </div>
              <div className="flex justify-between my-2">
                <div className="w-1/2 text-slate-400 mr-3">Delivery Cost</div>
                <div className="text-right w-1/2">$199</div>
              </div>
              <div className="flex justify-between my-2">
                <div className="w-1/2 text-slate-400 mr-3">Warranty Cost</div>
                <div className="text-right w-1/2">$1,995</div>
              </div>
              <div className="flex justify-between my-2">
                <div className="w-1/2 text-slate-400 mr-3">Trade-In Value</div>
                <div className="text-right w-1/2">$8,417</div>
              </div>
              <div className="flex justify-between my-2">
                <div className="w-1/2 text-slate-400 mr-3">Down Payment</div>
                <div className="text-right w-1/2">$500 (paid)</div>
              </div>
              <div className="flex justify-between my-2 border-t">
                <div className="w-1/2 text-slate-400 mr-3">
                  Total amount due
                </div>
                <div className="text-right w-1/2">$12,447.15</div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        {/* Documents & Approve */}
        <div className="w-3/5 ml-3">
          <div className="border-2 border-blue-500 rounded-lg p-3">
            <div className="flex items-center">
              <div className="text-slate-400 mr-4">Credit Score</div>
              <div className="text-lg">700</div>
            </div>
            <div className="text-xl mt-3">Documents Uploaded</div>
            <div className="flex justify-between flex-wrap">
              <div className="my-2 mr-2">
                <div className="text-blue-500">Bank Statement</div>
                <img
                  className="h-24 w-60 object-cover rounded-lg border-2 border-blue-500 p-1 cursor-pointer"
                  src={LicenceFr}
                  alt="LicenceFr"
                />
              </div>
              <div className="my-2 mr-2">
                <div className="text-blue-500">Self Assessment</div>
                <img
                  className="h-24 w-60 object-cover rounded-lg border-2 border-blue-500 p-1 cursor-pointer"
                  src={LicenceFr}
                  alt="LicenceFr"
                />
              </div>
              <div className="my-2 mr-2">
                <div className="text-blue-500">Void Checque</div>
                <img
                  className="h-24 w-60 object-cover rounded-lg border-2 border-blue-500 p-1 cursor-pointer"
                  src={LicenceFr}
                  alt="LicenceFr"
                />
              </div>
            </div>
          </div>
          <div
            className="bg-blue-500 text-slate-50 border-2 border-transparent rounded-lg text-center w-full py-2 my-3 
                      cursor-pointer hover:bg-white hover:border-blue-500 hover:text-blue-500 "
            onClick={() => props.setStep(3)}
          >
            Send to dealertrack
          </div>
          <div
            className="text-blue-500 border rounded-lg text-center w-full py-2 my-3 cursor-pointer hover:bg-blue-500  hover:text-white "
            onClick={() => handleOpenModal()}
          >
            Need more Information?
          </div>
          <div className="text-center text-red-400 cursor-pointer hover:bg-red-400 hover:text-white rounded-lg py-2">
            Reject
          </div>
        </div>
      </div>
      <SendQueryModal open={open} handleCloseModal={handleCloseModal} />
    </>
  );
};

export default FinanceApprovalOrder;
