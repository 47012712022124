import React, { useEffect, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState('cad');
  const [dealerId, setDealerId] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    try {
      const { data: { clientSecret } } = await axios.post('https://api.uobo.ca/api/user/stripe-payment', {
        amount,
        currency,
        dealerId,
        requestType: 'test'
      });

      console.log(clientSecret);

      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: { email },
        },
      });

      if (result.error) {
        await paymentCompletion('fail');
        setError(result.error.message);
      } else if (result.paymentIntent.status === 'succeeded') {
        await paymentCompletion('completed');
        setSuccess(true);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const paymentCompletion = async (paymentStatus) => {
    try {
      const queryParams = new URLSearchParams(location.search);
      
      const response = await axios.post('https://api.uobo.ca/api/user/payment-alert', {
        customerId,
        dealerId,
        paymentStatus,
        amount,
        orderId: queryParams.get('orderId')
      });

      console.log(response);

      // if (response.error) {
      //   setError(response.error.message);
      // } else if (response.paymentIntent.status === 'succeeded') {
      //   setSuccess(true);
      // }

    } catch (error) {
      setError(error.message);
    }
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const orderId = queryParams.get('orderId');
    const amount = queryParams.get('amount');

    if (orderId) {
      axios.get(`https://api.uobo.ca/api/finance/order?orderId=${orderId}&amount=${amount}`)
        .then(response => {
          const { dealerId, customerId } = response.data.Data[0];
          // console.log("--------------",response.data.Data[0])
          // setEmail(email);
          setAmount(amount);
          setDealerId(dealerId);
          setCustomerId(customerId);
        })
        .catch(error => {
          console.error('Error fetching customer and dealer info:', error);
          setError('Failed to fetch customer and dealer information.');
        });
    } else {
      setError('Missing orderId in query parameters.');
    }
  },[location.search]);

  const cardElementOptions = {
    hidePostalCode: true,
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-xl mx-auto p-8 bg-white shadow-md rounded-lg">
      <div className="mb-6">
        <label htmlFor="email" className="block text-lg font-medium text-gray-700">Email</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
          className="mt-2 block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-lg"
        />
      </div>
      <div className="mb-6">
        <label htmlFor="amount" className="block text-lg font-medium text-gray-700">Amount (in cad)</label>
        <input
          type="number"
          id="amount"
          value={amount}
          onChange={(e) => setAmount(Number(e.target.value))}
          placeholder="Amount in cents"
          required
          readOnly
          className="mt-2 block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-lg"
        />
      </div>
      <div className="mb-6">
        <label className="block text-lg font-medium text-gray-700">Card Details</label>
        <CardElement
          options={cardElementOptions}
          className="mt-2 block w-96 px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-lg"
        />
      </div>
      <button
        type="submit"
        disabled={!stripe}
        className="w-full px-6 py-3 font-semibold text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 rounded-md text-lg"
      >
        Pay
      </button>
      {error && <div className="mt-6 text-red-500 text-lg">{error}</div>}
      {success && <div className="mt-6 text-green-500 text-lg">Payment successful!</div>}
    </form>
  );
};

export default CheckoutForm;