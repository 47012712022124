import { React, useEffect, useState } from "react";

import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, Formik } from "formik";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import isEmpty from "lodash/isEmpty";
import * as Yup from "yup";
import axios from "axios";

import FieldErrorMessage from "../../../common/error";

const DealerSigninOTPForm = () => {
  const navigate = useNavigate();

  const [dealer, setDealer] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const dealer = JSON.parse(localStorage.getItem("dealerSignin"));
    if (dealer) {
      setDealer(dealer);
    }
  }, []);

  const resendOTP = async () => {
    setLoading(true);
    try {
      await axios.post(
        "https://verify.twilio.com/v2/Services/VA460f250497d83f25577f83fc2ccc80a6/Verifications",
        {
          To: "+1"+dealer.phoneNumber['number'],
          Channel: "sms",
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          auth: {
            username: process.env.REACT_APP_TWILLIO_AUTH_USERNAME,
            password: process.env.REACT_APP_TWILLIO_AUTH_PASSWORD,
          },
        }
      );
      setLoading(false);
    } catch (error) {
      console.error(error, "ERROR, OTP not send!");
      setLoading(false);
    }
  };

  const DealerSigninOTPFormValidationSchema = () =>
    Yup.object().shape({
      otp: Yup.string()
        .required("Please enter OTP.")
        .matches(/^\d+$/, "OTP must be a Numeric Value")
        .max(6, "OTP must be 6 digits.")
        .min(6, "OTP must be 6 digits."),
    });

  return (
    <div className="lg:w-2/5 h-98 overflow-auto tracking-wide flex flex-col justify-center">
      <p className="text-bold font-bold text-2xl mb-1 text-center tracking-widest">
        HELLO {dealer?.firstName.toUpperCase()}
      </p>
      <p className="text-blue-500 text-center">
        Please check your mobile for an OTP message
      </p>

      <Formik
        initialValues={{
          otp: "",
        }}
        onSubmit={async (values) => {
          setLoading(true);
          try {
            const response = await axios.post(
              "https://verify.twilio.com/v2/Services/VA460f250497d83f25577f83fc2ccc80a6/VerificationCheck",
              {
                To: "+1"+dealer.phoneNumber['number'],
                Code: values.otp,
              },
              {
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
                auth: {
                  username: process.env.REACT_APP_TWILLIO_AUTH_USERNAME,
                  password: process.env.REACT_APP_TWILLIO_AUTH_PASSWORD,
                },
              }
            );

            console.log( response.data.valid);
            localStorage.setItem("otpVerified", response.data.valid);
            setLoading(false);
            /// navigate to dealer dashboard
            response.data.valid? navigate("/dealer-dashboard") :
            toast.error("Invalid OTP", {position: toast.POSITION.TOP_RIGHT,});
          } catch (error) {
            toast.error(error.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
          }
        }}
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={DealerSigninOTPFormValidationSchema}
      >
        {({ handleSubmit, setFieldValue, errors }) => (
          <form onSubmit={handleSubmit} className="px-2">
            {/* OTP */}
            <div className={!!errors.otp ? "mb-3" : "mb-0"}>
              <FloatingLabel
                className={!!errors.otp ? "mb-0" : "mb-3"}
                label="One Time Password"
              >
                <Form.Control
                  type={"text"}
                  name="otp"
                  id="otp"
                  placeholder="otp"
                  className="mb-1"
                  onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                />
              </FloatingLabel>
              <ErrorMessage name="otp" component={FieldErrorMessage} />
            </div>

            {/* LOGIN BUTTON */}
            <button
              type="submit"
              disabled={!isEmpty(errors) || loading}
              className={`${
                isEmpty(errors) ? "bg-blue-500" : "bg-gray-300"
              } text-xl text-white  p-3 rounded-2xl w-full`}
            >
              {loading ? "Loading..." : "Confirm OTP"}
            </button>
          </form>
        )}
      </Formik>
      <div className="text-center mt-3">
        Didn't receive OTP?{" "}
        <span
          className="cursor-pointer underline text-blue-500"
          onClick={resendOTP}
        >
          Resend
        </span>
      </div>
    </div>
  );
};

export default DealerSigninOTPForm;
