import React, { useRef } from "react";
import {
  Search,
  Location,
  DownArrow,
  FourSquare,
} from "../../../../assets/icons/icons";

const EditFeatures = () => {
  const inputRef = useRef(null);
  const handleIconClick = () => {
    // Focus on the input when the SVG icon is clicked
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const renderSearchBar = () => {
    return (
      <div className="flex items-center justify-between mb-5">
        <div className="text-slate-400 text-sm">
          Select all features to be dispalyed on <b>Audi A4</b> page.
        </div>
        <div className="flex items-center bg-slate-200 rounded-2xl pr-2 cursor-pointer xs:mb-2 md:mb-0 md:mx-2 w-45">
          <input
            id="search"
            type="text"
            name="search"
            ref={inputRef}
            placeholder="Search a feature (alloy, light, sensor..)"
            className="bg-slate-200 rounded-2xl p-2 xs:pr-4 sm:pr-0 w-11/12"
          />
          <div onClick={handleIconClick}>
            <Search className="fill-blue-500" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="text-xl mt-4">Features</div>
      {renderSearchBar()}
      {/* Interior */}
      <div className="flex items-center">
        <div className="text-slate-400">Interior</div>
        <div className="flex flex-wrap ml-5">
          <div className="border-2 text-blue-500 bg-blue-50 cursor-pointer flex items-center justify-center rounded-full w-max px-4 py-2 m-2">
            <FourSquare className="fill-blue-500" height="16" />
            Leather
          </div>
          <div className="border-2 flex items-center justify-center rounded-full w-max px-4 py-2 m-2">
            <FourSquare height="16" />
            Wool
          </div>
          <div className="border-2 flex items-center justify-center rounded-full w-max px-4 py-2 m-2">
            <FourSquare height="16" />
            Suede
          </div>
          <div className="border-2 flex items-center justify-center rounded-full w-max px-4 py-2 m-2">
            <FourSquare height="16" />4 Airbags
          </div>
        </div>
      </div>

      {/* Vehicle */}
      <div className="flex items-center mt-5">
        <div className="text-slate-400">Vehicle</div>
        <div className="flex flex-wrap ml-5">
          <div className="border-2 text-blue-500 bg-blue-50 cursor-pointer flex items-center justify-center rounded-full w-max px-4 py-2 m-2">
            <FourSquare className="fill-blue-500" height="16" />
            17-inch wheels
          </div>
          <div className="border-2 flex items-center justify-center rounded-full w-max px-4 py-2 m-2">
            <FourSquare height="16" />
            18-inch wheels
          </div>
          <div className="border-2 flex items-center justify-center rounded-full w-max px-4 py-2 m-2">
            <FourSquare height="16" />
            Halogen Headlights
          </div>
          <div className="border-2 flex items-center justify-center rounded-full w-max px-4 py-2 m-2">
            <FourSquare height="16" /> LED Headlights
          </div>
          <div className="border-2 flex items-center justify-center rounded-full w-max px-4 py-2 m-2">
            <Location height="16" /> GPS
          </div>
          <div className="border-2 flex items-center justify-center rounded-full w-max px-4 py-2 m-2">
            <FourSquare height="16" /> ABS
          </div>
          <div className="border-2 flex items-center justify-center rounded-full w-max px-4 py-2 m-2">
            <FourSquare height="16" /> 4X4
          </div>
        </div>
      </div>
      {/* Technical */}
      <div className="flex items-center mt-5">
        <div className="text-slate-400">Technical</div>
        <div className="flex flex-wrap ml-5">
          <div className="border-2 text-blue-500 bg-blue-50 cursor-pointer flex items-center justify-center rounded-full w-max px-4 py-2 m-2">
            <FourSquare className="fill-blue-500" height="16" />
            Keyless Entry
          </div>
          <div className="border-2 flex items-center justify-center rounded-full w-max px-4 py-2 m-2">
            <FourSquare height="16" />
            Forward Collision warning
          </div>
          <div className="border-2 flex items-center justify-center rounded-full w-max px-4 py-2 m-2">
            <FourSquare height="16" />
            360&deg;Aerial camera
          </div>
          <div className="border-2 flex items-center justify-center rounded-full w-max px-4 py-2 m-2">
            <FourSquare height="16" /> Automatic climate control
          </div>
          <div className="border-2 flex items-center justify-center rounded-full w-max px-4 py-2 m-2">
            <Location height="16" /> Front and rear parking sensors
          </div>
        </div>
      </div>
      <div className="text-blue-500 flex items-center text-blue border-2 border-transparent cursor-pointer hover:border-blue-500 rounded-full px-3 py-2 w-max float-right">
        See More <DownArrow className="fill-blue-500" />
      </div>
    </>
  );
};

export default EditFeatures;
