import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import DashboardHeader from "./dashboardHeader/dashboardHeader";

import uoboLogo from "../../assets/images/logo.png";

const isAuthenticated = () => {
  const dealerSignin = localStorage.getItem("dealerSignin");
  const token = localStorage.getItem("token");
  const otpVerified = localStorage.getItem("otpVerified") === "true";
  const accountCreated = localStorage.getItem("accountCreated") === "true";

  return (
    (dealerSignin && token && otpVerified) ||
    (dealerSignin && token && accountCreated)
  );
};

const Header = (props) => {
  const [inDashboard, setInDashboard] = useState(false);
  const [otpVerify, setOtpVerify] = useState(null);

  let liveOrdersCount = props.liveOrdersCount;
  let notifications = props.notifications;
  const location = useLocation();
  const navigate = useNavigate();

  const dealer = isAuthenticated();

  const dealerLoggedIn =
    (dealer && otpVerify) ||
    (localStorage.getItem("dealerSignin") &&
      localStorage.getItem("accountCreated"));

  useEffect(() => {
    setInDashboard(
      location.pathname.includes("dealer-dashboard") ||
        location.pathname.includes("inventory") ||
        location.pathname.includes("live-order") ||
        location.pathname.includes("setting") ||
        location.pathname.includes("help"),
    );
    setOtpVerify(JSON.parse(localStorage.getItem("otpVerified")));
  }, [location.pathname, otpVerify]);

  const signOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("dealerSignin");
    localStorage.removeItem("otpVerified");
    localStorage.removeItem("accountCreated");
    setTimeout(() => {
      navigate("/");
    }, 500);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <header className="flex justify-center lg:mb-16">
      <div
        className={`fixed z-40 mx-5  flex w-full items-center justify-between p-1 backdrop-blur-sm md:w-11/12`}
      >
        <div onClick={scrollToTop}>
          <Link to="/home">
            <img src={uoboLogo} alt="Uobo Logo" className="h-14" />
          </Link>
        </div>
        {inDashboard && <DashboardHeader liveOrdersCount={liveOrdersCount} notifications={notifications} />}
        {!inDashboard && !location.pathname.includes("/payment") && (
          <div>
            { dealer && (
              <a
                href="/dealer-dashboard"
                className="mr-5 text-xs text-blue-500 no-underline md:text-sm"
              >
                Dashboard
              </a>
            )}
            <a
              href="#"
              className="mr-5 text-xs text-blue-500 no-underline md:text-sm"
            >
              Blog
            </a>
            <a
              href="mailto:saleh.h.zarandah@gmail.com"
              className="mr-5 text-xs text-blue-500 no-underline md:text-sm"
            >
              Contact
            </a>
            {!dealer && !location.pathname.includes("/payment") && (
              <Fragment>
                <Link
                  className="mr-3 p-2 text-xs text-blue-500 no-underline md:text-sm"
                  to="/dealer-signin"
                >
                  Login
                </Link>
                <Link
                  to="/dealer-signup"
                  className="rounded-full bg-blue-500 px-3 py-2 text-xs text-white no-underline md:text-sm"
                >
                  Join Uobo
                </Link>
              </Fragment>
            )}
            {dealerLoggedIn && !location.pathname.includes("/payment") && (
              <Link
                className="p-2 text-xs text-red-500 no-underline md:text-sm"
                onClick={signOut}
              >
                Logout
              </Link>
            )}
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
