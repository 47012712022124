import React, {useRef, useState} from "react";
import { sendAppointmentsToCustomer } from "../../../api/orders/helper";
import { sendFinanceAppointmentsToCustomer } from "../../../api/orders/helper";
import Modal from "react-bootstrap/Modal";

const SendQueryModal = (props) => {

  console.log('-----SEND QUERY MODAL-----', props);

  const checkboxRef1 = useRef(null);
  const checkboxRef2 = useRef(null);
  const checkboxRef3 = useRef(null);
  const textareaRef = useRef(null);

  let [additionalDocuments, setAdditionalDocuments] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    console.log("Textarea value:", textareaRef.current.value);
    console.log("Checkbox checked 1:", checkboxRef1.current.checked);
    if(checkboxRef1.current.checked === true) {
      console.log("Checkbox value 1:", checkboxRef1.current.value);
      additionalDocuments.push({
        nameOfDocument: checkboxRef1.current.value,
        explanation: textareaRef.current.value
      });
    }
    console.log("Checkbox value 2:", checkboxRef2.current.checked);
    if(checkboxRef2.current.checked === true) {
      console.log("Checkbox value 2:", checkboxRef2.current.value);
      additionalDocuments.push({
        nameOfDocument: checkboxRef2.current.value,
        explanation: textareaRef.current.value
      });
    }
    console.log("Checkbox value 3:", checkboxRef3.current.checked);
    if(checkboxRef3.current.checked === true) {
      console.log("Checkbox value 3:", checkboxRef3.current.value);
      additionalDocuments.push({
        nameOfDocument: checkboxRef3.current.value,
        explanation: textareaRef.current.value
      });
    }

    //if(checkboxRef1.current.value !== undefined || checkboxRef1.current.value !== null) {
      
    //}
    //console.log('----------ADDITIONAL DOCS-----------', additionalDocuments);

    if(props.queryModal.category === 'Cash') {
      //API will send here
      let body = {
        financeId: props.queryModal.orderId,
        status: 'AdditionalDocumentAskedFromDealer',
        confirmAvailabilty: true,
        tradeInCarValue: "0",
        additionalDocuments: additionalDocuments,
        appointments: [],
        billOfSale: ''
      }

      const token = JSON.parse(localStorage.getItem("token"));
      sendAppointmentsToCustomer(body, token).then((response) => {
        console.log(response);

        props.queryModal.handleCloseModal();
        props.queryModal.handleSuccessOpenModal();
        setLoading(false);

      }, (err) => {
        setLoading(false);
        console.log('Error sending appointments', err);
      });
    }
    else if(props.queryModal.category === 'Fix') {
      //API will send here
      let body = {
        financeId: props.queryModal.orderId,
        status: 'AdditionalDocumentAskedFromDealer',
        confirmAvailabilty: true,
        tradeInCarValue: "0",
        additionalDocuments: additionalDocuments,
        appointments: [],
        EMIOptions: [],
        billOfSale: ''
      }

      const token = JSON.parse(localStorage.getItem("token"));
      sendFinanceAppointmentsToCustomer(body, token).then((response) => {
        console.log(response);

        props.queryModal.handleCloseModal();
        props.queryModal.handleSuccessOpenModal();
        setLoading(false);

      }, (err) => {
        setLoading(false);
        console.log('Error sending appointments', err);
      });
    }
  }
  
  return (
    
    <Modal show={props.queryModal.open} onHide={() => props.queryModal.handleCloseModal()} centered>
      <form>
      <Modal.Header>
        <Modal.Title>
          <div className="text-center">
            Let {props?.queryModal?.customerName !== '' && props?.queryModal?.customerName !== undefined && props?.queryModal?.customerName !== null ? props?.queryModal?.customerName : 'customer'} know what more details would be required
          </div>
          <div className="flex justify-between my-3">
            <div className="rounded-full mr-3 text-sm border p-2 cursor-pointer hover:bg-blue-500 hover:text-white">
              <input type="checkbox" name="additional_document" ref={checkboxRef1} value="bank statement" />
              <label className="mx-2" htmlFor="r1">Bank Statement</label>
            </div>
            <div className="rounded-full mr-3 text-sm border p-2 cursor-pointer hover:bg-blue-500 hover:text-white">
            <input type="checkbox" name="additional_document" ref={checkboxRef2} value="self assessment" />
            <label className="mx-2" htmlFor="r2">Self Assessment</label>
            </div>
            <div className="rounded-full mr-3 text-sm border p-2 cursor-pointer hover:bg-blue-500 hover:text-white">
            <input type="checkbox" name="additional_document" ref={checkboxRef3} value="void check" />
              <label className="mx-2" htmlFor="r3">Void Cheque</label>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="border-2 border-blue-500 rounded-lg">
          <textarea
            name="reason"
            id="query"
            rows="3"
            ref={textareaRef}
            placeholder="Start Typing..."
            className="w-full p-1 mt-1 focus-visible:outline-none"
          ></textarea>
          {/* <div className="text-center text-blue-500 border-t  rounded-lg py-2 mx-2 my-2 cursor-pointer hover:bg-blue-500 hover:text-white">
            <input type="submit" value={loading ? "Loading..." : "Send"} />
          </div> */}
          <button
              type="submit"
              className="text-center text-blue-500 border-t  rounded-2xl w-full py-2 mx-2 my-2 cursor-pointer hover:bg-blue-500 hover:text-white"
              onClick={handleSubmit}
          >
              {loading ? "Loading..." : "Send"}
          </button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div
          className="bg-red-500 text-xl text-white text-center py-2 rounded-2xl w-48 float-right mb-10 cursor-pointer"
          onClick={() => props.queryModal.handleCloseModal()}
        >
          Close
        </div>
      </Modal.Footer>
      </form>
    </Modal>
    
  );
};

export default SendQueryModal;
