import React, { createContext, useReducer, useEffect } from "react";
import { createEventSource, closeEventSource } from "../api/orders/eventStreamManager";
import { dealerOrderReducer } from "./dealerOrderReducer";

// Create a context
export const DealerOrderContext = createContext();

export const DealerOrderProvider = ({ children }) => {
  const [state, dispatch] = useReducer(dealerOrderReducer, { orderWithStatuses: null });

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("dealerSignin"));
    const dealerId = data?._id;

    if (dealerId) {
      createEventSource(dealerId, (orders) => {
        dispatch({ type: "SET_ORDERS", payload: orders });
      });
    }

    return () => {
      closeEventSource();
    };
  }, []);

  return (
    <DealerOrderContext.Provider value={state}>
      {children}
    </DealerOrderContext.Provider>
  );
};
