import React from "react";
import Modal from "react-bootstrap/Modal";
import Varified from "../../../assets/images/Varified.png";

const SuccessModal = (props) => {
  
  return (
    
    <Modal show={props.queryModal.success} onHide={() => props.queryModal.handleSuccessCloseModal()} centered>
      <Modal.Body>
      <div className="flex justify-center items-center">
          <img
              className="h-16 w-16"
              src={Varified}
              alt="Verified"
            />
          </div>
          <div className="text-center">
            <h4>Successfully Approved</h4>
            <span className="text-sm">You will be notified when <strong>{props.queryModal.customerName}</strong> responds.</span>
          </div>
          <div className="flex justify-between my-3">

          </div>
      </Modal.Body>
    </Modal>
    
  );
};

export default SuccessModal;
