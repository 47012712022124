import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const RetryOnboarding = () => {
  const navigate = useNavigate();

  const removeStripeDetails = async (token) => {
    let deleteStripe = await axios.delete('https://api.uobo.ca/api/dealer/stripe-account', {
      headers: {
        Authorization: token,
      }
    })

    return deleteStripe.data.Data;
  }

  const handleRetry = async () => {
    const token = JSON.parse(localStorage.getItem("token"));

    await removeStripeDetails(token);

    navigate('/dealer-dashboard');
  };

  return (
    <div className="retry-onboarding">
      <h1>Onboarding Issue</h1>
      <p>It seems there was an issue with your onboarding process.</p>

      <div className="mt-10 flex items-center justify-center gap-x-6">
        <button 
          type="button" 
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={handleRetry}
        >
          Retry Onboarding
        </button>
        <button 
          type="button" 
          className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-all duration-300"
          onClick={() => window.location.href = "mailto:support@yourcompany.com"}
        >
          Contact Support
        </button>
      </div>
      
    </div>
  );
};

export default RetryOnboarding;
