// EMIDetailsModal.js
import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const EMIAppointmentDetails = ({ show, handleClose, emiDetails }) => {

  const modalBodyStyle = {
    maxHeight: '400px',  // Adjust the max height as needed
    overflowY: 'auto',
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>EMI Option and Appointment Details</Modal.Title>
      </Modal.Header>
      <Modal.Body style={modalBodyStyle}>
        <h5>EMI Option</h5>
        <p>Bank Name: {emiDetails.customerSelectedEMIOption?.bankName}</p>
        <p>Plan Duration: {emiDetails.customerSelectedEMIOption?.plan?.monthDuration} months</p>
        <p>Amount Due: {emiDetails.customerSelectedEMIOption?.plan?.amountDue}</p>
        <p>Amount Per Week: {emiDetails.customerSelectedEMIOption?.plan?.amountFragment}</p>
        <p>Total Amount: {emiDetails.customerSelectedEMIOption?.plan?.totalAmount}</p>
        <p>Interest Rate: {emiDetails.customerSelectedEMIOption?.plan?.interestRate}%</p>
        
        <h5>Appointment Details</h5>
        <p>Date: {emiDetails.deliveryDate?.date}</p>
        <p>Time: {emiDetails.deliveryDate?.time}</p>

        <h5>Trade In Car Details</h5>
        {emiDetails.customerTradeInDecision === true ? <p>Customer accepted trade in offer</p> : <p>Customer rejected trade in offer</p> }

        <h5>Selected Additional Service</h5>

        {emiDetails.selectedAdditionalService.map((service) => (
          <div>
          <h3 className="text-lg font-semibold">{service.name}</h3>
          <p className="text-gray-600">{service.description}</p>
          { (service.price !== undefined && service.price !== null) &&  
            <p className="text-blue-600 font-bold">${service.price}</p>
          }
          {/* <button className="py-2 px-4 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 mb-8">Add Service</button> */}
        </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EMIAppointmentDetails;
