import React from "react";
import { ReviewData } from "../../../../assets/constants/const";

const CustomerReview = () => {
  const renderReview = () => {
    return ReviewData.map((data) => (
      <div
        className="lg:w-46 mb-5 flex h-48 w-72 flex-col rounded-3xl p-3 text-left shadow-2xl shadow-blue-200 lg:mt-0 lg:h-auto lg:w-80 lg:p-4"
        key={data.id}
      >
        {/* IMAGE / SVG */}
        <div className="h-12 w-24 lg:w-28">
          <img
            src={data.image}
            alt=""
            className="h-full w-full rounded-full object-cover"
          />
        </div>
        <p className="mt-3 h-24 text-sm text-gray-600 lg:h-auto">
          {data.review}
        </p>
        <p className="text-xs text-gray-600">
          - {data.name} from {data.from}
        </p>
      </div>
    ));
  };

  return (
    <div className="flex flex-wrap justify-evenly lg:mt-28">
      {renderReview()}
    </div>
  );
};

export default CustomerReview;
