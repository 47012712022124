import React, { useState } from "react";

import uoboLogo from "../../../../assets/images/android-chrome-512x512.png";

const CarImageStack = (props) => {
  const [totalImages, setTotalImages] = useState(props.images.length);

  const fixedBrokenImage = ({ currentTarget }) => {
    currentTarget.onerror = null;
    currentTarget.src = uoboLogo;
  };

  const imageLayoutForOne = () => (
    <div className="w-11/12 flex">
      <div className="w-full h-52 bg-blue-100 rounded-2xl relative">
        <img
          src={props.images[0]}
          className="object-cover w-full h-full rounded-2xl"
          onError={fixedBrokenImage}
        />
      </div>
    </div>
  );

  const imageLayoutForTwo = () => (
    <div className="w-11/12 flex">
      <div className="w-3/5 h-52 mr-3 bg-blue-100 rounded-l-2xl">
        <img
          src={props.images[0]}
          className="object-cover w-full h-full rounded-2xl"
          onError={fixedBrokenImage}
        />
      </div>
      <div className="w-3/5 h-52 mr-3 bg-blue-100 rounded-r-2xl">
        <img
          src={props.images[1]}
          className="object-cover w-full h-full rounded-2xl"
          onError={fixedBrokenImage}
        />
      </div>
    </div>
  );

  const imageLayoutForThree = () => (
    <div className="w-11/12 flex">
      <div className="w-3/5 h-52 mr-3 bg-blue-100 rounded-l-2xl">
        <img
          src={props.images[0]}
          className="object-cover w-full h-full rounded-2xl"
          onError={fixedBrokenImage}
        />
      </div>
      <div className="w-2/5">
        <div className="bg-yellow-100 h-24 mb-3 rounded-tr-2xl">
          <img
            src={props.images[1]}
            className="object-cover w-full h-full rounded-2xl"
            onError={fixedBrokenImage}
          />
        </div>
        <div className="bg-violet-100 w-full h-24 mb-3 rounded-br-2xl">
          <img
            src={props.images[2]}
            className="object-cover w-full h-full rounded-2xl"
            onError={fixedBrokenImage}
          />
        </div>
      </div>
    </div>
  );

  const imageLayoutForFour = () => (
    <div className="w-11/12 flex">
      <div className="w-3/5 h-52 mr-3 bg-blue-100 rounded-l-2xl">
        <img
          src={props.images[0]}
          className="object-cover w-full h-full rounded-2xl"
          onError={fixedBrokenImage}
        />
      </div>
      <div className="w-2/5">
        <div className="bg-yellow-100 h-24 mb-3 rounded-tr-2xl">
          <img
            src={props.images[1]}
            className="object-cover w-full h-full rounded-2xl"
            onError={fixedBrokenImage}
          />
        </div>
        <div className="flex relative">
          <div className="bg-violet-100 w-1/2 h-24 mr-2">
            <img
              src={props.images[2]}
              className="object-cover w-full h-full rounded-2xl"
              onError={fixedBrokenImage}
            />
          </div>
          <div className="bg-orange-100 w-1/2 h-24 rounded-br-2xl">
            <img
              src={props.images[3]}
              className="object-cover w-full h-full rounded-2xl"
              onError={fixedBrokenImage}
            />
          </div>
          {totalImages > 4 && (
            <div
              className="absolute text-sm lg:text-base px-2 bg-white  text-blue-500 border-2 border-blue-500 rounded-full bottom-3 right-3 cursor-pointer"
              onClick={() => {
                props.inImages();
                props.setImageList(props.images);
              }}
            >
              Show All photos
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      {totalImages === 1 && imageLayoutForOne()}
      {totalImages === 2 && imageLayoutForTwo()}
      {totalImages === 3 && imageLayoutForThree()}
      {totalImages >= 4 && imageLayoutForFour()}
    </React.Fragment>
  );
};

// OLD CODE:

// const CarImageStack = (props) => {
//   return (
//     <div className="w-11/12 flex">
//       <div className="w-3/5 h-52 mr-3 bg-blue-100 rounded-l-2xl"></div>
//       <div className="w-2/5">
//         <div className="bg-yellow-100 h-24 mb-3 rounded-tr-2xl"></div>
//         <div className="flex relative">
//           <div className="bg-violet-100 w-1/2 h-24 mr-2"></div>
//           <div className="bg-orange-100 w-1/2 h-24 rounded-br-2xl"></div>
//           <div
//             className="absolute text-sm lg:text-base px-2 bg-white  text-blue-500 border-2 border-blue-500 rounded-full bottom-3 right-3 cursor-pointer"
//             onClick={}
//           >
//             Show All photos
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

export default CarImageStack;
