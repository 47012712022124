import React from "react";
import { StackData } from "../../../../assets/constants/const";

const Working = () => {
  const renderCards = () => {
    return StackData.map((data) => (
      <div
        className="mx-4 mt-10 flex max-h-36 w-30 flex-col items-center justify-center rounded-xl p-1 shadow-2xl shadow-blue-200 sm:mt-5 md:mb-10 md:max-h-64 md:w-52 lg:mt-0"
        key={data.id}
      >
        {/* IMAGE / SVG */}
        <img src={data.image} alt="" className="mt-2 h-16 md:h-24 lg:h-28" />
        <p className="mt-4 text-center text-xs font-bold text-gray-700 sm:mt-3 md:text-sm">
          {data.name}
        </p>
        {/* <p className="text-center text-xs">{data.content}</p> */}
      </div>
    ));
  };

  return (
    <div className="mt-8 flex flex-col md:mt-16 lg:mt-36 ">
      <div className="ml-10 w-8/12 font-bold lg:ml-52">
        <p className="mb-2 text-base font-semibold text-gray-700 md:text-lg lg:text-xl">
          About us
        </p>
        <span className="font-semibold-700 text-xl md:text-2xl lg:text-3xl">
          We Believe In A Future That Benefits
        </span>{" "}
        <span className="font-semibold-700 text-xl text-blue-500 md:text-2xl lg:text-3xl">
          All.
        </span>
      </div>
      {/* STACK */}
      <div className="rounded-xl border-32 border-transparent lg:mt-6">
        <div className="flex flex-wrap justify-center rounded-xl">
          {/* CARDS */}
          {renderCards()}
        </div>
      </div>
    </div>
  );
};

export default Working;
