import { React, useState } from "react";

import FloatingLabel from "react-bootstrap/FloatingLabel";
import { ErrorMessage, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import isEmpty from "lodash/isEmpty";
import * as Yup from "yup";
import axios from "axios";

import FieldErrorMessage from "../../../common/error";
import api from "../../../common/api/api";

import "../../../../assets/scss/custom.scss";
import { messaging, getToken } from "../../../../firebase";
import Mixpanel from "../../setting/container/mixpanel";

const DealerSigninForm = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const dealerSignin = async (dealer, token) => {
    setLoading(true);
    localStorage.setItem("dealerSignin", JSON.stringify(dealer));
    localStorage.setItem("token", JSON.stringify(token));
    localStorage.setItem("otpVerified", false);

    try {
      await axios.post(
        "https://verify.twilio.com/v2/Services/VA460f250497d83f25577f83fc2ccc80a6/Verifications",
        {
          To: "+1"+dealer.phoneNumber['number'],
          Channel: "sms",
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          auth: {
            username: process.env.REACT_APP_TWILLIO_AUTH_USERNAME,
            password: process.env.REACT_APP_TWILLIO_AUTH_PASSWORD,
          },
        }
      );

      let fcmToken = await requestNotificationPermission();

      try {
        const response = await api.put(
          "api/dealer/fcmToken",
          {
            dealerId: dealer._id,
            fcmToken: fcmToken,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } catch (error) {
        toast.error(error.response.data.Message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      // localStorage.setItem("fcmToken", fcmToken);
      navigate("/dealer-signin-otp");
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
    }
  };

  const requestNotificationPermission = async () => {
    try {
      const currentToken = await getToken(messaging, { vapidKey: 'BOLvkFpT5cXyLOn1sR0Sj9uH2l6OPtgMYG21s1pN-EV_DWLeRtli5SfIIiGBVKXHSoF5wS08ecJXnNaXfEIgy-8' });
      if (currentToken) {
        return currentToken;
      } else {
        console.warn('No registration token available. Request permission to generate one.');
      }
    } catch (error) {
      console.error('An error occurred while retrieving token. ', error);
    }
  };

  const DealerSigninFormValidationSchema = () =>
    Yup.object().shape({
      phoneNumber: Yup.string()
        .required("Please enter phone number.")
        .matches(/^\d+$/, "Phone number must be a Numeric Value.")
        .max(10, "Phone number must contains at max 10 digits.")
        .min(10, "Phone number must contains atleast 10 digits."),
    });

  return (
    <div className="w-4/5 md:w-3/5 lg:w-2/5 h-98 overflow-auto tracking-wide flex flex-col justify-center">
      <p className="text-bold font-bold text-2xl mb-1 text-center tracking-widest">
        LOGIN
      </p>
      <p className="text-slate-400 text-center">to access your dashboard</p>

      <Formik
        initialValues={{
          phoneNumber: "",
        }}
        onSubmit={async (values) => {
          setLoading(true);
          try {
            const response = await api.post(
              "api/dealer/login",
              {
                phoneNumber: values.phoneNumber,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            const data = response.data.Data;
            dealerSignin(data.dealer, data.token);
            setLoading(false);

            Mixpanel.identify(data?.dealer?._id);
            Mixpanel.track('Successful login');
            Mixpanel.people.set({
              $first_name: data?.dealer?.firstName,
              $last_name: data?.dealer?.lastName,
            });

          } catch (error) {
            Mixpanel.track('Unsuccessful login');
            toast.error(error.response.data.Message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
          }
        }}
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={DealerSigninFormValidationSchema}
      >
        {({ handleSubmit, setFieldValue, values, errors }) => (
          <form onSubmit={handleSubmit} className="px-2">
            {/* PHONE NUMBER */}
            <div className={!!errors.phoneNumber ? "mb-1 flex" : "mb-0 flex"}>
              <div className="w-14 sm:w-2/12 ">
                <Form.Control
                  type="text"
                  disabled={true}
                  value={"+1"}
                  className="countryCodeInput"
                />
              </div>
              <FloatingLabel
                className={
                  !!errors.phoneNumber ? "mb-0 sm:w-11/12" : "mb-3 sm:w-11/12"
                }
                // controlId="floatingInput"
                label="Phone Number"
              >
                <Form.Control
                  type="text"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  id="phoneNumber"
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setFieldValue(e.target.name, e.target.value);
                    }
                  }}
                  value={values.phoneNumber}
                  disabled={values.phoneNumber.length > 10}
                  className="phoneNumberInput"
                />
              </FloatingLabel>
            </div>

            <ErrorMessage name="phoneNumber" component={FieldErrorMessage} />

            {/* LOGIN BUTTON */}
            <button
              type="submit"
              disabled={!isEmpty(errors) || loading}
              className={`${
                isEmpty(errors) ? "bg-blue-500" : "bg-gray-300 mt-2"
              } text-xl text-white  p-3 rounded-2xl w-full`}
            >
              {loading ? "Loading..." : "Login"}
            </button>
          </form>
        )}
      </Formik>
      <div className="text-center mt-3">
        New to Uobo? <a href="/dealer-signup">Join Now</a>
      </div>
    </div>
  );
};

export default DealerSigninForm;
