import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import { CheckMark, DownArrow, Filter } from "../../../../assets/icons/icons";
import { CarList } from "../../../../assets/constants/const";

import "../../../../assets/scss/custom.scss";

const PrettoSlider = styled(Slider)({
  color: "#3c82f6",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&::before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 60,
    height: 60,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#3c82f6",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&::before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

const InventoryFilterModal = (props) => {
  const [selectedBodyTypeIndex, setSelectedBodyTypeIndex] = useState([]);
  const [selectedMakeIndex, setSelectedMakeIndex] = useState([]);
  const [selectedModelIndex, setSelectedModelIndex] = useState([]);

  return (
    <div>
      <Modal show={props.filterModal} onHide={props.handleModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="flex items-center">
            <Filter className="mr-2" /> Filter
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          {/* Location */}
          <div className="mb-3">
            <div>Your Location</div>
          </div>

          {/* Price Range */}
          <div className="flex justify-between ">
            <div>Price Range</div>
            <div className="text-blue-500">
              ${props.range[0]} - ${props.range[1]}
            </div>
          </div>
          <div className="mb-3">
            <PrettoSlider
              valueLabelDisplay="auto"
              min={1000}
              max={1000000}
              value={props.range}
              step={500}
              onChange={props.handleChange}
            />
          </div>

          {/* BODY TYPE */}
          <Accordion>
            <AccordionSummary
              expandIcon={<DownArrow className="fill-blue-500" />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <div className="flex justify-between w-100 ">
                <div>Body Type</div>
                <div className="mr-2">
                  {selectedBodyTypeIndex
                    .map((index) => CarList[index].bodyType)
                    .join(", ")}
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {CarList.map((car, index) => {
                const isSelectedIndex = selectedBodyTypeIndex.includes(index);

                return (
                  <div
                    className="flex justify-between mb-2 cursor-pointer p-1"
                    onClick={() => {
                      setSelectedBodyTypeIndex((prevSelectedBodyTypeIndex) => {
                        if (isSelectedIndex) {
                          return prevSelectedBodyTypeIndex.filter(
                            (itemIndex) => itemIndex !== index
                          );
                        } else {
                          return [...prevSelectedBodyTypeIndex, index];
                        }
                      });
                    }}
                    key={car.id}
                  >
                    <div>{car.bodyType}</div>

                    {isSelectedIndex ? (
                      <CheckMark
                        pathFill="#fff"
                        polygonFill="#2583ef"
                        height="25"
                        width="25"
                      />
                    ) : (
                      <CheckMark
                        pathFill="#fff"
                        polygonFill="#adb8c8"
                        height="25"
                        width="25"
                      />
                    )}
                  </div>
                );
              })}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<DownArrow className="fill-blue-500" />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <div className="flex justify-between w-100 ">
                <div>Make (21)</div>
                <div className="mr-2">
                  {selectedMakeIndex
                    .map((index) => CarList[index].make)
                    .join(", ")}
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {CarList.map((car, index) => {
                const isSelectedIndex = selectedMakeIndex.includes(index);

                return (
                  <div
                    className="flex justify-between mb-2 cursor-pointer p-1"
                    onClick={() => {
                      setSelectedMakeIndex((prevSelectedMakeIndex) => {
                        if (isSelectedIndex) {
                          return prevSelectedMakeIndex.filter(
                            (itemIndex) => itemIndex !== index
                          );
                        } else {
                          return [...prevSelectedMakeIndex, index];
                        }
                      });
                    }}
                    key={car.id}
                  >
                    <div>{car.make}</div>

                    {isSelectedIndex ? (
                      <CheckMark
                        pathFill="#fff"
                        polygonFill="#2583ef"
                        height="25"
                        width="25"
                      />
                    ) : (
                      <CheckMark
                        pathFill="#fff"
                        polygonFill="#adb8c8"
                        height="25"
                        width="25"
                      />
                    )}
                  </div>
                );
              })}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<DownArrow className="fill-blue-500" />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <div className="flex justify-between w-100 ">
                <div>Models (92)</div>
                <div className="mr-2">
                  {selectedModelIndex
                    .map((index) => CarList[index].model)
                    .join(", ")}
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {CarList.map((car, index) => {
                const isSelectedIndex = selectedModelIndex.includes(index);

                return (
                  <div
                    className="flex justify-between mb-2 cursor-pointer p-1"
                    onClick={() => {
                      setSelectedModelIndex((prevSelectedModelIndex) => {
                        if (isSelectedIndex) {
                          return prevSelectedModelIndex.filter(
                            (itemIndex) => itemIndex !== index
                          );
                        } else {
                          return [...prevSelectedModelIndex, index];
                        }
                      });
                    }}
                    key={car.id}
                  >
                    <div>{car.model}</div>
                    {isSelectedIndex ? (
                      <CheckMark
                        pathFill="#fff"
                        polygonFill="#2583ef"
                        height="25"
                        width="25"
                      />
                    ) : (
                      <CheckMark
                        pathFill="#fff"
                        polygonFill="#adb8c8"
                        height="25"
                        width="25"
                      />
                    )}
                  </div>
                );
              })}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<DownArrow className="fill-blue-500" />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              Trims (303)
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<DownArrow className="fill-blue-500" />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              <div className="flex justify-between w-100">
                <div>Year</div>
                <div className="text-blue-500 mr-2">
                  {props.year[0]} - {props.year[1]}
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="mb-3">
                <PrettoSlider
                  valueLabelDisplay="auto"
                  min={1998}
                  max={2024}
                  step={1}
                  value={props.year}
                  onChange={props.handleYearChange}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        </Modal.Body>
        <Modal.Footer className="filerModalFooter">
          <div>2 selected</div>
          <button
            className="bg-blue-500 text-lg text-white text-center py-1 rounded-3xl w-48 float-right mb-10 no-underline"
            onClick={props.handleModalClose}
          >
            Apply filters
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default InventoryFilterModal;
