import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import { sendAppointmentsToCustomer } from "../../../../api/orders/helper";
import SendQueryModal from "../../../common/modal/sendQueryModal";
import SuccessModal from "../../../common/modal/successModal";
//import LicenceFr from "../../../../assets/images/licenceFr.png";
import { sendFinanceAppointmentsToCustomer } from "../../../../api/orders/helper";
import ConfirmationModal from "./confirmAvailablePopUp";

const CustomerDetails = (props) => {

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const orderDetails = props?.ordersData?.orders[0];
  const customerDetails = props?.ordersData?.orders[0]?.customerId;
  const currentAddress = props?.ordersData?.orders[0]?.address[0];
  const customerMortgageDetails = customerDetails.houseOwnership;
  let queryModal;

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleSuccessOpenModal = () => {
    setSuccess(true);
  };

  const handleSuccessCloseModal = () => {
    setSuccess(false);
    props.ordersData.setNewOrderDetails(1);
    setOpen(0);
  };

  const carAvailable = () => {
    //API will call here
    let body = {
      financeId: props.ordersData.orders[0]._id,
      status: 'DealerSentAvailability',
      confirmAvailabilty: true,
      tradeInCarValue: "0",
      dealerEstimatedTradeInValue: "0",
      additionalDocuments: [],
      appointments: [],
      EMIOptions: [],
      billOfSale: ''
    }

    const token = JSON.parse(localStorage.getItem("token"));
    sendFinanceAppointmentsToCustomer(body, token).then((response) => {
      console.log(response);

      props.ordersData.setNewOrderDetails(1);

      navigate("/dealer-dashboard");

    }, (err) => {
      console.log('Error sending appointments', err);
    });
  };

  const carNotAvailable = () => {
    setConfirmOpen(true);
  };

  const handleConfirm = () => {
    setConfirmOpen(false);

    if(props.ordersData.orders[0].category === 'Cash') {
      let body = {
        financeId: props.ordersData.orders[0]._id,
        status: 'CarNotAvailable',
        confirmAvailabilty: false,
        tradeInCarValue: "0",
        additionalDocuments: [],
        appointments: [],
        billOfSale: ''
      }
  
      const token = JSON.parse(localStorage.getItem("token"));
      sendAppointmentsToCustomer(body, token).then((response) => {
        console.log(response);
  
        props.ordersData.setNewOrderDetails(1);
  
        navigate("/dealer-dashboard");
  
      }, (err) => {
        console.log('Error sending appointments', err);
      });
    }
    else if(props.ordersData.orders[0].category === 'Fix') {
      let body = {
        financeId: props.ordersData.orders[0]._id,
        status: 'CarNotAvailable',
        confirmAvailabilty: false,
        tradeInCarValue: "0",
        additionalDocuments: [],
        appointments: [],
        EMIOptions: [],
        billOfSale: ''
      }
  
      const token = JSON.parse(localStorage.getItem("token"));
      sendFinanceAppointmentsToCustomer(body, token).then((response) => {
        console.log(response);
  
        props.ordersData.setNewOrderDetails(1);
  
        navigate("/dealer-dashboard");
  
      }, (err) => {
        console.log('Error sending appointments', err);
      });
    }
    
  };

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const closeFullScreenImage = () => {
    setSelectedImage(null);
  };

  let isCustomerDetailAvailable = false;

  if(props.ordersData.orders.length > 0) {
    isCustomerDetailAvailable = true;

     queryModal = {
      open: open,
      success: success,
      handleCloseModal: handleCloseModal,
      handleSuccessOpenModal: handleSuccessOpenModal,
      handleSuccessCloseModal: handleSuccessCloseModal,
      customerName: props?.ordersData?.orders[0]?.customerId?.firstName + ' ' + props?.ordersData?.orders[0]?.customerId?.lastName,
      orderId: props?.ordersData?.orders[0]?._id,
      category: props?.ordersData?.orders[0]?.category
    };
  } else {
      queryModal = {
      open: open,
      success: success,
      handleCloseModal: handleCloseModal,
      handleSuccessOpenModal: handleSuccessOpenModal,
      handleSuccessCloseModal: handleSuccessCloseModal,
      customerName: '',
      orderId:0,
      category: ''
    };
  }
  let nextStep = 1;

  if(props.ordersData.orders.length > 0) {
    if(props?.ordersData?.orders[0]?.isTradeinCarAvilable === true) {
      nextStep = 2;
    }
  }
  // console.log('--NEXT STEP---', props?.ordersData?.orders[0]);
  // console.log('--NEXT STEP---', nextStep);

  return (
    <div className="w-3/5 ml-3">
      <div className="border-2 border-blue-500 rounded-lg p-3 h-96 overflow-y-auto">
        <div className="text-xl">Customer details</div>
        {isCustomerDetailAvailable === true && ( 
          <>
            <div className="flex justify-between my-2">
              <div className="text-slate-400">Customer Name</div>
              <div className="text-lg">{props.ordersData?.orders[0]?.firstName} {props.ordersData?.orders[0]?.lastName}</div>
            </div>
            <div className="text-xl mt-3">Documents Uploaded</div>
            <div className="flex justify-between flex-wrap">
              {props.ordersData?.orders[0]?.documents.map((document) => (
                <div className="my-2 mr-2">
                  <div className="text-blue-500">{document?.category}</div>
                  <img
                    className="h-24 w-60 object-cover rounded-lg border-2 border-blue-500 p-1 cursor-pointer"
                    src={document?.file}
                    onClick={() => handleImageClick(document?.file)}
                    alt="LicenceFr" />
                </div>
              ))}
            </div>
            {props.ordersData.orders[0].category === 'Fix' && (
              <>
                <div className="text-xl">Mortgage Details</div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Mortgage Type</div>
                  <div className="text-lg">
                    {customerMortgageDetails?.ownershipType !== undefined && customerMortgageDetails?.ownershipType !== '' ? customerMortgageDetails?.ownershipType : 'NA'}
                  </div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Monthly Rent</div>
                  <div className="text-lg">
                    {orderDetails?.monthlyRentAmount !== undefined && orderDetails?.monthlyRentAmount !== '' ? orderDetails?.monthlyRentAmount : '0'}
                  </div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Mortgage Bank</div>
                  <div className="text-lg">{customerMortgageDetails?.mortgageDetails?.bank !== undefined && customerMortgageDetails?.mortgageDetails?.bank !== '' ? customerMortgageDetails?.mortgageDetails?.bank : 'NA'}</div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Mortgage Amount</div>
                  <div className="text-lg">{customerMortgageDetails?.mortgageDetails?.amount !== undefined && customerMortgageDetails?.mortgageDetails?.amount !== '' ? customerMortgageDetails?.mortgageDetails?.amount : 'NA'}</div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Mortgage Market Value</div>
                  <div className="text-lg">{customerMortgageDetails?.mortgageDetails?.marketValue !== undefined && customerMortgageDetails?.mortgageDetails?.marketValue !== '' ? customerMortgageDetails?.mortgageDetails?.marketValue : 'NA'}</div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Monthly Mortgage</div>
                  <div className="text-lg">{customerMortgageDetails?.mortgageDetails?.monthlyMortgage !== undefined && customerMortgageDetails?.mortgageDetails?.monthlyMortgage !== '' ? customerMortgageDetails?.mortgageDetails?.monthlyMortgage : 'NA'}</div>
                </div>

                <div className="text-xl">Personal Information</div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Salutation</div>
                  <div className="text-lg">{customerDetails?.salutation !== undefined && customerDetails?.salutation !== '' ? customerDetails?.salutation : 'NA'}</div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Contact</div>
                  <div className="text-lg">{customerDetails?.contact?.number !== undefined && customerDetails?.contact?.number !== '' ? customerDetails?.contact?.number : 'NA'}</div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Married Status</div>
                  <div className="text-lg">{customerDetails?.marriedStatus !== undefined && customerDetails?.marriedStatus !== '' ? customerDetails?.marriedStatus : 'NA'}</div>
                </div>
              </>
            )}

            <div className="flex justify-between my-2">
              <div className="text-slate-400">DOB</div>
              <div className="text-lg">{orderDetails?.DOB !== undefined && orderDetails?.DOB !== '' ? orderDetails?.DOB : 'NA'}</div>
            </div>

            <div className="flex justify-between my-2">
              <div className="text-slate-400">Gender</div>
              <div className="text-lg">{orderDetails?.gender !== undefined && orderDetails?.gender !== '' ? orderDetails?.gender : 'NA'}</div>
            </div>

            <div className="text-xl">Current Address</div>

            <div className="flex justify-between my-2">
              <div className="text-slate-400">Address</div>
              <div className="text-lg">{currentAddress?.address !== undefined && currentAddress.address !== '' ? currentAddress.address : 'NA'}</div>
            </div>

            <div className="flex justify-between my-2">
              <div className="text-slate-400">Postalcode</div>
              <div className="text-lg">{currentAddress?.postalCode !== undefined && currentAddress.postalCode !== '' ? currentAddress.postalCode : 'NA'}</div>
            </div>

            <div className="flex justify-between my-2">
              <div className="text-slate-400">Stay Duration</div>
              <div className="text-lg">
                {currentAddress?.stayDuration?.year !== undefined && currentAddress.stayDuration?.year !== '' ? 
                  currentAddress.stayDuration?.year + ' year ' : 'NA'}

                {currentAddress?.stayDuration?.month !== undefined && currentAddress.stayDuration?.month !== '' ? 
                  currentAddress.stayDuration?.month + ' month ' : 'NA'}
              </div>
            </div>

            {props.ordersData.orders[0].category === 'Fix' && (
              <>
                <div className="text-xl">Current Employment</div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Employment Status</div>
                  <div className="text-lg">{customerDetails?.currentEmployment[0]?.status !== undefined && customerDetails?.currentEmployment[0]?.status !== '' ? customerDetails?.currentEmployment[0]?.status : 'NA'}</div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Occupation</div>
                  <div className="text-lg">{customerDetails?.currentEmployment[0]?.occupation !== undefined && customerDetails?.currentEmployment[0]?.occupation !== '' ? customerDetails?.currentEmployment[0]?.occupation : 'NA'}</div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Employment Type</div>
                  <div className="text-lg">{customerDetails?.currentEmployment[0]?.type !== undefined && customerDetails?.currentEmployment[0]?.type !== '' ? customerDetails?.currentEmployment[0]?.type : 'NA'}</div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Employer</div>
                  <div className="text-lg">{customerDetails?.currentEmployment[0]?.employer !== undefined && customerDetails?.currentEmployment[0]?.employer !== '' ? customerDetails?.currentEmployment[0]?.employer : 'NA'}</div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Work Location</div>
                  <div className="text-lg">
                    {customerDetails?.currentEmployment[0]?.workLocation !== undefined && 
                      customerDetails?.currentEmployment[0]?.workLocation !== '' ? 
                      `${customerDetails?.currentEmployment[0]?.workLocation?.address} ${customerDetails?.currentEmployment[0]?.workLocation?.city} ${customerDetails?.currentEmployment[0]?.workLocation?.postalCode} ${customerDetails?.currentEmployment[0]?.workLocation?.province}` : 
                      'NA'}
                  </div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Job Duration</div>
                  <div className="text-lg">
                    {customerDetails?.currentEmployment[0]?.jobDuration?.year !== undefined && 
                    customerDetails?.currentEmployment[0]?.jobDuration?.year !== '' ? 
                    customerDetails?.currentEmployment[0]?.jobDuration?.year + ' year ' : 'NA'}

                    {customerDetails?.currentEmployment[0]?.jobDuration?.month !== undefined && 
                    customerDetails?.currentEmployment[0]?.jobDuration?.month !== '' ? 
                    customerDetails?.currentEmployment[0]?.jobDuration?.month + ' month ' : 'NA'}
                  </div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Gross Income</div>
                  <div className="text-lg">
                    {customerDetails?.grossIncome?.income !== undefined && customerDetails?.grossIncome?.income !== '' ? 
                      customerDetails?.grossIncome?.income : 'NA'}
                  </div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Gross Income Duration</div>
                  <div className="text-lg">
                    {customerDetails?.grossIncome?.perMQY !== undefined && customerDetails?.grossIncome?.perMQY !== '' ? 
                      customerDetails?.grossIncome?.perMQY : 'NA'}
                  </div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Other Income Type</div>
                  <div className="text-lg">
                    {customerDetails?.otherIncomeSource?.type !== undefined && customerDetails?.otherIncomeSource?.type !== '' ? 
                      customerDetails?.otherIncomeSource?.type : 'NA'}
                  </div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Other Income</div>
                  <div className="text-lg">
                    {customerDetails?.otherIncomeSource?.income !== undefined && customerDetails?.otherIncomeSource?.income !== '' ? 
                      customerDetails?.otherIncomeSource?.income : 'NA'}
                  </div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Other Income Duration</div>
                  <div className="text-lg">
                    {customerDetails?.otherIncomeSource?.perMQY !== undefined && customerDetails?.otherIncomeSource?.perMQY !== '' ? 
                      customerDetails?.otherIncomeSource?.perMQY : 'NA'}
                  </div>
                </div>

                <div className="text-xl">Down Payment Details</div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Max Down Payment</div>
                  <div className="text-lg">{props.ordersData?.orders[0]?.maxDownPayment !== undefined && props.ordersData?.orders[0]?.maxDownPayment !== '' ? props.ordersData?.orders[0]?.maxDownPayment : 'NA'}</div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Desired Down Payment</div>
                  <div className="text-lg">{props.ordersData?.orders[0]?.desiredDownPayment !== undefined && props.ordersData?.orders[0]?.desiredDownPayment !== '' ? props.ordersData?.orders[0]?.desiredDownPayment : 'NA'}</div>
                </div>
              </>
            )}

            {/* {props.ordersData.orders[0].category === 'Cash' && (
              <>
                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Customer Name cash</div>
                  <div className="text-lg">{props.ordersData.orders[0].firstName} {props.ordersData.orders[0].lastName}</div>
                </div>
              </>
            )} */}
          </>
        )}
      </div>
      {props.ordersData.orders[0].category === 'Fix' && (
        props.ordersData.orders[0].isTradeinCarAvilable === true ?
        <div
        className="bg-blue-500 text-slate-50 border-2 border-transparent rounded-lg text-center w-full py-2 my-3 
      cursor-pointer hover:bg-white hover:border-blue-500 hover:text-blue-500 "
        onClick={() => props.ordersData.setStep(nextStep)}
      >
        Confirm Availability
      </div>
      : <div
      className="bg-blue-500 text-slate-50 border-2 border-transparent rounded-lg text-center w-full py-2 my-3 
    cursor-pointer hover:bg-white hover:border-blue-500 hover:text-blue-500 "
      onClick={() => carAvailable()}
    >
      Confirm Availability
    </div>
      )}
      {props.ordersData.orders[0].category === 'Cash' && (
        <div
        className="bg-blue-500 text-slate-50 border-2 border-transparent rounded-lg text-center w-full py-2 my-3 
      cursor-pointer hover:bg-white hover:border-blue-500 hover:text-blue-500 "
        onClick={() => props.ordersData.setStep(nextStep)}
      >
        Confirm Availability
      </div>
      )}
      <div className="text-center text-red-400 cursor-pointer hover:bg-red-400 hover:text-white rounded-lg py-2"
      onClick={() => carNotAvailable()}>
        Not Available
      </div>
      <div
            className="text-blue-500 border rounded-lg text-center w-full py-2 my-3 cursor-pointer hover:bg-blue-500  hover:text-white "
            onClick={() => handleOpenModal()}
          >
            Need more Information?
          </div>
      <SendQueryModal queryModal={queryModal} handleCloseModal={handleCloseModal} />
      <SuccessModal queryModal={queryModal} handleCloseModal={handleCloseModal} />

      {selectedImage && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
          <img
            src={selectedImage}
            alt="Full Screen"
            className="max-w-full max-h-full"
            onClick={closeFullScreenImage}
          />
          <button
            className="absolute top-4 right-4 text-white text-xl bg-gray-800 p-2 rounded-full"
            onClick={closeFullScreenImage}
          >
            &times;
          </button>
        </div>
      )}
      
      <ConfirmationModal
        isOpen={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={handleConfirm}
      />
    </div>
  );
};

export default CustomerDetails;
