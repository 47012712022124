import React from "react";
import Dropdown from "react-bootstrap/Dropdown";

const EditGeneralInfo = () => {
  return (
    <>
      <div className="text-xl mt-4 mb-5">Vehicle General Details</div>
      <div className="flex items-center mb-3">
        <div className="w-44 text-slate-400">Make</div>
        <Dropdown style={{ width: "100%" }}>
          <Dropdown.Toggle
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#dbe9fe",
              borderColor: "transparent",
              color: "black",
            }}
          >
            Dropdown
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ width: "100%" }}>
            <Dropdown.Item eventKey="1">Action 1</Dropdown.Item>
            <Dropdown.Item eventKey="2">Action 2</Dropdown.Item>
            <Dropdown.Item eventKey="3">Action 3</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {/* Model */}
      <div className="flex items-center mb-3">
        <div className="w-44 text-slate-400">Model</div>
        <Dropdown style={{ width: "100%" }}>
          <Dropdown.Toggle
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#dbe9fe",
              borderColor: "transparent",
              color: "black",
            }}
          >
            Dropdown
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ width: "100%" }}>
            <Dropdown.Item eventKey="1">Action 1</Dropdown.Item>
            <Dropdown.Item eventKey="2">Action 2</Dropdown.Item>
            <Dropdown.Item eventKey="3">Action 3</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {/* Year */}
      <div className="flex items-center mb-3">
        <div className="w-44 text-slate-400">Year</div>
        <Dropdown style={{ width: "100%" }}>
          <Dropdown.Toggle
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#dbe9fe",
              borderColor: "transparent",
              color: "black",
            }}
          >
            Dropdown
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ width: "100%" }}>
            <Dropdown.Item eventKey="1">Action 1</Dropdown.Item>
            <Dropdown.Item eventKey="2">Action 2</Dropdown.Item>
            <Dropdown.Item eventKey="3">Action 3</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {/* Body Type */}
      <div className="flex items-center mb-3">
        <div className="w-44 text-slate-400">Body Type</div>
        <Dropdown style={{ width: "100%" }}>
          <Dropdown.Toggle
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#dbe9fe",
              borderColor: "transparent",
              color: "black",
            }}
          >
            Dropdown
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ width: "100%" }}>
            <Dropdown.Item eventKey="1">Action 1</Dropdown.Item>
            <Dropdown.Item eventKey="2">Action 2</Dropdown.Item>
            <Dropdown.Item eventKey="3">Action 3</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {/* Trim */}
      <div className="flex items-center mb-3">
        <div className="w-44 text-slate-400">Trim</div>
        <Dropdown style={{ width: "100%" }}>
          <Dropdown.Toggle
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#dbe9fe",
              borderColor: "transparent",
              color: "black",
            }}
          >
            Dropdown
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ width: "100%" }}>
            <Dropdown.Item eventKey="1">Action 1</Dropdown.Item>
            <Dropdown.Item eventKey="2">Action 2</Dropdown.Item>
            <Dropdown.Item eventKey="3">Action 3</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {/* Location */}
      <div className="flex items-center mb-3">
        <div className="w-44 text-slate-400">Location</div>
        <input
          type="text"
          name="location"
          id="location"
          className="border w-full pl-3 py-2 rounded bg-blue-100"
          placeholder="Location"
        />
      </div>
      {/* Milage */}
      <div className="flex items-center mb-3">
        <div className="w-44 text-slate-400">Milage</div>
        <input
          type="text"
          name="milage"
          id="milage"
          className="border w-full pl-3 py-2 rounded bg-blue-100"
          placeholder="Milage"
        />
      </div>
    </>
  );
};

export default EditGeneralInfo;
