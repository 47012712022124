import { Navigate, useLocation } from "react-router-dom";

const isAuthenticated = () => {
  const dealerSignin = localStorage.getItem("dealerSignin");
  const token = localStorage.getItem("token");
  const otpVerified = localStorage.getItem("otpVerified") === "true";
  const accountCreated = localStorage.getItem("accountCreated") === "true";

  return (
    (dealerSignin && token && otpVerified) ||
    (dealerSignin && token && accountCreated)
  );
};

export const ProtectedRoute = ({ children }) => {
  const location = useLocation();

  const isAuthenticatedDealer = isAuthenticated();

  // If otp is not verified and user wants to go on signin-otp page then -
  // redirect to otp page otherwise signin page
  if (location.pathname.includes("otp")) {
    if (localStorage.getItem("otpVerified") === "false") {
      return children;
    }
    return <Navigate to="/dealer-signin" />;
  }

  // If dealer logged in and try to open signin or signup page, then -
  // dealer not able to open those page (redirect to dashboard)
  if (
    location.pathname.includes("dealer-signin") ||
    location.pathname.includes("dealer-signup")
  ) {
    if (isAuthenticatedDealer) {
      return <Navigate to="/dealer-dashboard" />;
    }
    return children;
  }

  // Check if dealer is logged in
  if (isAuthenticatedDealer) {
    return children;
  }

  return <Navigate to="/" />;
};
