import React from "react";
import { DownArrow } from "../../../../assets/icons/icons";
//import { Location } from "../../../../assets/icons/icons";

const TradeCarInfo = (props) => {

  let isCarDetailAvailable = false;

  if(props.ordersData.orders.length > 0) {
    isCarDetailAvailable = true;
  }
  const backPage = () => {
    props.ordersData.setStep(0);
  }

  return (
    <div className="w-2/5 mr-3">
      <div
          className="flex items-center text-xl text-blue-500 mt-2 no-underline w-max cursor-pointer"
          onClick={() => backPage()}
        >
          <DownArrow className="transform rotate-90 fill-blue-500" />
          Back
        </div>
      {/* <div className="text-xl">TradeIn Car details</div> */}
      {/* {isCarDetailAvailable === true && (
        <><img
          className="h-52 w-full object-cover rounded-lg"
          src={props?.ordersData?.orders[0]?.tradeDetails?.photos[0]}
          alt="AudiA4" /><div className="flex justify-between">
            <div>{props?.ordersData?.orders[0]?.tradeDetails?.YearMakeModel}</div>
            <div>${props?.ordersData?.orders[0]?.tradeDetails?.amount}</div>
          </div><div className="flex justify-between">
            <div className="text-slate-400">Year</div>
            <div>{props?.ordersData?.orders[0]?.tradeDetails?.YearMakeModel?.split(' ')[0]}</div>
          </div><div className="flex justify-between my-2">
            <div className="text-slate-400">Milage</div>
            <div>{props?.ordersData?.orders[0]?.tradeDetails?.odometerReading}</div>
          </div><div className="flex justify-between">
            <div className="text-slate-400">Transmission</div>
            <div>{props?.ordersData?.orders[0]?.tradeDetails?.transmission}</div>
          </div></>
      )} */}
      
    </div>
   
  );
};

export default TradeCarInfo;
