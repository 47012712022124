import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import { sendAppointmentsToCustomer, sendFinanceAppointmentsToCustomer } from "../../../../api/orders/helper";
import "../../../../assets/scss/calendar.scss";
import { DownArrow } from "../../../../assets/icons/icons";
import ScheduleList from "./scheduleList";
import SuccessModal from "../../../common/modal/successModal";
import OpenFinanceOrder from "../../../common/modal/openFinanceOrder";

const ConfirmDateTime = (props) => {
  console.log('---Confirm date PROPS---', props);

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [appointmentsArray, setAppointments] = useState([]);
  const [dates, setDates] = useState([]);
  const [financeOrder, setFinanceOrder] = useState([]);
  const [financeOrderStep, setFinanceOrderStep] = useState(false);
  const [submitBtnColor, setSubmitBtnColor] = useState("grey");

  const handleCloseModal = () => {
    setOpen(false);
    props.ordersData.setNewOrderDetails(1);
    props.ordersData.setStep(0);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const openFinanceOrder = () => {
    setFinanceOrderStep(true);
  };

  const closeFinanceOrder = () => {
    setFinanceOrderStep(false);
    props.ordersData.setNewOrderDetails(1);
    props.ordersData.setStep(0);
    navigate("/dealer-dashboard");
  };

  const handleAppointments = (event) => {
    console.log('-----APPOINTMENT ARRAY BEFORE----', appointmentsArray);
    console.log('Checked Item detail ', event.target.value);
    console.log('Event checked', event.target.checked);
    console.log('---DATES---', dates);

    if (appointmentsArray.length === 0) {
      appointmentsArray.push({
        date: value.toLocaleDateString("en-GB"),
        time: [event.target.value]
      });
      dates.push(value.toLocaleDateString("en-GB"));
    } else {
      for (let i = 0; i < appointmentsArray.length; i++) {
        if (dates.indexOf(value.toLocaleDateString("en-GB")) !== -1) {
          if (appointmentsArray[i].date === value.toLocaleDateString("en-GB")) {
            if (event.target.checked === true) {
              if (appointmentsArray[i].time.indexOf(event.target.value) === -1) {
                appointmentsArray[i].time.push(event.target.value);
              }
            } else {
              if (appointmentsArray[i].time.indexOf(event.target.value) !== -1) {
                let index = appointmentsArray[i].time.indexOf(event.target.value);
                appointmentsArray[i].time.splice(index, 1);
                if (appointmentsArray[i].time.length === 0) {
                  let dateIndex = dates.indexOf(value.toLocaleDateString("en-GB"));
                  dates.splice(dateIndex, 1);
                }
              }
            }
          }
        } else {
          appointmentsArray.push({
            date: value.toLocaleDateString("en-GB"),
            time: [event.target.value]
          });
          dates.push(value.toLocaleDateString("en-GB"));
        }
      }
    }
    console.log('---ARRAY---', appointmentsArray);

    if (appointmentsArray.length > 0) {
      setSubmitBtnColor("blue");
    } else {
      setSubmitBtnColor("grey");
    }
  };

  let tradeInCarValue = "0";
  if (props.ordersData.orders[0].isTradeinCarAvilable !== false) {
    tradeInCarValue = localStorage.getItem("dealerEvaluation");
  }
  console.log('---->>>>TRADE IN CAR VALUE<<<<-----', tradeInCarValue);

  const backPage = () => {
    if (props.ordersData.orders[0].isTradeinCarAvilable === true) {
      props.ordersData.setStep(2);
    } else {
      props.ordersData.setStep(0);
    }
  }

  function handleSubmit(event) {
    console.log('-------HANDLE-------', event);
    console.log('-------ORDER TYPE-----', props.ordersData.orders[0].category);
    event.preventDefault();

    if (props.ordersData.orders[0].category === 'Cash') {
      let body = {
        financeId: props.ordersData.orders[0]._id,
        status: 'DealerSentAvailability',
        confirmAvailabilty: true,
        tradeInCarValue: tradeInCarValue,
        additionalDocuments: [],
        appointments: appointmentsArray,
        billOfSale: ''
      }

      const token = JSON.parse(localStorage.getItem("token"));
      sendAppointmentsToCustomer(body, token).then((response) => {
        console.log(response);
        handleOpenModal();
      }, (err) => {
        console.log('Error sending appointments', err);
        props.ordersData.setStep(0);
      });
    }
    else if (props.ordersData.orders[0].category === 'Fix') {
      localStorage.setItem("appointmentsArray", JSON.stringify(appointmentsArray));
      setFinanceOrder(props.ordersData.orders[0]);
      openFinanceOrder();
    }
  }

  let queryModal = {
    open: open,
    success: open,
    handleSuccessCloseModal: handleCloseModal,
    customerName: props.ordersData.orders[0].firstName + ' ' + props.ordersData.orders[0].lastName
  };

  const [value, onChange] = useState(new Date());
  const bookings = [{
    id: 1,
    timestamp: '10:00am',
    event: 'Test Drive',
    carImage: 'test car image',
    carModal: '2010',
    carName: 'Audi A4',
    customerName: 'Mrs. Sarah Johnes',
    location: '884 New Street, London, ON'
  },
  {
    id: 2,
    timestamp: '11:00am',
    event: 'Pickup',
    carImage: 'test car image 2',
    carModal: '2018',
    carName: 'Jeep Cherokee',
    customerName: 'Mrs. Sarah Johnes',
    location: '882 Test Street, Torronto, ON'
  },
  {
    id: 3,
    timestamp: '12:00pm',
    event: 'Pickup',
    carImage: 'test car image 2',
    carModal: '2020',
    carName: 'Toyota Corolla',
    customerName: 'Mrs. Sarah Johnes',
    location: '91 Blue Street, Missisauga, ON'
  },
  {
    id: 4,
    timestamp: '10:00am',
    event: 'Test Drive',
    carImage: 'test car image',
    carModal: '2010',
    carName: 'Audi A4',
    customerName: 'Mrs. Sarah Johnes',
    location: '884 New Street, London, ON'
  },
  {
    id: 5,
    timestamp: '11:00am',
    event: 'Pickup',
    carImage: 'test car image 2',
    carModal: '2018',
    carName: 'Jeep Cherokee',
    customerName: 'Mrs. Sarah Johnes',
    location: '882 Test Street, Torronto, ON'
  },
  {
    id: 6,
    timestamp: '12:00pm',
    event: 'Pickup',
    carImage: 'test car image 2',
    carModal: '2020',
    carName: 'Toyota Corolla',
    customerName: 'Mrs. Sarah Johnes',
    location: '91 Blue Street, Missisauga, ON'
  }
  ];

  const timeRanges = [
    {
      id: 1,
      startTime: '10:00 am',
      endTime: '12:00 pm',
      available: true,
      timeSlot: '10:00 am - 12:00 pm'
    },
    {
      id: 2,
      startTime: '12:00 pm',
      endTime: '02:00 pm',
      available: true,
      timeSlot: '12:00 pm - 02:00 pm'
    },
    {
      id: 3,
      startTime: '02:00 pm',
      endTime: '04:00 pm',
      available: true,
      timeSlot: '02:00 pm - 04:00 pm'
    },
    {
      id: 4,
      startTime: '04:00 pm',
      endTime: '06:00 pm',
      available: true,
      timeSlot: '04:00 pm - 06:00 pm'
    },
    {
      id: 5,
      startTime: '06:00 pm',
      endTime: '08:00 pm',
      available: true,
      timeSlot: '06:00 pm - 08:00 pm'
    }
  ];

  const handleClick = () => {
    console.log('----I RAN ON DATE CLICK----');
    let checkboxes = document.querySelectorAll('.checkboxClass');
    console.log('----CHECKBOXES-----', checkboxes);
    for (var i = 0; i < checkboxes.length; i++) {
      checkboxes[i].firstChild.checked = false;
    }
  };

  let bookingsWithDates = {
    date: value.toLocaleDateString("en-GB"),
    bookings: bookings
  };

  let financeOrderQuery = {
    order: financeOrder,
    closeFinanceOrder: closeFinanceOrder,
    openFinanceOrder: financeOrderStep,
    appointmentsArray: appointmentsArray,
    tradeInCarValue: tradeInCarValue
  };

  return (
    <>
      <div className="flex">
        <div className="w-2/5 mr-3">
          <div
            className="flex items-center text-xl text-blue-500 mt-2 no-underline w-max cursor-pointer"
            onClick={() => backPage()}
          >
            <DownArrow className="transform rotate-90 fill-blue-500" />
            Back
          </div>
          <div className="text-xl">Select Date</div>
          <Calendar
            onChange={onChange}
            onClickDay={handleClick}
            value={value}
            minDate={new Date()} // Disable past dates
            className="my-3 h-72 rounded-lg"
          />
        </div>
        <div className="w-3/5 ml-3">
          <div className="text-xl my-3">Select Time range</div>
          <div className="flex items-center my-3 justify-between flex-wrap">
            {timeRanges.map((timeRange, index) => (
              timeRange.available === false ? (
                <div className="p-2 m-2 rounded border-2 bg-slate-100 line-through cursor-not-allowed" key={index}>
                  {timeRange.startTime} - {timeRange.endTime}
                </div>
              ) : (
                <div className="p-2 m-2 rounded border-2 border-blue-500 cursor-pointer hover:bg-blue-500 hover:text-white active:bg-blue-500 active:text-white checkboxClass" key={timeRange.id}>
                  <input type="checkbox" name="appointment" value={timeRange.timeSlot} onChange={handleAppointments} />
                  <label className="mx-2 check3" htmlFor="r1">{timeRange.startTime} - {timeRange.endTime}</label>
                </div>
              )
            ))}
          </div>
        </div>
      </div>
      
      <form onSubmit={handleSubmit}>
        {submitBtnColor === 'grey' && (
          <div className="w-max my-3 bg-gray-400 px-20 py-2 text-slate-50 border-2 border-transparent rounded-lg float-right">
            Confirm Date and Time
          </div>
        )}
        {submitBtnColor === 'blue' && (
          <div className="w-max my-3 bg-blue-500 px-20 py-2 text-slate-50 border-2 border-transparent rounded-lg float-right">
            <input type="submit" value="Confirm Date and Time" />
          </div>
        )}
      </form>
      <SuccessModal queryModal={queryModal} handleCloseModal={handleCloseModal} />
      {financeOrderStep === true && (
        <OpenFinanceOrder financeOrderQuery={financeOrderQuery} />
      )}
    </>
  );
};

export default ConfirmDateTime;
