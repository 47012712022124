import React, { useState, useEffect } from 'react';
import axios from 'axios';
import EditServiceModal from '../container/addNewServiceModal';
import { useLocation, useNavigate } from "react-router-dom";

const CarServices = () => {
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [newService, setNewService] = useState({
    name: '',
    description: '',
    price: 0,
    icon: ''
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentService, setCurrentService] = useState(null);
  const [saveTrigger, setSaveTrigger] = useState(false);

  const navigate = useNavigate();
  const { state } = useLocation() || {};

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      const response = await axios.get('https://api.uobo.ca/api/car/additional/services');
      setServices(response.data.Data);
    } catch (error) {
      console.error('Error fetching services', error);
    }
  };

  useEffect(() => {
    if (saveTrigger) {
      handleSaveBucketList();
      setSaveTrigger(false);
    }
  }, [selectedServices, saveTrigger]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentService((prevService) => ({
      ...prevService,
      [name]: value
    }));
  };

  const handleSave = async () => {
    if (!selectedServices.includes(currentService)) {
      setSelectedServices((prevSelectedServices) => [...prevSelectedServices, currentService]);
    }
    setModalIsOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://api.uobo.ca/api/car/additional/services', newService);
      fetchServices();
      setNewService({
        name: '',
        description: '',
        price: '',
        icon: ''
      });
    } catch (error) {
      console.error('Error adding service', error);
    }
  };

  const handleSelectService = (service) => {
    setCurrentService(service);
    setModalIsOpen(true);
  };

  const handleSaveBucketList = async () => {
    console.log('Saving selected services:', selectedServices, state.order._id);
    try {
      await axios.put('https://api.uobo.ca/api/car/additional/services', { 
        services: selectedServices,
        orderId: state.order._id 
      });

       navigate("/dealer-dashboard", {
          state: {
            order: state.order
          }
       })
    } catch (error) {
      console.error('Error saving services', error);
    }
  };

  const handleAddNewDefaultService = () => {
    setCurrentService({
      name: '',
      description: '',
      price: '',
      icon: ''
    });
    setModalIsOpen(true);
  };

  const handleRemoveService = (serviceToRemove) => {
    setSelectedServices((prevSelectedServices) =>
      prevSelectedServices.filter(service => service !== serviceToRemove)
    );
  };

  return (
    <div className="p-6 flex flex-col lg:flex-row">
      <div className="w-full lg:w-1/2 lg:pr-4">
        <h2 className="text-2xl font-bold mb-4">Car Services</h2>
        <button
          onClick={handleAddNewDefaultService}
          className="w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 mb-8"
        >
          Add New Default Service
        </button>
        <ul className="space-y-4 mb-8">
          {services.map((service) => (
            <li
              key={service.id}
              className={`p-4 border ${selectedServices.includes(service) ? 'border-blue-500' : 'border-gray-200'} rounded-md flex items-center space-x-4 cursor-pointer`}
              onClick={() => handleSelectService(service)}
            >
              <img src={service.icon} alt={service.name} className="w-12 h-12" />
              <div>
                <h3 className="text-lg font-semibold">{service.name}</h3>
                <p className="text-gray-600">{service.description}</p>
                { (service.price !== undefined && service.price !== null) &&  
                  <p className="text-blue-600 font-bold">${service.price}</p>
                }
                <button className="py-2 px-4 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 mb-8">Add Service</button>
              </div>
            </li>
          ))}
        </ul>
      </div>
      
      <div className="w-full lg:w-1/2 lg:pl-4">
        <EditServiceModal
          show={modalIsOpen}
          handleClose={() => setModalIsOpen(false)}
          service={currentService}
          handleChange={handleChange}
          handleSave={handleSave}
        />

        <h2 className="text-2xl font-bold mb-4">Selected Services</h2>

        <button
          onClick={handleSaveBucketList}
          className="w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 mb-8"
        >
          Add all selected Service
        </button>

        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">Name</th>
              <th className="py-2 px-4 border-b">Description</th>
              <th className="py-2 px-4 border-b">Price</th>
              <th className="py-2 px-4 border-b">Icon</th>
              <th className="py-2 px-4 border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {selectedServices.map((service, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border-b">{service.name}</td>
                <td className="py-2 px-4 border-b">{service.description}</td>
                <td className="py-2 px-4 border-b">${service.price}</td>
                <td className="py-2 px-4 border-b"><img src={service.icon} alt={service.name} className="w-8 h-8" /></td>
                <td className="py-2 px-4 border-b">
                  <button
                    onClick={() => handleRemoveService(service)}
                    className="py-2 px-4 bg-red-600 text-white font-semibold rounded-md hover:bg-red-700"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>
    </div>
  );
};

export default CarServices;
