import React from "react";

const Loading = () => {
  return (
    <div className="h-98 flex justify-center items-center text-2xl">
      <p>Loading...</p>
    </div>
  );
};

export default Loading;
