import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Bin, CheckMark } from "../../../../assets/icons/icons";
import api from "../../../common/api/api";
import { useNavigate, useParams } from "react-router-dom";

const EditDealerInventoryFeed = () => {
  const { dealerId } = useParams();
  const params = useParams();
  const [hasFile, setHasFile] = useState(false);
  const [xmlFile, setXMLFile] = useState(null);
  const [loading, setLoading] = useState(false);
  
  const handleChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setXMLFile(e.target.files[0]);
      setHasFile(true);
    }
  };

  const handlePostRequest = async () => {
    if (hasFile) {
      const dealerInfo = localStorage.getItem("dealerSignup");
      const oldData = JSON.parse(dealerInfo);

      const formData = new FormData();

      formData.append("inventory_csv", xmlFile);
      formData.append("dealerId", dealerId); // Include dealerId in formData

      if (oldData) {
        Object.keys(oldData).forEach((key) => {
          formData.append(key, oldData[key]);
        });
      }

      setLoading(true);
      try {
        // const response = await api.post(`/api/dealer/${dealerId}/inventory`, formData, {
        //   headers: {
        //     "Content-Type": "multipart/form-data",
        //   },
        // });

        // localStorage.setItem("dealerSignin", JSON.stringify(response.data.Data));
        // localStorage.setItem("token", JSON.stringify(response.data.token));
        // localStorage.removeItem("dealerSignup");
        // localStorage.setItem("accountCreated", true);
        // setLoading(false);
        // navigate("/dealer-dashboard");
      } catch (error) {
        toast.error(error.response.data.Message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <div className="my-5 text-xl">Upload your inventory feed file below. {params.dealerId}</div>
      <div className="mt-3 flex justify-center">
        <div
          className={`${
            hasFile ? "border-4" : "border-4 border-dashed border-blue-500 p-3"
          } 
          relative mr-5 flex h-52 w-full items-center justify-center rounded-xl`}
        >
          <div
            className={` ${
              !hasFile && "bg-blue-50"
            } relative flex h-full w-full flex-col items-center justify-center rounded-xl`}
          >
            {!hasFile && (
              <>
                <div className="cursor-pointer text-lg">
                  Drag and drop your file here
                </div>
                <div className="text-sm text-slate-400">or</div>
                <div className="w-64 cursor-pointer rounded border-2 border-blue-500 bg-white p-2 text-center text-lg text-blue-500">
                  <input type="file" onChange={(e) => handleChange(e)} />
                </div>
                <div className="mt-2 text-sm text-slate-400">
                  Supported files: txt, xml, gz, bz2, zip
                </div>
              </>
            )}
            {hasFile && (
              <div className="flex flex-col items-center justify-center">
                <div
                  className="absolute right-0 top-2 float-right mr-5 flex cursor-pointer fill-red-400 text-sm text-red-400"
                  onClick={() => setHasFile(false)}
                >
                  <Bin height="20" width="20" />
                  Remove file
                </div>
                <div className="h-12 w-10 rounded bg-slate-400" />
                <div className="text-sm text-slate-400">{xmlFile?.name}</div>
                <div className="mt-4 flex items-center text-blue-500">
                  Upload Successfully completed <CheckMark className="ml-3" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {!hasFile && (
        <div className="mt-3 text-center text-red-500">
          Please choose an inventory feed file
        </div>
      )}
      <div className="m-3 text-center text-sm text-slate-400">
        Your inventory feed will be used to update your vehicle inventory in Uobo's app.
      </div>
      <div className="text-center">
        <button
          type="submit"
          className="mb-10 mt-3 w-48 rounded-xl bg-blue-500 py-2 text-xl text-white"
          onClick={handlePostRequest}
        >
          {loading ? "Loading..." : "Start Selling"}
        </button>
      </div>
    </div>
  );
};

export default EditDealerInventoryFeed;
