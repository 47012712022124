import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";

import progress3Step2 from "../../../../assets/images/progress3Step2.png";

const capitalizeWord = (word) => {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
};

const DealerCustomization = () => {
  const navigate = useNavigate();

  const [delivery, setDelivery] = useState(true);
  const [pickup, setPickup] = useState(true);
  const [uoboDelivery, setUoboDelivery] = useState(true);
  const [ownDelivery, setOwnDelivery] = useState(false);
  const [dealerName, setDealerName] = useState("");

  useEffect(() => {
    let name = JSON.parse(localStorage.getItem("dealerSignup"))?.firstName;
    if (name) {
      name = capitalizeWord(name);
      setDealerName(name);
    }
  }, [dealerName]);

  const handleRedirect = () => {
    const oldData = JSON.parse(localStorage.getItem("dealerSignup"));

    if (oldData) {
      const final = {
        ...oldData,
        uoboDelivery: `${uoboDelivery}`,
        ownDeliveryStaff: `${ownDelivery}`,
        customerPickUp: `${pickup}`,
      };
      localStorage.setItem("dealerSignup", JSON.stringify(final));

      setTimeout(() => {
        navigate("/dealer-agreement");
      }, 100);
    } else {
      navigate("/dealer-signup");
    }
  };

  return (
    <div className="flex w-4/5 xs:flex-wrap lg:flex-nowrap">
      {/* LEFT SECTION */}
      <div className="mr-3 text-left lg:w-2/3 ">
        {/* <Link to="/home" className="text-2xl text-black no-underline" >U O B O</Link> */}
        {/* Heading */}
        <p className="m-0 mt-4 p-0 text-xl font-bold">
          Hey {dealerName}, Choose how you want to partner.
        </p>
        <p className="text-slate-400">You can change your selections later</p>

        {/* Delivery */}
        <div
          className={`${
            delivery ? "border-blue-500" : "border-gray-500"
          } w-full rounded-2xl border-3 p-3`}
        >
          <div className="flex justify-between">
            <p className="m-0 p-0 text-xl font-bold">Delivery</p>
            <Form.Check // prettier-ignore
              type="switch"
              id="custom-switch"
              defaultChecked
              onChange={() => setDelivery(!delivery)}
              className="ml-2 text-left text-lg"
            />
          </div>
          <p className="text-sm text-slate-400">
            Use the UOBO platform to deliver vehicles to customers.
          </p>

          {/* UOBO delivery */}
          <Form.Check type="radio" id="uoboDelivery" name="uoboDelivery">
            <Form.Check.Input
              type="radio"
              isValid
              defaultChecked
              id="uoboDelivery"
              name="uoboDelivery"
              onClick={() => {
                setUoboDelivery(true);
                setOwnDelivery(false);
              }}
            />
            <Form.Check.Label className="text-black" value="uboDelivery">
              Use Uobo's delivery people
            </Form.Check.Label>
            <Form.Control.Feedback type="valid" className="text-sm text-black">
              5% commission per deal
            </Form.Control.Feedback>
          </Form.Check>

          {/* Self Service */}
          <Form.Check
            type="radio"
            id="selfDelivery"
            name="uoboDelivery"
            className=" mt-3"
          >
            <Form.Check.Input
              type="radio"
              isValid
              id="selfDelivery"
              name="uoboDelivery"
              onClick={() => {
                setUoboDelivery(false);
                setOwnDelivery(true);
              }}
            />
            <Form.Check.Label className="text-black">
              Use your own delivery staffs
            </Form.Check.Label>
            <Form.Control.Feedback type="valid" className="text-sm text-black">
              5% commission per deal
            </Form.Control.Feedback>
          </Form.Check>
        </div>

        {/* PICKUP */}
        <div
          className={`${
            pickup ? "border-blue-500" : "border-gray-500"
          } my-4 w-full rounded-2xl border-3 p-3`}
        >
          <div className="flex justify-between">
            <p className="m-0 p-0 text-xl font-bold">Pickup</p>
            <Form.Check // prettier-ignore
              type="switch"
              id="custom-switch"
              defaultChecked
              onChange={() => setPickup(!pickup)}
              className="ml-2 text-left text-lg"
            />
          </div>
          <p className="text-sm text-slate-400">
            Let customers pickup their vehicles to get more sales at a lower
            cost.
          </p>

          <p className="m-0 p-0 text-sm text-slate-400">
            5% commission per deal
          </p>
        </div>

        {pickup && (
          <div className="pl-5 text-sm text-slate-400">
            $350 activation fee will be charged per location{" "}
            <b className="text-blue-500">&#9432;</b>
          </div>
        )}

        <button
          type="submit"
          className="float-right mb-10 mt-3 w-48 rounded-2xl bg-blue-500 py-2 text-xl text-white"
          onClick={handleRedirect}
        >
          Submit
        </button>
      </div>

      {/* RIGHT SECTION */}
      <div className="ml-3 pl-6 lg:w-1/3 lg:border-l ">
        {/* UPPER PROGRESS */}
        <div className="mt-14 flex">
          {/* Progress */}
          <img className="h-28" src={progress3Step2} alt="uoboFlow" />
          {/* Status Name*/}
          <div className="mt-1 w-2/3">
            <p className="ml-2 text-left text-sm lg:text-base">
              Getting Started
            </p>
            <p className="ml-2 text-left text-sm lg:text-base">
              Customize partnership
            </p>
            <p className="ml-2 text-left text-sm lg:text-base">
              Review Agreement
            </p>
          </div>
        </div>
        {/* LOWER INFORMATION */}
        <div className="mb-10 mt-20 bg-blue-50 p-3 lg:w-2/3">
          <div className="flex items-center text-left">
            <b className="text-blue-500">&#9432;</b>
            <p className="ml-3">Why $350 charged for activation?</p>
          </div>
          <p className="text-left text-sm">
            A one time activation fee will be charged per location only for
            photo shoots, equipments and on-boarding.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DealerCustomization;
