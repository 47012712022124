import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const PersonaInquiryButton = () => {

  const [customerId, setCustomerId] = useState('');
  const location = useLocation();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {

    const queryParams = new URLSearchParams(location.search);
    const customerIdIs = queryParams.get('customerId');

    if (customerId !== undefined && customerId !== null && customerId !== undefined) {
      setCustomerId(customerIdIs);
    }

    // Ensure Persona script is loaded
    const script = document.createElement('script');
    script.src = 'https://unpkg.com/@persona-js/verify@0.4.1/dist/cdn.js';

    script.integrity = 'sha384-yuhGbMG4/oa1tbNHAsCDPDTYbYOhO0sOLTBBPGPtCDsUgjesNUS+oZFCgvCD59OD';
    script.crossOrigin = 'anonymous';

    script.defer = true;
    document.body.appendChild(script);

    // startInquiry();

    script.onload = () => {
      console.log('Persona script loaded successfully');
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const startInquiry = () => {
    try {
      if (window.Persona) {
  
        // window.Persona.newInquiry('tmpl_p8miPyg2wfWdqhYx6c7cdjjP').start();
        // window.Persona.newInquiry('tmpl_p8miPyg2wfWdqhYx6c7cdjjP').start({
        //   templateId: 'tmpl_p8miPyg2wfWdqhYx6c7cdjjP',
        //   environment: 'env_gAv3a3nDnFVrEXhwKB8Ccqki',
        //   onComplete: ({ inquiryId, status, fields }) => {
        //     console.log(`Completed inquiry ${inquiryId} with status ${status}`);
        //     updateCustomerLicenceStatus(customerId);
        //   },
        //   onCancel: () => {
        //     console.log('cancel');
        //   },
        //   onError: (error) => {
        //     console.error('Inquiry error:', error);
        //   }
        // });

        window.Persona.newInquiry('tmpl_p8miPyg2wfWdqhYx6c7cdjjP').start({
          templateId: 'tmpl_p8miPyg2wfWdqhYx6c7cdjjP',
          environment: 'env_gAv3a3nDnFVrEXhwKB8Ccqki', // Change this to 'production' in production
          redirectUri: `${window.location.origin}/dealer-dashboard`,
          onComplete: ({ inquiryId, status, fields }) => {
            console.log(`Completed inquiry ${inquiryId} with status ${status}`);
            updateCustomerLicenceStatus(customerId);
          },
          onCancel: () => {
            console.log('Inquiry was cancelled');
          },
          onError: (error) => {
            console.error('Inquiry error:', error);
          }
        });
      } else {
        console.error('Persona script not loaded');
      }  
    } catch (error) {
      console.log(error);
    }
  };

  const updateCustomerLicenceStatus = async (customerId) => {
    console.log('--------------------')
    axios.put('https://api.uobo.ca/api/user/licenceVerification', { customerId: customerId })
         .then((response) => {
            console.log(response);
            setSuccess(true);
         })
         .catch(error => {
            console.error('Error fetching customer and dealer info:', error);
            setError('Failed to fetch customer information');
        });
  }

  return (
    <div>
      {/* <header>@persona-js Bundled Script Usage</header>
      <h1>Load cdn.js (1.7KB gzipped)</h1>
      <pre>&lt;script defer src=&quot;https://unpkg.com/@persona-js/verify@0.4.1/dist/cdn.js&quot; /&gt;</pre>
      <h2>Use given function</h2>
      <p>Call interfaces of <code>@persona-js</code> through <code>window.Persona</code></p>
      <pre>
        &lt;a href=&quot;#&quot; onClick=&quot;window.Persona.newInquiry('TEMPLATE_ID').start()&quot;&gt;
          &lt;button&gt;Start New Inquiry&lt;/button&gt;
        &lt;/a&gt;
      </pre> */}
      <p className='mt-20'>
        <button onClick={startInquiry}>Start New Inquiry</button>
      </p>

      {error && <div className="mt-6 text-red-500 text-lg">{error}</div>}
      {success && <div className="mt-6 text-green-500 text-lg">Customer ID verification successfull!</div>}
    </div>
  );
};

export default PersonaInquiryButton;
