/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import "react-toastify/dist/ReactToastify.css";

import { CheckMark } from "../../../../assets/icons/icons";
import { AggrementPerks } from "../../../../assets/constants/const";
import progress3Step3 from "../../../../assets/images/progress3Step3.png";

const DealerAgreement = () => {
  const [show, setShow] = useState(false);

  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    // setShow(true);
    navigate("/dealer-logo-and-location");
  };

  return (
    <>
      <div className="flex w-2/3">
        {/* LEFT SECTION */}
        <div className="mr-3 w-3/4 pr-5 text-left">
          {/* <Link to="/home" className="text-2xl text-black no-underline" >U O B O</Link> */}
          {/* Heading */}
          <p className="my-7 text-xl">Review Agreement</p>

          {/* What you get */}
          <p className="mt-10 text-lg text-slate-500">What you get</p>

          {AggrementPerks.map((data) => (
            <div key={data.id} className="mb-3 flex items-center">
              <CheckMark width="20" height="20" />
              <p className="mb-0 ml-3">{data.text}</p>
            </div>
          ))}

          <div className="mt-10 text-lg font-semibold text-slate-500">
            What you pay
          </div>
          <div className="my-3 mb-0">5% commission on each deal</div>
          <div className="my-3 mb-0">
            $350 breakdown for the one time fee (Photo-shoots, on-boarding and
            equipments)
          </div>

          <hr />

          <div className="my-3 mb-4">
            By clicking `I agree`, you confirm that you have reviewed and
            accepted the{" "}
            <a href="#" className="text-blue">
              terms and condition
            </a>
          </div>

          {/* <Link
          // to="/dealer-logo-and-location"
          to="/home"
          className="bg-blue-500 text-xl text-white text-center py-2 rounded-2xl w-48 float-right mb-10 no-underline"
        >
          I agree
        </Link> */}

          <button
            onClick={handleShow}
            className="float-right mb-10 w-48 rounded-2xl bg-blue-500 py-2 text-center text-xl text-white no-underline"
          >
            I agree
          </button>
        </div>

        {/* RIGHT SECTION */}
        <div className="ml-3 w-1/3 border-l pl-5">
          {/* UPPER PROGRESS */}
          <div className="mt-14 flex">
            {/* Progress */}
            <img className="h-28" src={progress3Step3} alt="uoboFlow" />
            {/* Status Name*/}
            <div className="mt-1 w-2/3">
              <p className="ml-2 text-left text-blue-500">Getting Started</p>
              <p className="ml-2 text-left text-blue-500">
                Customize partnership
              </p>
              <p className="ml-2 text-left text-blue-500">Review Agreement</p>
            </div>
          </div>
          {/* LOWER INFORMATION */}
          <div className="mb-10 mt-20 w-4/5 bg-blue-50 p-3">
            <p className="ml-3 text-left">Why choose uobo?</p>
            <ul className="text-left text-sm">
              <li className="list-disc">More sales</li>
              <li className="list-disc">Easy to use</li>
              <li className="list-disc">Time saving</li>
              <li className="list-disc">Hassle-free</li>
              <li className="list-disc">Keeping updated</li>
            </ul>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Thank you for Registration</Modal.Title>
        </Modal.Header>
        <Modal.Body>Welcome to Uobo!</Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}

          <Link
            to="/dealer-logo-and-location"
            className="float-right mb-10 w-48 rounded-2xl bg-blue-500 py-2 text-center text-xl text-white no-underline"
            onClick={handleClose}
          >
            Close
          </Link>

          {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DealerAgreement;
