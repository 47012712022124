import React, { useState } from "react";

import { usePlacesWidget } from "react-google-autocomplete";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { ErrorMessage, Formik } from "formik";
import Form from "react-bootstrap/Form";
import isEmpty from "lodash/isEmpty";
import * as Yup from "yup";

import FieldErrorMessage from "../../../common/error";

const DealershipLocation = (props) => {
  const [googleLocation, setGoogleLocation] = useState("");

  const dealershipName = JSON.parse(
    localStorage.getItem("dealerSignup"),
  ).dealerShipName;

  const dealershipAddress = JSON.parse(
    localStorage.getItem("dealerSignup"),
  ).address;

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyAOqcjpktHX5iPpazzG3Ge5KNMny41_2js",
    onPlaceSelected: (place) => {
      setGoogleLocation(place.formatted_address);
    },
    options: {
      types: [],
      componentRestrictions: { country: "can" },
    },
  });

  const DealerLocationValidationSchema = () =>
    Yup.object().shape({
      name: Yup.string()
        .min(3, "Please enter name which contains at least 3 characters*")
        .required("Please enter Dealership name*")
        .strict(true)
        .max(30, "Dealership name should be atmost of 30 characters*"),

      // address: Yup.string()
      //   .min(3, "Please enter address which contains at least 3 characters*")
      //   .required("Please enter Address*")
      //   .strict(true)
      //   .max(80, "Address should be atmost of 80 characters*"),
    });

  return (
    <div className="mb-10 mt-4">
      <div className="text-xl">
        Please enter your dealership location details.
      </div>

      {/* FORM */}

      <Formik
        initialValues={{
          name: dealershipName,
          address: dealershipAddress,
        }}
        onSubmit={(values) => {
          const params = {
            name: values.name,
            address: googleLocation === "" ? dealershipAddress : googleLocation,
          };

          // console.log("Form values>>", params);

          const oldData = JSON.parse(localStorage.getItem("dealerSignup"));

          const final = {
            ...oldData,
            dealerShipName: params.name,
            address: params.address,
          };

          localStorage.setItem("dealerSignup", JSON.stringify(final));
          props.setLocation();
        }}
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={DealerLocationValidationSchema}
      >
        {({ handleSubmit, setFieldValue, values, errors }) => (
          <form
            onSubmit={handleSubmit}
            className="mt-5 flex flex-col items-center justify-center px-2 text-center"
          >
            {/* DEALERSHP NAME */}
            <div className={`${!!errors.name ? "mb-3" : "mb-0"} w-3/5`}>
              <FloatingLabel
                label="Dealership legal name"
                className={!!errors.name ? "mb-0" : "mb-3"}
              >
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="name"
                  id="name"
                  value={values.name}
                  onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                />
              </FloatingLabel>
              <ErrorMessage name="name" component={FieldErrorMessage} />
            </div>

            {/* ADDRESS */}
            <div className={`${!!errors.address ? "mb-3" : "mb-0"} w-3/5`}>
              <FloatingLabel
                className={!!errors.address ? "mb-0" : "mb-3"}
                // controlId="floatingInput"
                label="Dealership Address"
              >
                <Form.Control
                  type="text"
                  name="address"
                  placeholder="address"
                  id="address"
                  ref={ref}
                  value={values.address}
                  onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                />
              </FloatingLabel>
              <ErrorMessage name="address" component={FieldErrorMessage} />
            </div>
            <div className="mt-3 text-sm text-slate-400">
              Add location details for your dealership branches.
            </div>
            <div className="mb-3 text-sm text-slate-400">
              You could also add/edit them later.
            </div>
            {/* SUBMIT BUTTON */}
            {/* <div className=" "> */}
            <button
              type="submit"
              disabled={!isEmpty(errors)}
              className={`${
                isEmpty(errors) ? "bg-blue-500" : "bg-gray-300"
              } w-40 rounded-lg px-1 py-2 text-xl text-white`}
            >
              Proceed
            </button>
            {/* </div> */}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default DealershipLocation;
