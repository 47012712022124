import React from "react";
import { Link } from "react-router-dom";

const JoinNow = () => {
  return (
    <div className="flex flex-col items-center pb-36 text-center">
      <div className="font-semibold-700 mb-4 text-2xl text-slate-700 xs:mt-40 xs:text-center md:mt-0 md:text-5xl">
        Join now. It's easy
      </div>
      <div className="text-sm font-semibold text-gray-500 xs:mx-auto xs:w-4/5 md:w-9/12 md:text-base lg:m-0 lg:w-5/12">
        Sign up now and be amongst the early adopters of Uobo. Join the select
        early adopters of Uobo and tap into the growing online market.
      </div>
      <Link
        to="/dealer-signup"
        className="mt-5 cursor-pointer rounded-full bg-blue-500 text-center  text-sm text-white no-underline xs:px-4 xs:py-3 sm:text-base lg:px-7 lg:py-3"
      >
        Join Uobo
      </Link>
    </div>
  );
};

export default JoinNow;
