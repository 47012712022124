import React from 'react';
import '../../assets/scss/loader.scss';

const Loader = () => {
    return (
        <div className="loader-overlay">
          <div className="spinner"></div>
        </div>
    );
};

export default Loader;
