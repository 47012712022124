import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { getNotifications } from "../../../api/orders/helper";

import {
  Home,
  Play,
  Bell,
  Help,
  Settings,
  Inventory,
} from "../../../assets/icons/icons";

const DashboardHeader = (props) => {

  let notificationsList = props.notifications;

  // console.log(notificationsList);
  const [isActive, setIsActive] = useState("");
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([...notificationsList]);
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.includes("live-order")) {
      setIsActive("live-order");
    } else if (location.pathname.includes("dealer-dashboard")) {
      setIsActive("dealer-dashboard");
    } else if (location.pathname.includes("inventory")) {
      setIsActive("inventory");
    } else if (location.pathname.includes("help")) {
      setIsActive("help");
    } else if (location.pathname.includes("setting")) {
      setIsActive("setting");
    }
  }, [location.pathname]);

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  const toggleProfileDropdown = () => {
    setShowProfileDropdown(!showProfileDropdown);
  };

  const handleLogout = async () => {
    try {
      // Remove token from local storage or any other storage
      localStorage.removeItem('authToken');
      localStorage.removeItem('fcmToken');
      localStorage.removeItem('token');
      // Navigate to login page
      navigate('/dealer-signin');
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  // Firebase messaging setup
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));
    getNotifications(token).then((notification) => {
      setNotifications(notification);

      // console.log('notification', notification)
    });
  }, []);

  return (
    <div className="relative flex w-full justify-end sm:justify-between">
      {/* Responsive header Button */}
      <div className="absolute left-2 z-10 sm:hidden">
        <Navbar expand="lg" bg="primary" className="bg-body-tertiary p-0">
          <Container className="rounded bg-transparent p-0">
            <Navbar.Toggle
              aria-controls="basic-navbar-nav p-0"
              className="shadow-none"
            />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <div
                  className={`m-2 flex cursor-pointer items-center justify-start ${
                    isActive === "live-order" &&
                    "rounded bg-blue-500 p-2 text-white"
                  }`}
                  onClick={() => navigate("/live-order")}
                >
                  <Play
                    className={`mr-1 ${
                      isActive === "live-order" && "fill-white"
                    }`}
                  />{" "}
                  Live order
                  <div className="ml-1 flex h-4 w-4 justify-center rounded-full bg-red-500 text-xs text-white">
                    2
                  </div>
                </div>
                <div
                  className={`m-2 flex cursor-pointer items-center justify-start ${
                    isActive === "dealer-dashboard" &&
                    "rounded bg-blue-500 p-2 text-white"
                  }`}
                  onClick={() => navigate("/dealer-dashboard")}
                >
                  <Home
                    className={`mr-1 ${
                      isActive === "dealer-dashboard" && "fill-white"
                    }`}
                  />{" "}
                  Home
                </div>
                <div
                  className={`m-2 flex cursor-pointer items-center justify-start ${
                    isActive === "inventory" &&
                    "rounded bg-blue-500 p-2 text-white"
                  }`}
                  onClick={() => navigate("/inventory")}
                >
                  <Inventory
                    className={`mr-1 ${
                      isActive === "inventory" && "fill-white"
                    }`}
                  />{" "}
                  Inventory
                </div>
                <div
                  className={`m-2 flex cursor-pointer items-center justify-start ${
                    isActive === "help" && "rounded bg-blue-500 p-2 text-white"
                  }`}
                  onClick={() => navigate("/help")}
                >
                  <Help
                    className={`mr-1 ${isActive === "help" && "fill-white"}`}
                  />{" "}
                  Help
                </div>
                <div
                  className={`m-2 flex cursor-pointer items-center justify-start ${
                    isActive === "setting" &&
                    "rounded bg-blue-500 p-2 text-white"
                  }`}
                  onClick={() => navigate("/setting")}
                >
                  <Settings
                    className={`mr-1 ${isActive === "setting" && "fill-white"}`}
                  />{" "}
                  Settings
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div className="hidden sm:flex">
        {/* Live Order */}
        <div
          className={`${
            isActive === "live-order" && "rounded-full bg-blue-500 px-2"
          } ml-5 flex cursor-pointer items-center justify-center`}
          onClick={() => navigate("/live-order")}
        >
          <Play
            height="16"
            className={`${
              isActive === "live-order" ? "fill-white" : "fill-black"
            }`}
          />
          <div
            className={`${
              isActive === "live-order" ? "text-white" : "text-black"
            } ml-1 hidden lg:flex`}
          >
            Live orders
          </div>
          <div className="bg-red-500 text-white text-xs w-4 h-4 rounded-full ml-1 flex justify-center">
            {props.liveOrdersCount}
          </div>
        </div>

        {/*Dealer Home*/}
        <div
          className={`${
            isActive === "dealer-dashboard" && "rounded-full bg-blue-500 px-2"
          } ml-5 flex cursor-pointer items-center justify-center`}
          onClick={() => navigate("/dealer-dashboard")}
        >
          <Home
            height="16"
            className={`${
              isActive === "dealer-dashboard" ? "fill-white" : "fill-black"
            }`}
          />
          <div
            className={`${
              isActive === "dealer-dashboard" ? "text-white" : "text-black"
            } ml-1 hidden lg:flex`}
          >
            Home
          </div>
        </div>

        {/* Inventory")} */}
        <div
          className={`${
            isActive === "inventory" && "rounded-full bg-blue-500 px-2"
          } ml-5 flex cursor-pointer items-center justify-center`}
          onClick={() => navigate("/inventory")}
        >
          <Inventory
            height="16"
            className={`${
              isActive === "inventory" ? "fill-white" : "fill-black"
            }`}
          />
          <div
            className={`${
              isActive === "inventory" ? "text-white" : "text-black"
            } ml-1 hidden lg:flex`}
          >
            Inventory
          </div>
        </div>

        {/* Help */}
        <div
          className={`${
            isActive === "help" && "rounded-full bg-blue-500 px-2"
          } ml-5 flex cursor-pointer items-center justify-center`}
          onClick={() => navigate("/help")}
        >
          <Help
            height="16"
            className={`${isActive === "help" ? "fill-white" : "fill-black"}`}
          />
          <div
            className={`${
              isActive === "help" ? "text-white" : "text-black"
            } ml-1 hidden lg:flex`}
          >
            Help
          </div>
        </div>

        {/* Setting */}
        <div
          className={`${
            isActive === "setting" && "rounded-full bg-blue-500 px-2"
          } ml-5 flex cursor-pointer items-center justify-center`}
          onClick={() => navigate("/setting")}
        >
          <Settings
            height="16"
            className={`${
              isActive === "setting" ? "fill-white" : "fill-black"
            }`}
          />
          <div
            className={`${
              isActive === "setting" ? "text-white" : "text-black"
            } ml-1 hidden lg:flex`}
          >
            Settings
          </div>
        </div>
      </div>

      {/* Right Section */}
      <div className="flex items-center justify-center relative">
        <div className="relative cursor-pointer" onClick={toggleNotifications}>
          <Bell className="cursor-pointer" />
          {notifications?.length > 0 && (
            <div className="absolute top-0 right-0 bg-red-500 text-white text-xs w-4 h-4 rounded-full flex justify-center items-center">
              {notifications?.length}
            </div>
          )}
        </div>
        {showNotifications && (
          <div className="absolute right-0 translate-y-55 w-64 bg-white border border-gray-200 rounded-lg shadow-lg transform">
            <h4 className="text-lg font-semibold mb-2 ml-4">Notifications</h4>
            <ul className="max-h-96 overflow-y-auto">
              {notifications?.map((notification) => (
                <li key={notification.id} className="py-2 border-b border-gray-200">
                  <div className="w-full">
                    <div className="text-sm font-medium text-gray-900">{notification.title}</div>
                    <div className="text-sm text-gray-500">{notification.body}</div>
                  </div>
              </li>
              ))}
            </ul>
          </div>
        )}
        <div className="ml-5 relative">
          <div
            className="h-12 w-12 cursor-pointer rounded-full bg-gray-500"
            onClick={toggleProfileDropdown}
          ></div>
          {showProfileDropdown && (
            <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg">
              <div className="p-4">
                <button
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  onClick={() => navigate("/profile")}
                >
                  Profile
                </button>
                <button
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  onClick={() => handleLogout() }
                >
                  Logout
                </button>
              </div>
            </div>
          )}
        </div>
        {/* <div className="ml-5 h-12 w-12 cursor-pointer rounded-full bg-gray-500"></div> */}
      </div>
    </div>
  );
};

export default DashboardHeader;
