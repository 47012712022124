import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getUserInventory } from "../../../../api/inventory/helper";
import { Location } from "../../../../assets/icons/icons";
import Loading from "../../../common/Loading";
import CarImageStack from "./carImageStack";

const CarDetails = (props) => {
  const [read, setRead] = useState(false);
  const [carInfo, setCarInfo] = useState("");

  const params = useParams();

  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem("dealerSignin"))._id;
    const token = JSON.parse(localStorage.getItem("token"));

    getUserInventory(userId, token)
      .then((data) => {
        const car = data.find((car) => car.Inventory_Id === params.id);
        setCarInfo(car);
      })
      .catch((error) => {
        console.error(error, "Car Details API Error!");
      });
  }, [carInfo]);

  return carInfo ? (
    <div className="my-5">
      <div className="flex flex-col lg:flex-row">
        <div className="w-5/5 lg:w-3/5">
          {/* Images */}
          <CarImageStack
            inImages={props.inImages}
            images={carInfo?.Extra_Photos}
            setImageList={props.setImageList}
          />
          {/* Address */}
          <div className="mt-3 flex items-center">
            <Location height={14} />
            {carInfo?.Vehicle_Location}
          </div>

          {/* Name & Price */}
          <div className="flex w-11/12 flex-col justify-between border-b pb-3 text-xl sm:flex-row md:text-2xl xl:text-3xl">
            <div>
              {carInfo?.Year} {carInfo?.Make} {carInfo?.Model}
            </div>
            <div>${carInfo?.Price}</div>
          </div>
        </div>

        {/* Description1 */}
        <div className="w-5/5 lg:w-2/5">
          <div className="text-lg">Description</div>
          <div className={`mt-3 ${read ? "" : "line-clamp-5"}`}>
            {carInfo?.Internet_Description}
          </div>
          <div
            className="cursor-pointer text-blue-500"
            onClick={() => setRead(!read)}
          >
            {read ? `Show Less` : `Read More`}
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row">
        {/* Specification */}
        <div className="w-5/5 mt-3  md:w-3/5">
          <div className="text-lg">Specification</div>
          <div className="mt-2 flex w-11/12 justify-between">
            <div className="text-slate-400">Milage</div>
            <div>{carInfo?.Current_Miles} kms</div>
          </div>
          <div className="my-2 flex w-11/12 justify-between">
            <div className="text-slate-400">Status</div>
            <div>{carInfo?.New_or_Used}</div>
          </div>
          <div className="flex w-11/12 justify-between">
            <div className="text-slate-400">Trim</div>
            <div>{carInfo?.Trim}</div>
          </div>
          <div className="my-2 flex w-11/12 justify-between">
            <div className="text-slate-400">Body type</div>
            <div>{carInfo?.Body_Style}</div>
          </div>
          <div className="flex w-11/12 justify-between">
            <div className="text-slate-400">Engine</div>
            <div>{carInfo?.Engine_Size}</div>
          </div>
        </div>

        {/* Features */}
        <div className="w-5/5 mt-4 md:w-2/5">
          <div className="text-lg">Features</div>
          {carInfo?.Additional_Options &&
          carInfo?.Additional_Options.length > 0 ? (
            <div className="flex flex-wrap">
              {carInfo?.Additional_Options.slice(0, 6).map((feature, index) => (
                <div key={index} className="my-3 flex w-1/2 text-sm">
                  <Location /> {feature}
                </div>
              ))}
            </div>
          ) : (
            <div>No additional options available</div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default CarDetails;
