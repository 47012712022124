import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getLiveOrders } from "../../../../api/orders/helper";
import { Location } from "../../../../assets/icons/icons";

const CustomerOrderInformation = () => {
  const [carInfo, setCarInfo] = useState({});
  const [customerDetails, setCustomerDetails] = useState({});
  const [orderDetails, setOrderDetails] = useState({});
  const [read, setRead] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const { state } = useLocation() || {};

  const getCustomerOrderInformation = () => {
    let orderInformation = state?.order[0];
    if (orderInformation) {
      const { carId, customerId, ...otherDetails } = orderInformation;
      setCarInfo(carId);
      setCustomerDetails(customerId);
      setOrderDetails(otherDetails);
    } else {
      const token = JSON.parse(localStorage.getItem("token"));
      getLiveOrders(token).then((orders) => {
        setOrderDetails(orders);
        setCarInfo(orders.carId);
        setCustomerDetails(orders.customerId);
      });
    }
  };

  useEffect(() => {
    getCustomerOrderInformation();
  }, [state]);

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const closeFullScreenImage = () => {
    setSelectedImage(null);
  };

  return (
    <div className="flex w-full my-3 p-2">
      {/* Car Information Section */}
      <div className="w-2/5 mr-3">
        <div className="text-xl">Order details</div>
        <div className="text-slate-400">
          {orderDetails.category === 'Fix' ? 'Finance' : 'Cash Purchase'} Order
        </div>
        {carInfo && (
          <>
            <img
              className="h-52 w-full object-cover rounded-lg my-3"
              src={carInfo?.Main_Photo}
              alt={carInfo?.Make}
            />
            <div className="flex justify-between">
              <div>{carInfo?.Year} {carInfo?.Make} {carInfo?.Model}</div>
              <div>${carInfo?.Price || 'NA'}</div>
            </div>
            <div className="flex justify-between">
              <div className="text-slate-400">Year</div>
              <div>{carInfo?.Year}</div>
            </div>
            <div className="flex justify-between my-2">
              <div className="text-slate-400">Milage</div>
              <div>{carInfo?.Current_Miles || 'NA'}</div>
            </div>
            <div className="flex justify-between">
              <div className="text-slate-400">Transmission</div>
              <div>{carInfo?.Transmission_Description || 'NA'}</div>
            </div>
          </>
        )}
      </div>

      {/* Customer Details Section */}
      <div className="flex-grow border-2 border-blue-500 rounded-lg p-3 h-96 overflow-y-auto">
        <div className="text-xl">Customer details</div>
        {customerDetails && (
          <>
            <div className="flex justify-between my-2">
              <div className="text-slate-400">Customer Name</div>
              <div className="text-lg">
                {customerDetails?.firstName || 'NA'} {customerDetails?.lastName || 'NA'}
              </div>
            </div>
            <div className="text-xl mt-3">Documents Uploaded</div>
            <div className="flex justify-between flex-wrap">
              {orderDetails?.documents?.map((document, index) => (
                <div key={index} className="my-2 mr-2">
                  <div className="text-blue-500">{document?.category}</div>
                  <img
                    className="h-24 w-60 object-cover rounded-lg border-2 border-blue-500 p-1 cursor-pointer"
                    src={document?.file}
                    onClick={() => handleImageClick(document?.file)}
                    alt="LicenceFr"
                  />
                </div>
              ))}
            </div>
            {customerDetails?.houseOwnership && (
                <>
                <div className="text-xl">Mortgage Details</div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Mortgage Type</div>
                  <div className="text-lg">
                    {customerDetails?.houseOwnership?.customerMortgageDetails?.ownershipType !== undefined && customerDetails?.houseOwnership?.customerMortgageDetails?.ownershipType !== '' ? customerDetails?.houseOwnership?.customerMortgageDetails?.ownershipType : 'NA'}
                  </div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Monthly Rent</div>
                  <div className="text-lg">
                    {customerDetails?.houseOwnership?.customerMortgageDetails?.monthlyRentAmount !== undefined && customerDetails?.houseOwnership?.customerMortgageDetails?.monthlyRentAmount !== '' ? customerDetails?.houseOwnership?.customerMortgageDetails?.monthlyRentAmount : '0'}
                  </div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Mortgage Bank</div>
                  <div className="text-lg">{customerDetails?.houseOwnership?.customerMortgageDetails?.mortgageDetails?.bank !== undefined && customerDetails?.houseOwnership?.customerMortgageDetails?.mortgageDetails?.bank !== '' ? customerDetails?.houseOwnership?.customerMortgageDetails?.mortgageDetails?.bank : 'NA'}</div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Mortgage Amount</div>
                  <div className="text-lg">{customerDetails?.houseOwnership?.customerMortgageDetails?.mortgageDetails?.amount !== undefined && customerDetails?.houseOwnership?.customerMortgageDetails?.mortgageDetails?.amount !== '' ? customerDetails?.houseOwnership?.customerMortgageDetails?.mortgageDetails?.amount : 'NA'}</div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Mortgage Market Value</div>
                  <div className="text-lg">{customerDetails?.houseOwnership?.customerMortgageDetails?.mortgageDetails?.marketValue !== undefined && customerDetails?.houseOwnership?.customerMortgageDetails?.mortgageDetails?.marketValue !== '' ? customerDetails?.houseOwnership?.customerMortgageDetails?.mortgageDetails?.marketValue : 'NA'}</div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Monthly Mortgage</div>
                  <div className="text-lg">{customerDetails?.houseOwnership?.customerMortgageDetails?.mortgageDetails?.monthlyMortgage !== undefined && customerDetails?.houseOwnership?.customerMortgageDetails?.mortgageDetails?.monthlyMortgage !== '' ? customerDetails?.houseOwnership?.customerMortgageDetails?.mortgageDetails?.monthlyMortgage : 'NA'}</div>
                </div>

                <div className="text-xl">Personal Information</div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Salutation</div>
                  <div className="text-lg">{customerDetails?.salutation !== undefined && customerDetails?.salutation !== '' ? customerDetails?.salutation : 'NA'}</div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Contact</div>
                  <div className="text-lg">{customerDetails?.contact?.number !== undefined && customerDetails?.contact?.number !== '' ? customerDetails?.contact?.number : 'NA'}</div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Married Status</div>
                  <div className="text-lg">{customerDetails?.marriedStatus !== undefined && customerDetails?.marriedStatus !== '' ? customerDetails?.marriedStatus : 'NA'}</div>
                </div>

                <div className="flex justify-between my-2">
              <div className="text-slate-400">DOB</div>
              <div className="text-lg">{orderDetails?.DOB !== undefined && orderDetails?.DOB !== '' ? orderDetails?.DOB : 'NA'}</div>
            </div>

            <div className="flex justify-between my-2">
              <div className="text-slate-400">Gender</div>
              <div className="text-lg">{orderDetails?.gender !== undefined && orderDetails?.gender !== '' ? orderDetails?.gender : 'NA'}</div>
            </div>

            <div className="text-xl">Current Address</div>

            <div className="flex justify-between my-2">
              <div className="text-slate-400">Address</div>
              <div className="text-lg">{orderDetails?.address[0]?.currentAddress?.address !== undefined && orderDetails?.address[0]?.currentAddress.address !== '' ? orderDetails?.address[0]?.currentAddress.address : 'NA'}</div>
            </div>

            <div className="flex justify-between my-2">
              <div className="text-slate-400">Postalcode</div>
              <div className="text-lg">{orderDetails?.address[0]?.currentAddress?.postalCode !== undefined && orderDetails?.address[0]?.currentAddress.postalCode !== '' ? orderDetails?.address[0]?.currentAddress.postalCode : 'NA'}</div>
            </div>

            <div className="flex justify-between my-2">
              <div className="text-slate-400">Stay Duration</div>
              <div className="text-lg">
                {orderDetails?.address[0]?.currentAddress?.stayDuration?.year !== undefined && orderDetails?.address[0]?.currentAddress.stayDuration?.year !== '' ? 
                  orderDetails?.address[0]?.currentAddress.stayDuration?.year + ' year ' : 'NA'}

                {orderDetails?.address[0]?.currentAddress?.stayDuration?.month !== undefined && orderDetails?.address[0]?.currentAddress.stayDuration?.month !== '' ? 
                  orderDetails?.address[0]?.currentAddress.stayDuration?.month + ' month ' : 'NA'}
              </div>
            </div>

            <div className="text-xl">Current Employment</div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Employment Status</div>
                  <div className="text-lg">{customerDetails?.currentEmployment[0]?.status !== undefined && customerDetails?.currentEmployment[0]?.status !== '' ? customerDetails?.currentEmployment[0]?.status : 'NA'}</div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Occupation</div>
                  <div className="text-lg">{customerDetails?.currentEmployment[0]?.occupation !== undefined && customerDetails?.currentEmployment[0]?.occupation !== '' ? customerDetails?.currentEmployment[0]?.occupation : 'NA'}</div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Employment Type</div>
                  <div className="text-lg">{customerDetails?.currentEmployment[0]?.type !== undefined && customerDetails?.currentEmployment[0]?.type !== '' ? customerDetails?.currentEmployment[0]?.type : 'NA'}</div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Employer</div>
                  <div className="text-lg">{customerDetails?.currentEmployment[0]?.employer !== undefined && customerDetails?.currentEmployment[0]?.employer !== '' ? customerDetails?.currentEmployment[0]?.employer : 'NA'}</div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Work Location</div>
                  <div className="text-lg">
                    {customerDetails?.currentEmployment[0]?.workLocation !== undefined && 
                      customerDetails?.currentEmployment[0]?.workLocation !== '' ? 
                      `${customerDetails?.currentEmployment[0]?.workLocation?.address} ${customerDetails?.currentEmployment[0]?.workLocation?.city} ${customerDetails?.currentEmployment[0]?.workLocation?.postalCode} ${customerDetails?.currentEmployment[0]?.workLocation?.province}` : 
                      'NA'}
                  </div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Job Duration</div>
                  <div className="text-lg">
                    {customerDetails?.currentEmployment[0]?.jobDuration?.year !== undefined && 
                    customerDetails?.currentEmployment[0]?.jobDuration?.year !== '' ? 
                    customerDetails?.currentEmployment[0]?.jobDuration?.year + ' year ' : 'NA'}

                    {customerDetails?.currentEmployment[0]?.jobDuration?.month !== undefined && 
                    customerDetails?.currentEmployment[0]?.jobDuration?.month !== '' ? 
                    customerDetails?.currentEmployment[0]?.jobDuration?.month + ' month ' : 'NA'}
                  </div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Gross Income</div>
                  <div className="text-lg">
                    {customerDetails?.grossIncome?.income !== undefined && customerDetails?.grossIncome?.income !== '' ? 
                      customerDetails?.grossIncome?.income : 'NA'}
                  </div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Gross Income Duration</div>
                  <div className="text-lg">
                    {customerDetails?.grossIncome?.perMQY !== undefined && customerDetails?.grossIncome?.perMQY !== '' ? 
                      customerDetails?.grossIncome?.perMQY : 'NA'}
                  </div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Other Income Type</div>
                  <div className="text-lg">
                    {customerDetails?.otherIncomeSource?.type !== undefined && customerDetails?.otherIncomeSource?.type !== '' ? 
                      customerDetails?.otherIncomeSource?.type : 'NA'}
                  </div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Other Income</div>
                  <div className="text-lg">
                    {customerDetails?.otherIncomeSource?.income !== undefined && customerDetails?.otherIncomeSource?.income !== '' ? 
                      customerDetails?.otherIncomeSource?.income : 'NA'}
                  </div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Other Income Duration</div>
                  <div className="text-lg">
                    {customerDetails?.otherIncomeSource?.perMQY !== undefined && customerDetails?.otherIncomeSource?.perMQY !== '' ? 
                      customerDetails?.otherIncomeSource?.perMQY : 'NA'}
                  </div>
                </div>

                <div className="text-xl">Down Payment Details</div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Max Down Payment</div>
                  <div className="text-lg">{orderDetails?.maxDownPayment !== undefined && orderDetails?.maxDownPayment !== '' ? orderDetails?.maxDownPayment : 'NA'}</div>
                </div>

                <div className="flex justify-between my-2">
                  <div className="text-slate-400">Desired Down Payment</div>
                  <div className="text-lg">{orderDetails?.desiredDownPayment !== undefined && orderDetails?.desiredDownPayment !== '' ? orderDetails?.desiredDownPayment : 'NA'}</div>
                </div>
              </>
            )}
          </>
        )}
      </div>

      {/* Fullscreen Image Modal */}
      {selectedImage && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
          <img
            src={selectedImage}
            alt="Full Screen"
            className="max-w-full max-h-full"
            onClick={closeFullScreenImage}
          />
          <button
            className="absolute top-4 right-4 text-white text-xl bg-gray-800 p-2 rounded-full"
            onClick={closeFullScreenImage}
          >
            &times;
          </button>
        </div>
      )}
    </div>
  );
};

export default CustomerOrderInformation;
