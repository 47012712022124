// firebaseConfig.js
import { initializeApp, getApps } from "firebase/app";
import { getFirestore, initializeFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDpHLM2j8b9xeXkfc5EKX2bQrTV2QzJkKY",
  authDomain: "uobodelivery.firebaseapp.com",
  projectId: "uobodelivery",
  storageBucket: "uobodelivery.appspot.com",
  messagingSenderId: "419136359378",
  appId: "1:419136359378:web:01a06f6184cc3c315c560b",
  measurementId: "G-71FDPWJK61"
};

let deliveryApp;
if (!getApps().length) {
  deliveryApp = initializeApp(firebaseConfig);
} else {
  deliveryApp = getApps()[0];
}

export const firestore = getFirestore(deliveryApp);
export const storage = getStorage(deliveryApp);

// export const db = initializeFirestore(deliveryApp, {
//   experimentalForceLongPolling: true,
//   // useFetchStreams: false,
// });

// const deliveryApp = initializeApp(firebaseConfig);
// const db = getFirestore(deliveryApp);
// db.settings({ experimentalForceLongPolling: true });
// const db = initializeFirestore(deliveryApp, {useFetchStreams: false});

// export { db };
