import { React } from "react";

import { DealerSigninLogo } from "../../../../assets/icons/icons";
import DealerSigninOTPForm from "../components/dealerSigninOTPForm";

const OTPVerify = () => {
  return (
    <div className="my-4 flex w-4/5 justify-center tracking-wide">
      {/* LEFT SECTION */}
      <div className="hidden lg:block lg:w-1/2">
        <div className="flex h-98 flex-col items-center">
          <div className="flex h-96 w-4/5 items-center justify-center">
            <DealerSigninLogo />
          </div>
          <p className="text-bold mb-2 mt-11 text-2xl font-bold">
            Welcome Back!
          </p>
          <p className="mb-0 text-slate-600 ">Sell your cars hassle-free</p>
          <p className="text-slate-600">
            with just a{" "}
            <span className=" text-blue-500">
              few <span className="font-semibold">clicks</span>
            </span>
          </p>
        </div>
      </div>
      {/* RIGHT SECTION */}
      <DealerSigninOTPForm />
    </div>
  );
};

export default OTPVerify;
