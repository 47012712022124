import api from "../../component/common/api/api";

export let getLiveOrders = async (token) => {
  try {
    const response = await api.get(`/api/finance/liveOrders`, {
      headers: {
        Authorization: token,
      },
    });
    // console.log('-RESPONSE-', response);
    return response.data.Data;

  } catch (error) {
    // console.error(error, "Live Orders not fetched!");
    if(error.response.status === 400) {
      return [];
    }
  }
};

export let getOrders = async (token) => {
  try {
    const response = await api.get(`/api/order/dealer`, {
      headers: {
        Authorization: token,
      },
    });
    // console.log('---RESPONSE---', response);
    return response.data.Data;

  } catch (error) {
    console.error(error, "Orders not fetched!");
    if(error.response.status === 400) {
      return [];
    }
  }
};

export let sendAppointmentsToCustomer = async (data, token) => {
  try {
    const response = await api.put(
      "api/finance/cash",
      data,
      {
        headers: {
          Authorization: token,
        },
      });
      
    return response;

  } catch (error) {
    console.error(error, "Appointments unable to sent!", JSON.stringify(error));
    if(error.response.status === 400 || error.response.status === 500 || error.response.status === 401) {
      return [];
    }
  }
};

export let sendFinanceAppointmentsToCustomer = async (data, token) => {
  try {
    const response = await api.put(
      "api/finance/fix",
      data,
      {
        headers: {
          Authorization: token,
        },
      });
    // console.log('---RESPONSE---', response);
    return response;

  } catch (error) {
    console.error(error, "Appointments unable to sent!", JSON.stringify(error));
    if(error.response.status === 400 || error.response.status === 500 || error.response.status === 401) {
      return [];
    }
  }
};

export let getNotifications = async (token) => {
  try {
    const response = await api.get(`/api/dealer/notification`, {
      headers: {
        Authorization: token,
      },
    });
    // console.log('---RESPONSE---', response);
    return response.data.Data;

  } catch (error) {
    console.error(error, "Orders not fetched!");
    if(error.response.status === 400) {
      return [];
    }
  }
};

export let getDealerStripeDetails = async (token) => {
  try {
    const response = await api.get(`/api/dealer/retrive-stripe-account`, {
      headers: {
        Authorization: token,
      },
    });
    // console.log('---RESPONSE---', response);
    return response.data.Data;

  } catch (error) {
    console.error(error, "Stripe Details not found!");
    if(error.response.status === 400) {
      return [];
    }
  }
};

export let createDealerStripeAccount = async (dealerId) => {
  try {
    const response = await api.post(`/api/dealer/stripe-onboarding`, { dealerId });
    // console.log('---RESPONSE---', response);
    return response.data.Data;

  } catch (error) {
    console.error(error, "Stripe Details not found!");
    if(error.response.status === 400) {
      return [];
    }
  }
};

export const toastNotification = ({ title, description, status }) => {
  // Implement your toast notification logic
  console.log(`Toast Notification: ${title} - ${description} - ${status}`);
};

export const sendNativeNotification = ({ title, body }) => {
  // Implement your native notification logic
  console.log(`Native Notification: ${title} - ${body}`);
};
