export const inventorySorting = (sortType, userInventory) => {
  let sortedInventory = userInventory;

  switch (sortType) {
    case "0":
      sortedInventory = [...userInventory].sort((a, b) => {
        const sortA = a.Make.toLowerCase();
        const sortB = b.Make.toLowerCase();
        if (sortA < sortB) {
          return -1;
        }
        if (sortA > sortB) {
          return 1;
        }
        return 0;
      });
      break;
    case "1":
      sortedInventory = [...userInventory].sort((a, b) => {
        const sortA = a.Year.toLowerCase();
        const sortB = b.Year.toLowerCase();
        if (sortA < sortB) {
          return -1;
        }
        if (sortA > sortB) {
          return 1;
        }
        return 0;
      });
      break;
    case "2":
      sortedInventory = [...userInventory].sort((a, b) => {
        const sortA = a.Body_Style.toLowerCase();
        const sortB = b.Body_Style.toLowerCase();
        if (sortA < sortB) {
          return -1;
        }
        if (sortA > sortB) {
          return 1;
        }
        return 0;
      });
      break;
    default:
      sortedInventory = userInventory;
      break;
  }
  return sortedInventory;
};
