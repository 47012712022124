let eventSource = null;

export const createEventSource = (dealerId, setOrderWithStatuses) => {
  if (!eventSource) {
    eventSource = new EventSource(
      `https://api.uobo.ca/api/user/order-event-stream?dealerId=${dealerId}`
    );

    eventSource.onmessage = (event) => {
      const message = JSON.parse(event.data);
      const ordersList = message?.data;
      setOrderWithStatuses(ordersList);
    };
  }
};

export const closeEventSource = () => {
  if (eventSource) {
    eventSource.close();
    eventSource = null;
  }
};
