import React, { useState, useRef } from "react";

import {
  Bin,
  CheckMark,
  Location,
  CirclePlus,
} from "../../../../assets/icons/icons";
import uoboLogo from "../../../../assets/images/android-chrome-512x512.png";

const DealerLogo = (props) => {
  const [hasImage, setHasImage] = useState(false);
  const [previewLogo, setPreviewLogo] = useState(null);
  const [dealerInfo, setDealerInfo] = useState(
    localStorage.getItem("dealerSignup"),
  );
  const [imageSelected, setImageSelected] = useState(false);

  const hiddenFileInput = useRef(null);

  const handleClick = (e) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (e) => {
    const oldData = JSON.parse(dealerInfo);

    if (oldData && e.target.files && e.target.files[0]) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        const final = {
          ...oldData,
          logo: reader.result,
        };

        setPreviewLogo(reader.result);
        setImageSelected(true);
        localStorage.setItem("dealerSignup", JSON.stringify(final));
      });

      reader.readAsDataURL(e.target.files[0]);
      setHasImage(!hasImage);
    }
  };

  const removeLogo = () => {
    let oldData = JSON.parse(dealerInfo);

    if (oldData) {
      delete oldData.logo;
      localStorage.setItem("dealerSignup", JSON.stringify(oldData));

      setPreviewLogo("");
      setImageSelected(false);
      setHasImage(false);
    }
  };

  return (
    <div className="mt-5">
      <div className="flex justify-between">
        <div className="text-xl">Upload you Dealership logo.</div>
        {hasImage && (
          <div
            className="mr-5 flex cursor-pointer fill-red-400 text-red-400"
            onClick={removeLogo}
          >
            <Bin />
            Remove logo
          </div>
        )}
      </div>
      {/* ADD IMAGE  */}
      <div className="mt-3 flex justify-center">
        <div
          className={` ${hasImage ? "border-4" : "border-4 border-dashed"} 
          relative flex h-52 w-52 items-center justify-center rounded-full border-blue-500 p-3`}
        >
          <div
            className="flex h-full w-full cursor-pointer flex-col items-center justify-center rounded-full bg-blue-50"
            onClick={hasImage ? null : handleClick}
          >
            <div
              className="text-lg text-blue-500"
              // onClick={() => setHasImage(!hasImage)}
            >
              {hasImage ? (
                <img
                  src={previewLogo || uoboLogo}
                  alt="Dealership Logo"
                  className="h-44 w-44 rounded-full object-cover"
                />
              ) : (
                <div>Choose Image</div>
              )}
              <input
                type="file"
                onChange={handleChange}
                ref={hiddenFileInput}
                hidden
              />
            </div>
            {!hasImage && (
              <div className="text-sm text-slate-400">(jpeg,png)</div>
            )}
          </div>
          {hasImage ? (
            <CheckMark
              height="50"
              width="50"
              className="absolute bottom-0 right-0"
            />
          ) : (
            <div onClick={handleClick}>
              <CirclePlus className="absolute bottom-0 right-0 cursor-pointer" />
            </div>
          )}
        </div>
      </div>
      {/* LOCATION */}
      <div className="mt-4">
        {!imageSelected && (
          <div className="m-3 text-center text-red-500">
            Please choose an Image
          </div>
        )}
        <div className="flex justify-center ">
          <Location />
          {JSON.parse(dealerInfo).address}
        </div>
      </div>

      {/* BUTTON */}
      <div className="mt-4">
        <div className="flex flex-col items-center justify-center">
          <div className="text-center text-sm text-slate-400">
            Displaying logo creates trust in customer
          </div>
          <button
            type="submit"
            className="mb-10 mt-3 w-48 rounded-2xl bg-blue-500 py-2 text-xl text-white"
            onClick={() => props.setAddress()}
          >
            Submit Logo
          </button>
        </div>
      </div>
    </div>
  );
};

export default DealerLogo;
